import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useForm, useWatch } from "antd/es/form/Form";
import { useCheckPromocode } from "services/rest/subscription";
import { ProductType } from "types/general";
import { AxiosResponse } from "axios";
import { Button, Form, Input, Modal, notification } from "antd";
import { ValidateCode, ValidateResponse } from "services/rest/general";

import "./PromoCodeButton.less";

export type PromoCodeButtonProps = {
  children: React.ReactNode;
  type: ProductType;
  onApply: (promoCode: string) => void;
  onApplyEditCostCardPromoCode?: (editCostCardPromoCode: string) => any;
};

export function PromoCodeButton(props: PromoCodeButtonProps) {
  const { children, type, onApplyEditCostCardPromoCode, onApply } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Button
        type="link"
        className="promocode-button"
        onClick={() => setIsModalVisible(true)}
      >
        {children}
      </Button>
      <PromoCodeModal
        visible={isModalVisible}
        type={type}
        onOk={(promoCode: string) => {
          onApply(promoCode);
          onApplyEditCostCardPromoCode &&
            onApplyEditCostCardPromoCode(promoCode);
          setIsModalVisible(false);
        }}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  );
}

export type PromoCodeModalProps = {
  visible: boolean;
  type: ProductType;
  onOk: (promoCode: string) => void;
  onCancel: () => void;
};

export function PromoCodeModal(props: PromoCodeModalProps) {
  const { visible, type, onOk, onCancel } = props;
  const intl = useIntl();
  const [form] = useForm();
  const [error, setError] = useState<ValidateCode>();
  const promoCode = useWatch("promoCode", form);

  const { loading, fetch } = useCheckPromocode(promoCode, type);

  const check = () => {
    form.validateFields().then(() => {
      fetch()
        .then((response: AxiosResponse<ValidateResponse>) => {
          const data = response.data;

          if (data.code != ValidateCode.VALID) {
            setError(data.code);
          } else {
            onOk(promoCode);
          }
        })
        .catch(() => {
          notification.error({
            message: "Check promo code failed.",
            placement: "bottomLeft",
          });
        });
    });
  };

  const help = {
    promoCode:
      error != null
        ? intl.formatMessage({ id: `jmix-store.validate.promoCode.${error}` })
        : null,
  };

  return (
    <Modal
      title="Apply Promo Code"
      visible={visible}
      centered
      confirmLoading={loading}
      onOk={check}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Promo code"
          name="promoCode"
          validateStatus={help.promoCode != null ? "error" : ""}
          help={help.promoCode}
          required
          rules={[
            {
              message: "Please input email",
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
