import { Basket } from "services/basket/basket";
import { LicenseType } from "types/subscription";
import {LicenseKeyRow} from "../../pages/store/subscription/new/modal/license-keys/LicenseKeysModal";

export type BasketType = {
  consulting?: ProductConsulting,
  subscription?: ProductSubscription,
  product?: Product,
}

export type ProductConsulting = {
  quantity: number,
  languageId: string,
  promoCode: string,
}

export type Product = {
  productId: string,
}

export type ProductSubscription = {
  quantity: number,
  planId: string,
  licenseType: LicenseType,
  licenseKeys: LicenseKeyRow[],
  promoCode: string,
}


export function useBasket() {
  const basket = new Basket();

  const item = basket.getItem();

  const setItem = (item: BasketType): void => {
    basket.setItem(item);
  };

  return { item, setItem };
}