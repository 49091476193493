import React from "react";
import { SelectOutlined } from "@ant-design/icons";
import { Col, Layout, Menu, Row, Space, } from "antd";
import { Link, useLocation } from "react-router-dom";
import StoreLogo from "assets/logo/logo.svg";

import { ItemType } from "antd/es/menu/hooks/useItems";
import { UserAvatar } from "components/user-avatar/UserAvatar";

import "./StoreHeader.less";

export type MenuItem = ItemType & {
  hidden: boolean,
}

export type StoreHeaderProps = {
  items: MenuItem[],
}

export const StoreHeader = (props: StoreHeaderProps) => {
  const { items } = props;
  const location = useLocation();

  const activeKey = location.pathname.split("/")[1];

  const securityMenuItems = items.filter((item) => !item.hidden);

  return (
    <Layout.Header className="store-header">
      <Layout className="store-header__container">
        <Row
          className="store-header__row"
          wrap={false}
        >
          <Col flex="150px">
            <div className="store-header__logo">
              <Link to="/account">
                <img
                  src={StoreLogo}
                  alt="Store Logo"
                />
              </Link>
            </div>
          </Col>
          <Col flex="1 1 200px">
            <Menu
              theme="light"
              mode="horizontal"
              className="store-header__menu"
              defaultSelectedKeys={["3"]}
              activeKey={activeKey}
              items={securityMenuItems}
            />
          </Col>
          <Col flex="100px">
            <a
              href="https://jmix.io"
              className="store-header__jmix"
            >
              <Space>
                <SelectOutlined />
                <span>jmix.io</span>
              </Space>
            </a>
          </Col>
          <Col flex="50px">
            <UserAvatar className="store-header__avatar" />
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};
