import React, { useState } from "react";
import { Button, Col, Row, Typography, } from "antd";

import { StoreCard } from "toolkit/card/StoreCard";

import { AcademicModal } from "components/academic-card/modal/AcademicModal";

import { useIntl } from "react-intl";

import { useAccountInfo } from "services/account/hook";
import { Eligible } from "services/rest/account";
import { env } from "config";

import { NewSubscriptionForm } from "pages/store/subscription/new/configure/SubscriptionConfigure";

import "./AcademicCard.less";

export type AcademicCardProps = {
  onProceed: () => Promise<NewSubscriptionForm>;
}

export function AcademicCard(props: AcademicCardProps) {
  const { onProceed } = props;

  const intl = useIntl();
  const { info } = useAccountInfo();
  const [isVisibleAcademicModal, setVisibleAcademicModal] = useState(false);

  const isUnconfirmedLicense = info.eligible == Eligible.INDIVIDUAL_UNCONFIRMED || info.eligible == Eligible.ACADEMIC_UNCONFIRMED;

  const infoLink = (
    <a href={env.REACT_APP_INFO_JMIX_IO} target="_blank" rel="noreferrer">
      {intl.formatMessage({ id: "jmix-store.info-link" })}
    </a>
  );

  return (
    <StoreCard className="academic-card">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="academic-card__title">
            {intl.formatMessage({ id: "jmix-store.academic-card.title" })}
          </div>
        </Col>
        <Col span={24}>
          <Typography>
            {intl.formatMessage({ id: "jmix-store.academic-card.description_1" })}
          </Typography>
        </Col>
        <Col span={24}>
          <Typography>
            {intl.formatMessage({ id: "jmix-store.academic-card.description_2" }, { link: infoLink })}
          </Typography>
        </Col>
        <Col span={24}>
          <Button
            className="academic-card__button"
            type="primary"
            size="large"
            disabled={isUnconfirmedLicense}
            onClick={() => {
              onProceed()
                .then(() => setVisibleAcademicModal(true));
            }}
          >
            {intl.formatMessage({ id: "jmix-store.academic-card.button" })}
          </Button>
          {
            isUnconfirmedLicense &&
            <div className="academic-card__error">
              {intl.formatMessage({ id: "jmix-store.services.subscription.get-special-license.error" })}
            </div>
          }
        </Col>
      </Row>
      <AcademicModal
        visible={isVisibleAcademicModal}
        onAfterOk={() => setVisibleAcademicModal(false)}
        onCancel={() => setVisibleAcademicModal(false)}
      />
    </StoreCard>
  );
}
