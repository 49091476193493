import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import { StoreCard } from "toolkit/card/StoreCard";
import {
  useProfileSubscriptions,
  useRequestIndividual,
  useSubscriptionCost,
} from "services/rest/subscription";

import { Currency, CurrencyCode, ProductType } from "types/general";
import { DiscountInfo } from "components/discount-info/DiscountInfo";
import { BasketType, useBasket } from "services/basket/hook";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Duration } from "services/utils/DateUtils";
import { PromoCodeButton } from "components/promo-code-button/PromoCodeButton";

import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useAccountInfo } from "services/account/hook";
import { Eligible } from "services/rest/account";
import { LicenseType, SubscriptionType } from "types/subscription";
import { VatInfo } from "components/vat-info/VatInfo";

import { LicenseKeyRow } from "pages/store/subscription/new/modal/license-keys/LicenseKeysModal";
import { StoreError } from "pages/system/error/StoreError";
import { NewSubscriptionForm } from "pages/store/subscription/new/configure/SubscriptionConfigure";

import "./SubscriptionCostCard.less";

export type SubscriptionCostCardProps = {
  planId: string;
  quantity: number;
  licenseType: LicenseType;
  licenseKeys: LicenseKeyRow[];
  currencyCode?: CurrencyCode;

  onProceed: () => Promise<NewSubscriptionForm>;
};

export function SubscriptionCostCard(props: SubscriptionCostCardProps) {
  const [promoCode, setPromoCode] = useState("");

  const {
    planId,
    licenseType,
    quantity,
    licenseKeys,
    currencyCode,
    onProceed,
  } = props;

  const count = quantity || 1;
  const isCompany = licenseType === LicenseType.COMPANY;

  const { response, loading, error, fetch } = useSubscriptionCost(
    {
      isCompany: isCompany,
      planId: planId,
      licenseKeys: licenseKeys,
      promoCode: promoCode,
      quantity: count,
      currencyCode: currencyCode || CurrencyCode.USD,
    },
    [count, isCompany, planId, promoCode],
  );

  const content = response && (
    <>
      <Row justify="space-between">
        <Col>{response.plan.name}</Col>
        <Col>
          <Space direction="vertical" align="end" size={1}>
            <>
              <span>
                {count} x {response.currency.sign}
                {response.plan.amount}
              </span>
              <span className="cost-card__saving">
                {response?.termDiscountText || ""}
              </span>
            </>
          </Space>
        </Col>
      </Row>
      <div className="subscription-cost-card__discounts">
        <DiscountInfo
          currency={response.currency}
          discounts={response.discountNotes || []}
        />
      </div>
      <Row>
        <Col>
          <Description
            amount={response.price}
            currency={response.currency}
            duration={response.duration}
          />
        </Col>
      </Row>
      <div className="subscription-cost-card__promocode">
        <PromoCodeButton
          type={ProductType.LICENSE}
          onApply={(promoCode) => setPromoCode(promoCode)}
        >
          Apply promo code
        </PromoCodeButton>
      </div>
      <Divider />
      {response.tax != 0 && (
        <div>
          <VatInfo currency={response.currency} cost={response.tax} />
          <Divider />
        </div>
      )}
      <Row gutter={[12, 12]} justify="space-between">
        <Col>
          <strong>Total</strong>
        </Col>
        <Col>
          <>
            {response?.currency.sign} {response?.total}
          </>
        </Col>
        <Col span={24}>
          <PurchaseButton
            licenseType={licenseType}
            planId={planId}
            promoCode={promoCode}
            licenseKeys={licenseKeys}
            count={count}
            onProceed={onProceed}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Spin spinning={loading}>
      <StoreCard className="subscription-cost-card">
        {error ? (
          <StoreError reload={fetch} autoHeight={true} bordered={false} />
        ) : (
          content
        )}
      </StoreCard>
    </Spin>
  );
}

type PurchaseButtonProps = {
  licenseType: LicenseType;
  planId: string;
  licenseKeys: LicenseKeyRow[];
  promoCode: string;
  count: number;

  onProceed: () => Promise<NewSubscriptionForm>;
};

function PurchaseButton(props: PurchaseButtonProps) {
  const { licenseType, planId, promoCode, count, licenseKeys, onProceed } =
    props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { item, setItem } = useBasket();
  const [isIndividualModalVisible, setIndividualModalVisible] = useState(false);
  const { info } = useAccountInfo();

  const { response } = useProfileSubscriptions();

  const isIndividualEligible = info.eligible == Eligible.INDIVIDUAL;
  const isUnconfirmedEligible =
    info.eligible == Eligible.INDIVIDUAL_UNCONFIRMED ||
    info.eligible == Eligible.ACADEMIC_UNCONFIRMED;
  const individualSubscriptions =
    licenseType === LicenseType.INDIVIDUAL && isIndividualEligible
      ? response?.filter(
          (subscription) => subscription.type === SubscriptionType.COMMERCIAL,
        )
      : null;

  if (
    licenseType === LicenseType.INDIVIDUAL &&
    (!isIndividualEligible || isUnconfirmedEligible)
  ) {
    return (
      <>
        <Button
          className="cost-card__button"
          type="primary"
          size="large"
          disabled={isUnconfirmedEligible}
          onClick={() => {
            onProceed().then(() => setIndividualModalVisible(true));
          }}
        >
          {intl.formatMessage({
            id: "jmix-store.services.subscription.get-individual-license.button",
          })}
        </Button>
        {isUnconfirmedEligible && (
          <div className="subscription-cost-card__error">
            {intl.formatMessage({
              id: "jmix-store.services.subscription.get-special-license.error",
            })}
          </div>
        )}
        <RequestIndividualModal
          visible={isIndividualModalVisible}
          onCancel={() => setIndividualModalVisible(false)}
        />
      </>
    );
  }

  return (
    <Button
      className="cost-card__button"
      type="primary"
      size="large"
      onClick={() => {
        if (
          individualSubscriptions == null ||
          individualSubscriptions.length === 0 ||
          licenseKeys.length > 0
        ) {
          onProceed().then(() => {
            setItem(
              createBasket(
                planId,
                promoCode,
                licenseType,
                count,
                licenseKeys,
                item,
              ),
            );
            navigate("/store/subscription/new/billing");
          });
        } else {
          notification.error({
            message: intl.formatMessage(
              { id: "jmix-store.subscription-cost-card.individualError" },
              {
                contactLink: (str) => (
                  <a href="https://www.jmix.io/contacts/">{str}</a>
                ),
              },
            ),
          });
        }
      }}
    >
      Proceed to billing info
    </Button>
  );
}

type RequestIndividualModalProps = {
  visible: boolean;
  onCancel: () => void;
};

function RequestIndividualModal(props: RequestIndividualModalProps) {
  const { visible, onCancel } = props;
  const navigate = useNavigate();
  const [form] = useForm();
  const { loading, reload } = useAccountInfo();
  const { fetch, loading: requestLoading } = useRequestIndividual();

  return (
    <Modal
      centered={true}
      title="Get Individual License"
      open={visible}
      okButtonProps={{
        loading: loading || requestLoading,
      }}
      onOk={() => {
        form.validateFields().then(() => {
          const data = {
            projectInfo: form.getFieldValue("projectInfo"),
          };

          fetch(data)
            .then(() => {
              reload().finally(() =>
                navigate("/store/subscription/request-success"),
              );
            })
            .catch(() => {
              notification.error({
                message: "Individual subscription request failed.",
              });
            });
        });
      }}
      onCancel={onCancel}
    >
      <div className="request-individual-modal__description">
        Please fill out the form below to apply an individual license. We will
        review your application and let you know the result via email as soon as
        possible.
      </div>
      <Form layout="vertical" form={form}>
        <Form.Item
          name="personalFunds"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("You must accept the usage rule."),
                    ),
            },
          ]}
        >
          <Checkbox>I confirm that I am paying with my personal funds</Checkbox>
        </Form.Item>
        <Form.Item
          name="individual"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("You must accept the usage rule."),
                    ),
            },
          ]}
        >
          <Checkbox>
            I confirm that I am going to develop software with Jmix individually
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Tell us a few words about your project"
          name="projectInfo"
          required={true}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("The field is required.")),
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

type DescriptionProps = {
  amount: number;
  currency: Currency;
  duration: Duration;
};

function Description(props: DescriptionProps) {
  const { amount, currency, duration } = props;
  const intl = useIntl();

  const durationUnitFormat = intl.formatMessage({
    id: "jmix-store.duration." + duration.durationUnit,
  });

  const period = `${duration.duration} ${durationUnitFormat}`;
  const price = `${currency.sign}${amount}`;

  return (
    <div className="subscription-cost-card__description">
      Your subscription will be automatically renewed every {period} at the
      price of {price}. You can cancel the subscription at any time in your
      account settings.
    </div>
  );
}

function createBasket(
  planId: string,
  promoCode: string,
  licenseType: LicenseType,
  count: number,
  licenseKeys: LicenseKeyRow[],
  item: BasketType,
): BasketType {
  const subscription = {
    licenseType: licenseType,
    licenseKeys: licenseKeys,
    planId: planId,
    quantity: count,
    promoCode: promoCode,
  };

  if (item != null) {
    item.subscription = subscription;
    return item;
  }

  return {
    subscription: subscription,
  };
}
