import { Statistic } from "antd";
import React from "react";

import "./AutoRenewal.less";

export type AutoRenewalProps = {
  autoRenewal: boolean,
}

export function AutoRenewal(props: AutoRenewalProps) {
  const { autoRenewal } = props;
  const name = autoRenewal ? "Active" : "Cancelled";
  const style = autoRenewal ? "auto-renewal__status_active" : "";

  return (
    <Statistic
      className={style}
      title="Auto renewal"
      value={name}
    />
  );
}