import React, { useState } from "react";
import { DeleteOutlined, EditOutlined, TeamOutlined, UserAddOutlined, } from "@ant-design/icons";
import { Button, Result, Row, Space } from "antd";
import { useIntl } from "react-intl";

import { StoreTable } from "toolkit/table/StoreTable";

import { StoreCard } from "toolkit/card/StoreCard";
import { useMembers } from "services/rest/member";
import { Pagination } from "types/general";
import { DeleteUserModal } from "components/team-member/modal/DeleteUserModal";
import { EditUserModal } from "components/team-member/modal/EditUserModal";
import { AddUserModal } from "components/team-member/modal/AddUserModal";

import { StoreError } from "pages/system/error/StoreError";

import { HelpLink } from "../help-link/HelpLink";

import "./TeamMember.less";

export type IMember = {
  id: string,
  key: string,
  name: string,
  email: string,
  role: string,
  enabled: boolean,
}

export function TeamMember() {
  const intl = useIntl();
  const [isAddUserModalVisible, setAddUserModalVisible] = useState(false);
  const [isEditUserModalVisible, setEditUserModalVisible] = useState(false);
  const [isDeleteUserModalVisible, setDeleteUserModalVisible] = useState(false);

  const [currentMember, setCurrentMember] = useState<IMember>();

  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 5 });

  const { response, loading, error, fetch } = useMembers(pagination.page, pagination.size);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: IMember) => {
        return (
          record.enabled
            ? <div>{text}</div>
            : <div className="team-member__name-column_pending">Pending</div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role"
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      render: (text: string, record: IMember) => {
        return (
          <Row justify="end">
            <Space>
              <Button
                type="link"
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setEditUserModalVisible(true);
                  setCurrentMember(record);
                }}
              >
                Edit
              </Button>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                size="small"
                className="team-member__delete"
                onClick={() => {
                  setDeleteUserModalVisible(true);
                  setCurrentMember(record);
                }}
              >
                Delete
              </Button>
            </Space>
          </Row>
        );
      },
    },
  ];

  const dataSource: IMember[] = response?.content.map((member) => {
    return {
      id: member.id,
      key: member.id,
      role: intl.formatMessage({ id: `jmix-store.role.${member.role}` }),
      email: member.email,
      enabled: member.enabled,
      name: `${member.firstName} ${member.lastName}`,
    };
  }) || [];

  const content = (
    response && response.total > 0
      ? (
        <StoreTable
          columns={columns}
          dataSource={dataSource}
          pagination={{
            total: response.total,
            pageSize: pagination.size,
            current: pagination.page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: (page) => setPagination({ page: page, size: pagination.size })
          }}
        />
      )
      : (
        <Result
          icon={(
            <TeamOutlined
              size={50}
              style={{ color: "#DEE8ED" }}
            />
          )}
          subTitle="Add team members to assign keys to them"
        />
      )
  );

  return (
    <>
      <StoreCard
        loading={loading}
        title={`Team Member (${response?.total || 0})`}
        extra={
          <Space>
            <HelpLink name="jmix-store.help-link.need-help"
                      to="https://docs.jmix.io/jmix/account-management.html#company-profile-management" />
            <Button
              icon={<UserAddOutlined />}
              size="small"
              type="primary"
              ghost
              onClick={() => setAddUserModalVisible(true)}
            >
              Add
            </Button>
          </Space>
        }
      >
        {
          error ?
            <StoreError
              reload={fetch}
              autoHeight={true}
              bordered={false}
            /> :
            content
        }
      </StoreCard>
      <AddUserModal
        visible={isAddUserModalVisible}
        onAfterOk={() => fetch()}
        onCancel={() => {
          setAddUserModalVisible(false);
        }}
      />
      {
        currentMember &&
        <EditUserModal
          member={currentMember}
          visible={isEditUserModalVisible}
          onAfterOk={fetch}
          onCancel={() => setEditUserModalVisible(false)}
        />
      }
      {
        currentMember &&
        <DeleteUserModal
          member={currentMember}
          visible={isDeleteUserModalVisible}
          onAfterOk={fetch}
          onCancel={() => setDeleteUserModalVisible(false)}
        />
      }
    </>
  );
}
