import React from "react";
import { Table, TableProps } from "antd";

import "./StoreTable.less";

export function StoreTable(props: TableProps<any>) {
  return (
    <Table
      {...props}
      className={`store-table ${props.className}`}
    />
  );
}
