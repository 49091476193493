import React, { useState } from "react";
import { Button, Modal } from "antd";
import { SubscriptionInfo, } from "services/rest/subscription";
import { DateUtils } from "services/utils/DateUtils";
import { StoreTable } from "toolkit/table/StoreTable";
import { ColumnsType } from "antd/es/table";
import { LocalDate } from "types/general";

export type SubscriptionModalProps = {
  visible: boolean;
  onOk: (row: SubscriptionRow) => void;
  onCancel: () => void;
  loading?: boolean,
  activeSubscriptions?: SubscriptionInfo[];
};

export type SubscriptionRow = {
  id: string;
  startDate: LocalDate;
  expireOn: LocalDate;
  plan: string;
  keysCount: string;
  nextPayment: LocalDate;
};

const columns: ColumnsType = [
  {
    title: "Started on",
    dataIndex: "startDate",
    key: "startDate",
  },
  {
    title: "Expire on",
    dataIndex: "expireOn",
    key: "expireOn",
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "plan",
  },
  {
    title: "Keys",
    dataIndex: "keysCount",
    key: "keysCount",
  },
  {
    title: "Next payment",
    dataIndex: "nextPayment",
    key: "nextPayment",
  },
];

export function SubscriptionModal(props: SubscriptionModalProps) {
  const { visible, activeSubscriptions, loading, onOk, onCancel } = props;
  const [subscription, setSubscription] = useState<SubscriptionRow>(Object);

  const dataSource = activeSubscriptions?.map(
    (subscription) => {
      return {
        key: subscription.id,
        id: subscription.id,
        startDate: DateUtils.getFormatDate(subscription.startDate),
        expireOn: DateUtils.getFormatDate(subscription.nextPayment.date),
        plan: subscription.tariff.name,
        keysCount: subscription.userCount,
        nextPayment: DateUtils.getFormatDate(
          subscription.nextPayment.date
        ),
      };
    }
  );

  return (
    <Modal
      centered
      width="800px"
      title="Choose subscription to update"
      open={visible}
      onOk={() => onOk(subscription)}
      onCancel={onCancel}
      okText="Use selected"
      okButtonProps={{ disabled: subscription.id == undefined }}
    >
      <Button type="link" onClick={onCancel}>
        Purchase new subscription anyway
      </Button>
      <StoreTable
        loading={loading}
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: SubscriptionRow[]
          ) => {
            setSubscription(selectedRows[0]);
          },
        }}
        columns={columns}
        dataSource={dataSource}
      />
    </Modal>
  );
}
