import React from "react";
import { Col, Result, Row, Space, Statistic } from "antd";

import { StoreList } from "toolkit/list/StoreList";
import { useBalance } from "services/rest/account";
import { ClockCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { NavigateButton } from "components/navigate-button/NavigateButton";
import { StoreCard } from "toolkit/card/StoreCard";

import { useIntl } from "react-intl";

import "./BalanceCard.less";
import { HelpLink } from "../help-link/HelpLink";
import { StoreError } from "pages/system/error/StoreError";

export function BalanceCard() {
  const intl = useIntl();
  const { response, loading, error, fetch } = useBalance();

  const consultancy = response?.consultancy;
  const incident = response?.incident;

  const isEmpty = !consultancy && !incident;

  return (
    <StoreCard
      title={intl.formatMessage({ id: "jmix-store.balance-card.title" })}
      extra={
        <Space>
          <HelpLink name="jmix-store.help-link.need-help" to="https://docs.jmix.io/jmix/account-management.html#consulting" />
          {
            isEmpty &&
            <NavigateButton icon={<ShoppingCartOutlined />}
                            ghost={false}
                            size="small"
                            name={intl.formatMessage({ id: "jmix-store.balance-card.buy-consulting-button" })}
                            path="/store/consulting" />
          }
        </Space>
      }
    >
      <StoreList
        loading={loading}
      >
        {
          error &&
          <StoreError
            autoHeight={true}
            bordered={false}
            reload={fetch}
          />
        }
        {
          isEmpty && <EmptyBalance />
        }
        {
          !isEmpty && consultancy && <ConsultancyCard
            used={consultancy?.hoursUsed || 0}
            total={consultancy?.hoursTotal || 0}
          />
        }
        {
          !isEmpty && incident && <IncidentsInfo
            used={incident?.incidentsUsed || 0}
            total={incident?.incidentsTotal || 0}
          />
        }
      </StoreList>
    </StoreCard>
  );
}

function EmptyBalance() {
  const intl = useIntl();

  return (
    <Result
      icon={(
        <ClockCircleOutlined
          size={50}
          style={{ color: "#DEE8ED" }}
        />
      )}
      subTitle={intl.formatMessage({ id: "jmix-store.balance-card.empty-card.description" })}
    />
  );
}

type ConsultancyCardProps = {
  used: number,
  total: number,
}

function ConsultancyCard(props: ConsultancyCardProps) {
  const { used, total } = props;

  const intl = useIntl();
  const available = total - used;

  return (
    <>
      <Row>
        <Col>
          <div className="balance-card__card-header">
            {intl.formatMessage({ id: "jmix-store.balance-card.consultancy-card.title" })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col flex="0 0 200px">
          <Statistic
            title={intl.formatMessage({ id: "jmix-store.balance-card.consultancy-card.hours-used" })}
            value={used}
          />
        </Col>
        <Col flex="1 0 200px">
          <Statistic
            className={available < total * 0.1 ? "balance-card__available_red" : "balance-card__available_green"}
            title={intl.formatMessage({ id: "jmix-store.balance-card.consultancy-card.hours-available" })}
            value={available}
          />
        </Col>
        <Col flex="0 -1 100px">
          <Space>
            <NavigateButton
              name={intl.formatMessage({ id: "jmix-store.balance-card.consultancy-card.balance-button" })}
              ghost={false}
              path="/store/consulting"
            />
            <NavigateButton
              name={intl.formatMessage({ id: "jmix-store.balance-card.consultancy-card.view-details-button" })}
              path="/account/consultancy"
            />
          </Space>
        </Col>
      </Row>
    </>
  );
}

type IncidentsCardProps = {
  used: number,
  total: number,
}

function IncidentsInfo(props: IncidentsCardProps) {
  const { used, total } = props;
  const intl = useIntl();
  const available = total - used;

  return (
    <>
      <Row>
        <Col>
          <div className="balance-card__card-header">
            {intl.formatMessage({ id: "jmix-store.balance-card.incidents-info.title" })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col flex="0 0 200px">
          <Statistic
            title={intl.formatMessage({ id: "jmix-store.balance-card.incidents-info.incidents-used" })}
            value={used}
          />
        </Col>
        <Col flex="1 0 200px">
          <Statistic
            className={available < total * 0.1 ? "balance-card__available_red" : "balance-card__available_green"}
            title={intl.formatMessage({ id: "jmix-store.balance-card.incidents-info.incidents-available" })}
            value={total - used}
          />
        </Col>
        <Col flex="0 0 100px">
          <NavigateButton
            name={intl.formatMessage({ id: "jmix-store.balance-card.incidents-info.view-details-button" })}
            path="/account/incidents"
          />
        </Col>
      </Row>
    </>
  );
}
