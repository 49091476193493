import React from "react";
import { Col, Row, Typography, } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import Paragraph from "antd/lib/typography/Paragraph";
import { useIntl } from "react-intl";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";

import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";
import { ConsultingRoutes } from "pages/store/consulting/ConsultingRoutes";
import { env } from "config";

import "pages/store/consulting/Consulting.less";

const routes: Route[] = [
  {
    path: "/store",
    breadcrumbName: "Store",
  },
  {
    path: "",
    breadcrumbName: "",
  },
];

export function Consulting() {
  const intl = useIntl();

  return (
    <>
      <StorePageHeader
        title={intl.formatMessage({ id: "jmix-store.consulting.title" })}
        breadcrumb={<StoreBreadcrumb routes={routes} />}
      >
        <Paragraph>
          {intl.formatMessage({ id: "jmix-store.consulting.sub-title" })}
        </Paragraph>
      </StorePageHeader>
      <StoreLayout>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ConsultingRoutes />
          </Col>
          <Col flex="1 1 500px">
            <ManyHoursConsultingInfo />
          </Col>
          <Col flex="1 1 500px">
            <CustomDevelopmentInfo />
          </Col>
        </Row>
      </StoreLayout>
    </>
  );
}

function ManyHoursConsultingInfo() {
  const intl = useIntl();

  return (
    <>
      <StoreCard
        className="consulting__info-card"
        title={intl.formatMessage({ id: "jmix-store.consulting.many-hours-consulting.title" })}
      >
        <Paragraph>
          {
            intl.formatMessage({
              id: "jmix-store.consulting.many-hours-consulting.description"
            }, {
              contactUs: <ContactUsLink />
            })
          }
        </Paragraph>
      </StoreCard>
    </>
  );
}

function CustomDevelopmentInfo() {
  const intl = useIntl();

  return (
    <>
      <StoreCard
        className="consulting__info-card"
        title={intl.formatMessage({ id: "jmix-store.consulting.custom-development.title" })}
      >
        <Typography>
          {
            intl.formatMessage({
              id: "jmix-store.consulting.custom-development.description"
            }, {
              contactUs: <ContactUsLink />
            })
          }
        </Typography>
      </StoreCard>
    </>
  );
}

export function ContactUsLink() {
  const intl = useIntl();
  return (
    <a href={env.REACT_APP_JMIX_CONTACTS} target="_blank" rel="noopener noreferrer">
      {
        intl.formatMessage({ id: "jmix-store.contact-us" })
      }
    </a>
  );
}
