import { Col, Form, Radio, Row, Typography } from "antd";
import { PaymentCard } from "components/payment-card/PaymentCard";
import React from "react";

import { CurrencyCode, PaymentType } from "types/general";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useWatch } from "antd/es/form/Form";
import { useIntl } from "react-intl";
import Paragraph from "antd/lib/typography/Paragraph";

import "./PaymentBillingInfo.less";

export type PaymentMethodFormItemProps = {
  allowedOffline?: boolean,
  hideDescription?: boolean,
  hideCurrency?: boolean,
}

export function PaymentMethodFormItem(props: PaymentMethodFormItemProps) {
  const { allowedOffline, hideDescription, hideCurrency } = props;
  const intl = useIntl();
  const form = useFormInstance();
  const paymentType = allowedOffline
    ? useWatch("paymentType", form)
    : PaymentType.CARD;

  return (
    <>
      {
        allowedOffline &&
        <Row>
          <Col>
            <Form.Item
              label={intl.formatMessage({ id: "jmix-store.payment-method-form-item.payment-method" })}
              tooltip={<PaymentMethodTooltip />}
              name="paymentType"
              initialValue={PaymentType.CARD}
            >
              <Radio.Group>
                <Radio.Button value={PaymentType.CARD}>Credit card</Radio.Button>
                <Radio.Button value={PaymentType.OFFLINE} disabled={!allowedOffline}>Wire transfer</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {
              !hideDescription &&
              <div className="payment-method__offline-description">
                Offline payments are not available for subscriptions paid by a card.
              </div>
            }
          </Col>
        </Row>
      }
      <Row>
        <Col flex="1 0 300px">
          {
            paymentType === PaymentType.CARD &&
            <Form.Item
              label={intl.formatMessage({ id: "jmix-store.payment-method-form-item.payment-card" })}
              name="paymentCard"
              rules={[
                {
                  validator: (_, value) => {
                    return value ? Promise.resolve() : Promise.reject(new Error("Please input payment card"));
                  }
                }
              ]}
            >
              <PaymentCard />
            </Form.Item>
          }
          {
            paymentType !== PaymentType.CARD && !hideCurrency &&
            <Form.Item
              label={intl.formatMessage({ id: "jmix-store.payment-method-form-item.currency" })}
              name="currency"
              initialValue={CurrencyCode.USD}
            >
              <Radio.Group>
                <Radio.Button value={CurrencyCode.USD}>
                  {intl.formatMessage({ id: "jmix-store.currency.USD" })}
                </Radio.Button>
                <Radio.Button value={CurrencyCode.EUR}>
                  {intl.formatMessage({ id: "jmix-store.currency.EUR" })}
                </Radio.Button>
                <Radio.Button value={CurrencyCode.GBP}>
                  {intl.formatMessage({ id: "jmix-store.currency.GBP" })}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          }
        </Col>
      </Row>
    </>
  );
}

export function PaymentMethodTooltip() {
  const intl = useIntl();

  return (
    <Typography className="payment-method-tooltip">
      <Paragraph>
        {intl.formatMessage({ id: "jmix-store.payment-method-form-item.payment-method.tooltip_1" })}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage({ id: "jmix-store.payment-method-form-item.payment-method.tooltip_2" })}
      </Paragraph>
    </Typography>
  );
}