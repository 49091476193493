import React from "react";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import { Col, Result, Row, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { NavigateButton } from "components/navigate-button/NavigateButton";

import { useSearchParams } from "react-router-dom";
import { SubscriptionInfo, useProfileSubscription } from "services/rest/subscription";
import { useAccountInfo } from "services/account/hook";
import { CustomerType, TariffType } from "types/general";
import { useIntl } from "react-intl";

import { env } from "config";

import "./NewSubscriptionSuccessPage.less";
import { Survey } from "components/servey/Survey";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { StoreError } from "pages/system/error/StoreError";
import { useAccountLicenseInfo } from "services/rest/account";

export function NewSubscriptionSuccessPage() {
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const {
    response: subscriptionResponse,
    loading: subscriptionLoading,
    error: subscriptionError
  } = useProfileSubscription(searchParams.get("subscriptionId"));

  const {
    response: accountLicenseResponse,
    loading: accountLicenseLoading,
    error: accountLicenseError
  } = useAccountLicenseInfo();

  if (subscriptionError || accountLicenseError) {
    return (
      <StoreError showBack />
    );
  }

  if (subscriptionLoading || accountLicenseLoading || !subscriptionResponse || !accountLicenseResponse) {
    return (
      <StoreLayout>
        <StoreLoading />
      </StoreLayout>
    );
  }

  const isSurveyVisible = accountLicenseResponse.commercialLicenseCount <= 1;
  const subscriptionUrl = `/account/subscription/${subscriptionResponse.id}`;

  const title = subscriptionResponse.offline
    ? intl.formatMessage({ id: "jmix-store.success-page.offline.title" })
    : intl.formatMessage({ id: "jmix-store.success-page.subscription.online.title" });

  return (
    <StoreLayout>
      <StoreCard className="new-subscription-success-page">
        <Row
          className="new-subscription-success__content"
          justify="space-around"
          align="middle"
        >
          <Col>
            <Space direction="vertical" align="center">
              <Result
                className="new-subscription-success__description"
                icon={<CheckOutlined className="new-subscription-success_check-icon" />}
                title={title}
                subTitle={
                  <div>
                    <div>
                      {
                        subscriptionResponse.offline ?
                          <OfflineDescription /> :
                          <OnlineDescription subscriptionInfo={subscriptionResponse} />
                      }
                    </div>
                    {
                      subscriptionResponse.offline
                        ? <NavigateButton
                          name="Go to Jmix Account"
                          path="/account"
                          type="primary"
                          ghost={false}
                        />
                        : <NavigateButton
                          name="Go to subscription page"
                          path={subscriptionUrl}
                          type="primary"
                          ghost={false}
                        />
                    }
                    {
                      isSurveyVisible &&
                      <div className="new-subscription-success__survey">
                        <Survey />
                      </div>
                    }
                  </div>
                }
              />
            </Space>
          </Col>
        </Row>
      </StoreCard>
    </StoreLayout>
  );
}

function OfflineDescription() {
  const intl = useIntl();

  return (
    <div className="new-subscription-success__text">
      {intl.formatMessage({ id: "jmix-store.success-page.offline.description" }, {
        link: (str) => <a href={env.REACT_APP_JMIX_ACCOUNT_URL}>{str}</a>,
      })}
    </div>
  );
}

type OnlineDescriptionProps = {
  subscriptionInfo: SubscriptionInfo;
}

function OnlineDescription(props: OnlineDescriptionProps) {
  const { subscriptionInfo } = props;
  const intl = useIntl();
  const { info } = useAccountInfo();

  const subscriptionUrl = `/account/subscription/${subscriptionInfo.id}`;

  const isCompanyCustomer = info.type?.toUpperCase() == CustomerType.COMPANY.toUpperCase();
  const isEnterprise = subscriptionInfo.tariff.name.toUpperCase() == TariffType.ENTERPRISE.toUpperCase();
  const isEligibleForFreeConsulting = subscriptionInfo.eligibleForFreeConsulting;

  return (
      <div>
          <div className="new-subscription-success__sub_title">
              {intl.formatMessage({id: "jmix-store.success-page.activatingLicense"})}
          </div>
          <div className="new-subscription-success__text">
              <div>
                  {
                      isCompanyCustomer
                          ? intl.formatMessage({id: "jmix-store.success-page.activatingLicenseCompany"}, {
                              link: (str) => <a href={subscriptionUrl}>{str}</a>,
                          })
                          : intl.formatMessage({id: "jmix-store.success-page.activatingLicenseCustomer"})
                  }
              </div>
          </div>
          <div className="new-subscription-success__text">
              {
                  isEnterprise &&
                  intl.formatMessage({id: "jmix-store.success-page.activatingLicenseEnterpriseTariff"}, {
                      link: (str) => <a href="https://docs.jmix.io/jmix/add-ons.html">{str}</a>
                  })
              }
          </div>
          <div className="new-subscription-success__sub_title">
              {
                  isEligibleForFreeConsulting &&
                  intl.formatMessage({id: "jmix-store.success-page.freeConsulting"})
              }
          </div>
          <div className="new-subscription-success__text">
              <div>
                  {
                      isEligibleForFreeConsulting &&
                      intl.formatMessage({id: "jmix-store.success-page.freeConsultingMessage"})
                  }
              </div>
          </div>
          <div className="new-subscription-success__sub_title">
              {intl.formatMessage({id: "jmix-store.success-page.managingSubscription"})}
          </div>
          <div className="new-subscription-success__text">
              <div>
                  {intl.formatMessage({id: "jmix-store.success-page.managingSubscriptionMessage"}, {
                      link: (str) => <a href={subscriptionUrl}>{str}</a>,
                  })}
              </div>
              <div>
                  {intl.formatMessage({id: "jmix-store.success-page.managingSubscriptionMessageFinalPart"}, {
                      link: (str) => <a href="https://forum.jmix.io/">{str}</a>
                  })}
              </div>
          </div>
      </div>
  );
}