import { StoreCard } from "toolkit/card/StoreCard";
import { Col, Result, Row, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import { env } from "config";
import { useIntl } from "react-intl";
import { ConsultingInfo } from "services/rest/subscription";
import { useLocation } from "react-router-dom";
import { NavigateButton } from "components/navigate-button/NavigateButton";

export function NewConsultingSuccessPage() {
  const intl = useIntl();
  const location = useLocation();
  const consultingInfo = location.state as ConsultingInfo;

  const title = consultingInfo.offline
    ? intl.formatMessage({ id: "jmix-store.success-page.offline.title" })
    : intl.formatMessage({ id: "jmix-store.success-page.consulting.online.title" });

  const description = consultingInfo.offline
    ? (
      <div className="new-subscription-success__text">
        {intl.formatMessage({ id: "jmix-store.success-page.offline.description" }, {
          link: (str) => <a href={env.REACT_APP_JMIX_ACCOUNT_URL}>{str}</a>,
        })}
      </div>
    )
    : (
      <div>
        <div className="new-subscription-success__text">
          {intl.formatMessage({ id: "jmix-store.success-page.consulting.online.description_1" })}
        </div>
        <div className="new-subscription-success__text">
          {intl.formatMessage({ id: "jmix-store.success-page.consulting.online.description_2" }, {
            link: (str) => <a href={env.REACT_APP_JMIX_CONTACTS}>{str}</a>
          })}
        </div>
      </div>
    );

  return (
    <StoreCard className="new-subscription-success-page">
      <Row
        className="new-subscription-success__content"
        justify="space-around"
        align="middle"
      >
        <Col>
          <Space direction="vertical" align="center">
            <Result
              className="new-subscription-success__description"
              icon={<CheckOutlined className="new-subscription-success_check-icon" />}
              title={title}
              subTitle={description}
              extra={
                <NavigateButton
                  name="Go to Jmix Account"
                  path="/account"
                  type="primary"
                  ghost={false}
                />
              }
            />
          </Space>
        </Col>
      </Row>
    </StoreCard>
  );
}
