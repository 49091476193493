import { Navigate, Route, Routes } from "react-router-dom";
import { ConsultingConfigure } from "pages/store/consulting/configure/ConsultingConfigure";
import { ConsultingBilling } from "pages/store/consulting/billing/ConsultingBilling";
import React from "react";
import { NewConsultingSuccessPage } from "pages/store/consulting/success/NewConsultingSuccessPage";

export function ConsultingRoutes() {
  return (
    <>
      <Routes>
        <Route path="/configure" element={<ConsultingConfigure />} />
        <Route path="/billing" element={<ConsultingBilling />} />
        <Route path="/success" element={<NewConsultingSuccessPage />} />
        <Route index element={<Navigate to="/store/consulting/configure" />} />
      </Routes>
    </>
  );
}