import React, { useState } from "react";
import { Modal } from "antd";
import { LicenseKey } from "services/rest/subscription";
import { DateUtils } from "services/utils/DateUtils";
import { StoreTable } from "toolkit/table/StoreTable";
import { ColumnsType } from "antd/es/table";

export type LicenseKeysModalProps = {
  visible: boolean,
  onOk: (keys: LicenseKeyRow[]) => void,
  onCancel: () => void,
  licenseKeysProps?: LicenseKey[],
}

export type LicenseKeyRow = {
  key: string,
  id: string,
  licenseKey: string,
  startedOn: string,
  expireOn: string,
}

const columns: ColumnsType = [
  {
    title: "License key",
    dataIndex: "licenseKey",
    key: "licenseKey",
  },
  {
    title: "Started on",
    dataIndex: "startedOn",
    key: "startedOn",
  },
  {
    title: "Expire on",
    dataIndex: "expireOn",
    key: "expireOn",
  },
];

export function LicenseKeysModal(props: LicenseKeysModalProps) {
  const { visible,licenseKeysProps , onOk, onCancel } = props;
  const [licenseKeys, setLicenseKeys] = useState<LicenseKeyRow[]>([]);


  const dataSource = licenseKeysProps?.map(key => {
    return {
      key: key.id,
      id: key.id,
      licenseKey: key.sid,
      startedOn: DateUtils.getFormatDate(key.startDate),
      expireOn: DateUtils.getFormatDate(key.endDate),
    };
  });

  return (
    <Modal
      centered
      destroyOnClose={true}
      width="800px"
      title="Choose subscription to update"
      visible={visible}
      onOk={() => onOk(licenseKeys)}
      onCancel={onCancel}

      okText="Use selected"
    >
      <StoreTable
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys: React.Key[], selectedRows: LicenseKeyRow[]) => {
            setLicenseKeys(selectedRows);
          },
        }}
        columns={columns}
        dataSource={dataSource}
      />
    </Modal>
  );
}