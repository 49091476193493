import { Route } from "antd/es/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import React from "react";
import { Breadcrumb } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb/Breadcrumb";

export type StoreBreadcrumbProps = BreadcrumbProps;

export function StoreBreadcrumb(props: StoreBreadcrumbProps) {
  return (
    <Breadcrumb itemRender={itemRender} {...props} />
  );
}

function itemRender(route: Route) {
  const { path, breadcrumbName } = route;
  return path === "/" ? (
    <span>{breadcrumbName}</span>
  ) : (
    <Link to={path}>{breadcrumbName}</Link>
  );
}