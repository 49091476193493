import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";

export function RoleDescription() {
  return (
    <Typography className="add-user-modal__role-tooltip">
      <Paragraph>
        Admin is a role with full access. Admin sees all the keys in the subscription in the company profile and can manage payments.
      </Paragraph>
      <Paragraph>
        Worker only sees his key on the profile screen.
      </Paragraph>
      {/*<Paragraph>*/}
      {/*  Manager sees all keys in the subscription in company profile. May not have a key assigned.*/}
      {/*</Paragraph>*/}
    </Typography>
  );
}