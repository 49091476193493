import React from "react";
import { Layout } from "antd";

import { StoreContent } from "toolkit/content/StoreContent";
import { StoreFooter } from "toolkit/footer/StoreFooter";
import { MenuItem, StoreHeader } from "toolkit/header/StoreHeader";

import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAccountInfo } from "services/account/hook";
import { CustomerType, Role } from "types/general";

import { EmailVerifyBanner } from "components/email-verify-banner/EmailVerifyBanner";

import "./MainLayout.less";

type IMainLayoutProps = {
  children?: React.ReactNode
}

export const MainLayout = (props: IMainLayoutProps) => {
  const intl = useIntl();
  const { info } = useAccountInfo();

  const hidden = info.type == CustomerType.COMPANY && info.role == Role.WORKER;

  const items: MenuItem[] = [
    {
      key: "account",
      label: <Link to="/account">{intl.formatMessage({ id: "jmix-store.header.account" })}</Link>,
      hidden: false,
    },
    {
      key: "billing-info",
      label: <Link to="/billing-info">{intl.formatMessage({ id: "jmix-store.header.billing-info" })}</Link>,
      hidden: hidden,
    },
    {
      key: "store",
      label: <Link to="/store">{intl.formatMessage({ id: "jmix-store.header.store" })}</Link>,
      hidden: false,
    }
  ];

  return (
    <Layout className="main-layout">
      <EmailVerifyBanner info={info} />
      <StoreHeader items={items} />
      <StoreContent>
        <Layout className="main-layout__content">
          {props.children}
        </Layout>
      </StoreContent>
      <StoreFooter />
    </Layout>
  );
};
