import React from "react";
import { Route, Routes } from "react-router-dom";
import { EditSubscription } from "pages/store/subscription/edit/EditSubscription";
import { EditSubscriptionSuccessPage } from "pages/store/subscription/edit/success/EditSubscriptionSuccessPage";

export function EditSubscriptionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<EditSubscription />} />
      <Route path="/success" element={<EditSubscriptionSuccessPage />} />
    </Routes>
  );
}