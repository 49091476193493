import { InvoiceItem } from "components/invoice-item/InvoiceItem";
import { DateUtils } from "services/utils/DateUtils";
import { CurrencyUtils } from "services/utils/CurrencyUtils";
import { StoreList } from "toolkit/list/StoreList";
import React, { useState } from "react";
import { Pageable, Pagination } from "types/general";

import { Invoice } from "services/rest/account";
import { Result } from "antd";

import { FileTextOutlined } from "@ant-design/icons";

import { StoreError } from "pages/system/error/StoreError";

import { useIntl } from "react-intl";

import { MakePaymentModal } from "components/make-payment-modal/MakePaymentModal";
import { HelpLink } from "components/help-link/HelpLink";

import "./PostedInvoiceListCard.less";
import { StoreCard } from "toolkit/card/StoreCard";
import { AxiosResponse } from "axios";

export type InvoiceListCardProps = {
  subscriptionId?: string;
  invoiceData: InvoiceData;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
};

export type InvoiceData = {
  loading?: boolean;
  error?: string | undefined;
  response?: Pageable<Invoice>;
  fetch: FetchFunction;
};
export type FetchFunction = (
  data?: any,
) => Promise<AxiosResponse<Pageable<Invoice>, any>>;

export function PostedInvoiceListCard(props: InvoiceListCardProps) {
  const { pagination, setPagination, invoiceData } = props;
  const intl = useIntl();

  const { fetch, loading, response, error } = invoiceData;
  const [makePaymentModalVisible, setMakePaymentModalVisible] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<Invoice>();

  const content =
    response && response.total > 0 ? (
      response?.content.map((invoice) => {
        return (
          <InvoiceItem
            key={invoice.id}
            id={invoice.id}
            title={invoice.reference}
            status={invoice.status}
            isMakePayment={invoice.allowPayment}
            onMakePayment={() => {
              setMakePaymentModalVisible(true);
              setCurrentInvoice(invoice);
            }}
            date={DateUtils.getFormatDate(invoice.creationDate)}
            amount={CurrencyUtils.getFormatCurrency(
              invoice.currency,
              invoice.amount,
            )}
          />
        );
      })
    ) : (
      <Result
        icon={<FileTextOutlined size={50} style={{ color: "#DEE8ED" }} />}
        subTitle={intl.formatMessage({
          id: "jmix-store.posted-invoice-list-card.empty-list",
        })}
      />
    );

  return (
    <StoreCard
      className="posted-invoice-list-card"
      extra={
        <HelpLink
          name="jmix-store.help-link.need-help"
          to="https://docs.jmix.io/jmix/account-management.html#posted-invoices"
        />
      }
      bordered={true}
      title={intl.formatMessage({
        id: "jmix-store.posted-invoice-list-card.title",
      })}
    >
      <StoreList
        loading={loading}
        pagination={{
          pageSize: pagination.size,
          //total: response?.total || 0,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: (page) =>
            setPagination({ page: page, size: pagination.size }),
        }}
      >
        {error ? (
          <StoreError reload={fetch} autoHeight={true} bordered={false} />
        ) : (
          content
        )}
      </StoreList>
      {currentInvoice && (
        <MakePaymentModal
          invoice={currentInvoice}
          visible={makePaymentModalVisible}
          onClose={() => setMakePaymentModalVisible(false)}
          onAfterPayment={fetch}
        />
      )}
    </StoreCard>
  );
}
