import React from "react";
import { Col, Row } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import Text from "antd/lib/typography/Text";

import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { StoreTable } from "toolkit/table/StoreTable";

import "./Consultancy.less";
import { useConsultancyHours } from "services/rest/consulting";
import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";
import { DateUtils } from "services/utils/DateUtils";
import { NavigateButton } from "components/navigate-button/NavigateButton";
import { ProgressBar } from "components/progress-bar/ProgressBar";
import { StoreError } from "pages/system/error/StoreError";
import { DownloadLink } from "components/download-link/DownloadFile";
import { env } from "config";
import { useIntl } from "react-intl";
import { HelpLink } from "components/help-link/HelpLink";

type HourEvent = {
  key: number,
  date: string,
  hours: number,
  fileId: string,
  fileType: string,
  isWriteOff: boolean,
}

const routes: Route[] = [
  {
    path: "/account",
    breadcrumbName: "Account",
  },
  {
    path: "",
    breadcrumbName: "",
  },
];

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Hours",
    dataIndex: "hours",
    key: "hours",
    render: (text: number, record: HourEvent) => (
      <Text
        className={record.isWriteOff ? "consultancy__hours_red" : "consultancy__hours_green"}
      >
        {record.isWriteOff ? "-" + text : "+" + text}
      </Text>
    ),
  },
  {
    title: "Report",
    dataIndex: "report",
    key: "report",
    render: (text: string, record: HourEvent) => {
      if (!record.fileId) {
        return "";
      }
      const link = `${env.REACT_APP_JMIX_STORE_BACKEND_URL}/rest/v3/profile/balance/consultancy/${record.fileId}/file`;
      return (
        <DownloadLink link={link} fileType={record.fileType} />
      );
    }
  },
];

export function Consultancy() {
  const { response, loading, error } = useConsultancyHours();

  if (error) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }
  const intl = useIntl();
  const complete = response?.unitsUsed;
  const total = response?.unitsTotal;

  const data: HourEvent[] = response?.eventList.map((event, index_) => {
    return {
      key: index_,
      date: DateUtils.getFormatDate(event.date),
      hours: event.units,
      isWriteOff: event.isWriteOff,
      fileId: event.fileId,
      fileType: event.fileType,
    };
  }) || [];

  return (
    <>
      <StorePageHeader
        title={intl.formatMessage({ id: "jmix-store.consultancy.title" })}
        extra={<NavigateButton ghost={false} name="Top up the balance" path="/store/consulting" />}
        breadcrumb={<StoreBreadcrumb routes={routes} />}
      />
      <StoreLayout>
        <StoreCard
          bordered
          title={intl.formatMessage({ id: "jmix-store.consultancy.card.title" })}
          className="consultancy"
          loading={loading}
          extra={
            <HelpLink name="jmix-store.help-link.need-help"
                      to="https://docs.jmix.io/jmix/account-management.html#consulting-reports" />
          }
        >
          <Row gutter={[16, 16]}>
            <Col flex="100%">
              <ProgressBar
                complete={complete || 0}
                total={total || 0}
                unitName="hours"
              />
            </Col>
            <Col flex="100%">
              <StoreTable
                dataSource={data}
                columns={columns}
              />
            </Col>
          </Row>
        </StoreCard>
      </StoreLayout>
    </>
  );
}

