import { useAxiosWithoutAuthorize } from "services/rest/request";
import { env } from "config";

const baseUrl: string = env.REACT_APP_JMIX_STORE_BACKEND_URL || "";

export const useHealthCheck = () => {
  return useAxiosWithoutAuthorize({
    url: `${baseUrl}/dispatch/health`,
    method: "get",
  }, []);
};
