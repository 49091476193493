import * as React from "react";
import { Select, SelectProps } from "antd";
import { useMembers } from "services/rest/member";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useEffect, useState } from "react";
import { Pagination } from "types/general";

export type UserTeamSelectProps = SelectProps;

export function UserTeamSelect(props: UserTeamSelectProps) {
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 10 });
  const { response, loading } = useMembers(pagination.page, pagination.size);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    const newOptions = response?.content.map((member) => {
      const label = member.enabled
        ? member.firstName + " " + member.lastName
        : member.email;

      return {
        label: label,
        value: member.id,
      };
    }) || [];

    setOptions([...options, ...newOptions]);
  }, [response]);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      options={options}
      placeholder="Select User"
      onPopupScroll={(event: React.UIEvent<HTMLDivElement>) => {
        const target: any  = event.target;
        const total = response?.total || 0;

        if (target.scrollTop + target.offsetHeight >= target.scrollHeight
          && total > pagination.page * pagination.size
        ) {
          setPagination({ page: pagination.page + 1, size: pagination.size });
        }
      }}
    />
  );
}