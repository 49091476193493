import { baseUrl, useAxios } from "services/rest/request";
import { Currency, CustomerType, LocalDate, Pageable, Role } from "types/general";

export enum Eligible {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
  ACADEMIC = "ACADEMIC",
  COMPANY_UNCONFIRMED = "COMPANY_UNCONFIRMED",
  INDIVIDUAL_UNCONFIRMED = "INDIVIDUAL_UNCONFIRMED",
  ACADEMIC_UNCONFIRMED = "ACADEMIC_UNCONFIRMED",
}

export type Invoice = {
  id: string,
  amount: number,
  currency: Currency,
  status: string,
  reference: string,
  pdfLink: string,
  creationDate: LocalDate,
  allowPayment: boolean,
}

export type Balance = {
  incident: IncidentInfo,
  consultancy: ConsultancyInfo,
}

export type IncidentInfo = {
  incidentsTotal: number,
  incidentsUsed: number;
}

export type ConsultancyInfo = {
  hoursTotal: number,
  hoursUsed: number,
}

export type ProfileInfo = {
  type: CustomerType,
  name: string,
  companyName: string,
  role: Role,
  eligible: Eligible,
  email: string,
  verifyEmail: boolean,
}

export type CheckTrialResponse = {
  available: boolean,
}

export type GenerateTrialResponse = {
  link: string,
  subscriptionId: string,
  expiryDate: LocalDate,
}

export type ProductInfo = {
  infoSource: InfoSourceType,
  infoSourceDesc: string,
  searchType: SearchType,
  searchTypeDescription: string;
}

export enum InfoSourceType {
  SEARCH_ENGINE = "SEARCH_ENGINE",
  ONLINE_ADVERTISING = "ONLINE_ADVERTISING",
  WEBSITE = "WEBSITE",
  SOCIAL_NETWORKS = "SOCIAL_NETWORKS",
  PERSONAL_RECOMMENDATION = "PERSONAL_RECOMMENDATION",
  CONFERENCE = "CONFERENCE",
  OTHER = "OTHER",
}

export enum SearchType {
  PRODUCT_REPLACEMENT = "PRODUCT_REPLACEMENT",
  PRODUCT_DEVELOPMENT = "PRODUCT_DEVELOPMENT",
  SEARCH_INTERNET = "SEARCH_INTERNET",
  OTHER = "OTHER",
}

export type AccountLicenseInfo = {
  licenseCount: number,
  commercialLicenseCount: number,
  trialLicenseCount: number,
}

export type SendEmailInfo = {
  success: boolean;
  repeatIn: number;
};

export function useAccountInfo() {
  return useAxios<ProfileInfo>({
    url: `${baseUrl}/profile/info`,
    method: "get",
  }, []);
}

export function getPaidInvoices() {
  return useAxios<Pageable<Invoice>>(
    {
      url: `${baseUrl}/profile/invoices/paid`,
      method: "get",
    }, []);
}

export function getPostedInvoices() {
  return useAxios<Pageable<Invoice>>(
    {
      url: `${baseUrl}/profile/invoices/unpaid`,
      method: "get",
    }, []);
}

export function useBalance() {
  return useAxios<Balance>({
    url: `${baseUrl}/profile/balance`,
    method: "get",
  }, []);
}

export function useCheckTrial() {
  return useAxios<CheckTrialResponse>({
    url: `${baseUrl}/trial/addons/check`,
    method: "get",
  }, []);
}


export function useGenerateTrial() {
  return useAxios<GenerateTrialResponse>({
    url: `${baseUrl}/trial/addons/generate`,
    method: "post",
  }, [], false);
}

export function useSendVerifyEmail() {
  return useAxios<SendEmailInfo>({
    url: `${baseUrl}/profile/send-verify-email`,
    method: "post",
  }, [], false);
}

export function useSendProductInfo() {
  return useAxios({
    url: `${baseUrl}/profile/product-info/send`,
    method: "post"
  }, [], false)
}


export const useAccountLicenseInfo = () => {
  return useAxios<AccountLicenseInfo>({
    url: `${baseUrl}/profile/license-info`,
    method: "get"
  }, [])
}