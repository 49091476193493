import { Col, Form, InputNumber, notification, Radio, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { PaymentMethodFormItem } from "components/payment-billing-info/PaymentMethodFormItem";
import { AgreementCheckBox } from "components/agreement-checkbox/AgreementCheckBox";
import { SubscriptionEditCostCard } from "components/subscription-edit-cost-card/SubscriptionEditCostCard";
import { StoreCard } from "toolkit/card/StoreCard";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "antd/es/form/Form";
import {
  SubscriptionInfo,
  usePayUpdateSubscription,
  usePlans,
} from "services/rest/subscription";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { useIntl } from "react-intl";
import { PaymentType } from "types/general";
import { LicenseKeyRow } from "../../new/modal/license-keys/LicenseKeysModal";
import { LicenseKeysToDeleteModal } from "../../new/modal/license-keys/LicenseKeysToDeleteModal";
import { StoreError } from "pages/system/error/StoreError";
import { ErrorResponse } from "services/rest/general";
import { useError } from "services/error/hook";
import { AxiosResponse } from "axios";

export type EditSubscriptionConfigureProps = {
  subscriptionInfo?: SubscriptionInfo;
  loading: boolean;
};

export function EditSubscriptionConfigure(
  props: EditSubscriptionConfigureProps,
) {
  const { subscriptionInfo, loading } = props;
  const [licenseKeysModalVisible, setLicenseKeysModalVisible] = useState(false);
  const [form] = useForm();
  const errorHook = useError();

  const navigate = useNavigate();
  const intl = useIntl();
  const tariff = useWatch("tariff", form);
  const count = useWatch("count", form);
  const currency = useWatch("currency", form);
  const paymentType = useWatch("paymentType", form);

  const [editSubscriptionPromoCode, setEditSubscriptionPromoCode] =
    useState("");

  const handleApplyPromoCode = (editSubscriptionPromoCode: string) => {
    setEditSubscriptionPromoCode(editSubscriptionPromoCode);
  };

  useEffect(() => {
    if (subscriptionInfo && subscriptionInfo.currentPromoCode !== undefined) {
      setEditSubscriptionPromoCode(subscriptionInfo.currentPromoCode);
    }
  }, [subscriptionInfo]);

  const {
    response: plansResponse,
    loading: plansLoading,
    error: plansError,
  } = usePlans(
    subscriptionInfo?.duration.duration,
    subscriptionInfo?.duration.durationUnit,
    [subscriptionInfo],
  );

  const { loading: updateSubscriptionLoading, fetch: updateSubscriptionFetch } =
    usePayUpdateSubscription();

  const planOptions =
    plansResponse?.map((tariff) => {
      const isDisabled =
        subscriptionInfo &&
        (tariff.priority < subscriptionInfo.tariff.priority ||
          tariff.plans.length == 0);

      return {
        label: tariff.name,
        value: tariff.id,
        disabled: isDisabled,
      };
    }) || [];

  const userCount = subscriptionInfo ? subscriptionInfo.userCount : 0;
  useEffect(() => {
    form.setFieldsValue({
      count: subscriptionInfo?.userCount,
      tariff: subscriptionInfo?.tariff.id,
    });
  }, [subscriptionInfo]);

  if (plansError) {
    return <StoreError />;
  }

  if (plansLoading || loading || updateSubscriptionLoading) {
    return <StoreLoading />;
  }

  const isChangeError =
    count != subscriptionInfo?.userCount &&
    tariff != subscriptionInfo?.tariff.id;

  const isIndividualCountError =
    count > 1 && subscriptionInfo?.individualPrice == true;

  function updateSubscription(
    subscriptionInfo?: SubscriptionInfo,
    keys?: LicenseKeyRow[],
  ) {
    updateSubscriptionFetch({
      subscriptionId: subscriptionInfo?.id,
      tariffId: form.getFieldValue("tariff"),
      quantity: form.getFieldValue("count"),
      promocode: editSubscriptionPromoCode,
      offlineInvoice: paymentType == PaymentType.OFFLINE,
      licenseIdsToBeSuspended: keys ? keys.map((license) => license.id) : [],
    })
      .then((response: AxiosResponse<SubscriptionInfo>) => {
        navigate("../success", {
          state: {
            ...response.data,
          },
        });
      })
      .catch((error: ErrorResponse) => {
        notification.error({
          message: intl.formatMessage({ id: "jmix-store.payment-error.title" }),
          description: errorHook.getDescription(error),
          placement: "bottomLeft",
        });
      });
  }

  return (
    <>
      <StoreCard className="new-subscription__card">
        <Row gutter={[64, 64]}>
          <Col flex="1 1 500px">
            <Form layout="vertical" form={form}>
              <Form.Item
                name="group"
                rules={[
                  {
                    validateTrigger: "onChange",
                    validator: () =>
                      isChangeError
                        ? Promise.reject(
                            "It is not possible to change users and plan at the same time. Please do this in two steps.",
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Row>
                  <Col className="edit-subscription__col" flex="1 1 200px">
                    <Form.Item
                      label="Plan"
                      name="tariff"
                      tooltip={{
                        overlay: <Link to="store">See Jmix plan</Link>,
                        color: "#CADAE3",
                      }}
                    >
                      <Radio.Group options={planOptions} optionType="button" />
                    </Form.Item>
                  </Col>
                  <Col className="edit-subscription__col" flex="1 1 150px">
                    <Form.Item
                      label="Users"
                      name="count"
                      rules={[
                        {
                          validateTrigger: "onChange",
                          validator: () =>
                            isIndividualCountError
                              ? Promise.reject(
                                  "Individual users can have only one license key. Please switch to the company pricing if you need more than one key.",
                                )
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row>
                <Col flex="auto">
                  <PaymentMethodFormItem
                    allowedOffline={subscriptionInfo?.offline}
                    hideCurrency
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  intl.formatMessage({
                                    id: "jmix-store.subscription-configure.agreement.rule",
                                  }),
                                ),
                              ),
                      },
                    ]}
                  >
                    <AgreementCheckBox agreement="license" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col flex="1 1 300px">
            {subscriptionInfo && (
              <SubscriptionEditCostCard
                currency={currency}
                subscriptionInfo={subscriptionInfo}
                tariffId={tariff}
                count={count}
                onApplyEditSubscriptionPromoCode={handleApplyPromoCode}
                onProcess={() => {
                  form.validateFields().then(() => {
                    if (form.getFieldValue("count") < userCount) {
                      setLicenseKeysModalVisible(true);
                    } else {
                      updateSubscription(subscriptionInfo);
                    }
                  });
                }}
              />
            )}
          </Col>
        </Row>
      </StoreCard>
      <LicenseKeysToDeleteModal
        visible={licenseKeysModalVisible}
        onOk={(keys: LicenseKeyRow[]) => {
          setLicenseKeysModalVisible(false);
          updateSubscription(subscriptionInfo, keys);
        }}
        onCancel={() => {
          setLicenseKeysModalVisible(false);
        }}
        subscriptionId={subscriptionInfo?.id}
        licenseKeysCount={userCount - form.getFieldValue("count")}
      />
    </>
  );
}
