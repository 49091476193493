import React from "react";
import {
  Button, Col, Result, Row,
} from "antd";
import { useNavigate } from "react-router-dom";

import { StoreLayout } from "components/store-layout/StoreLayout";

import "./StoreNotFound.less";

export function StoreNotFound() {
  const navigate = useNavigate();

  return (
    <StoreLayout>
      <Row
        className="store-not-found__content"
        justify="space-around"
        align="middle"
      >
        <Col>
          <Result
            status="404"
            title="404"
            subTitle={<strong>Page Not Found!</strong>}
            extra={(
              <Button
                type="primary"
                onClick={() => navigate("/")}
              >
                Back Home
              </Button>
            )}
          />
        </Col>
      </Row>
    </StoreLayout>
  );
}
