import React from "react";
import {StoreCard} from "toolkit/card/StoreCard";
import {Col, Form, Row, Select, Steps} from "antd";
import {AgreementCheckBox} from "components/agreement-checkbox/AgreementCheckBox";
import {useIntl} from "react-intl";
import {useProductInfo} from "services/rest/product";
import {StoreError} from "pages/system/error/StoreError";
import {StoreLoading} from "pages/system/loading/StoreLoading";
import {useForm, useWatch} from "antd/es/form/Form";
import {ProductCostCard} from "components/product-cost-card/ProductCostCard";

import "pages/store/product/configure/ProductConfigurePage.less";

export type NewProductConsultingForm = {
    product: string
}

export function ProductConfigurePage() {
    const intl = useIntl();
    const [form] = useForm<NewProductConsultingForm>();
    const {response, loading, fetch, error} = useProductInfo();

    const productId = useWatch("product", form);

    const options = response?.map((product) => {
        return {
            label: product.name,
            value: product.id,
        };
    });

    if (error) {
        return (
            <StoreError
                reload={fetch}
            />
        );
    }

    if (loading) {
        return (
            <StoreLoading/>
        );
    }

    return (
        <StoreCard className="product-configure-page__card">
            <Row gutter={[16, 16]}>
                <Col flex="1 1 580px">
                    <Row className="product-configure__header" justify="space-between" align="middle">
                        <div className="product-configure__steps">
                            <Steps current={0}>
                                <Steps.Step
                                    key="config"
                                    title={intl.formatMessage({id: "jmix-store.product-page.settings-step"})}
                                />
                                <Steps.Step
                                    key="billing"
                                    title={intl.formatMessage({id: "jmix-store.product-page.billing-step"})}
                                />
                            </Steps>
                        </div>
                    </Row>
                    <Form
                        form={form}
                        layout="vertical">
                        <Row gutter={[16, 16]}>
                            <Col flex="0 0 250px">
                                <Form.Item
                                    label={intl.formatMessage({id: "jmix-store.product-configure.products"})}
                                    name="product"
                                    initialValue={response?.[0].id}
                                >
                                    <Select
                                        options={options}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item
                                required
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(new Error(intl.formatMessage({id: "jmix-store.subscription-configure.agreement.rule"}))),
                                    },
                                ]}
                            >
                                <AgreementCheckBox agreement="license"/>
                            </Form.Item>
                        </Row>
                    </Form>
                </Col>
                <Col flex="1 1 300px">
                    {
                        productId &&
                        <ProductCostCard
                            productId={productId}
                            onProcess={form.validateFields}
                        />
                    }
                </Col>
            </Row>
        </StoreCard>
    );
}