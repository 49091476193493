import { Select, SelectProps } from "antd";
import { Role } from "types/general";
import React from "react";
import { useIntl } from "react-intl";

export type RoleSelectProps = SelectProps

export function RoleSelect(props: RoleSelectProps) {
  const intl = useIntl();

  return (
    <Select
      {...props}
      placeholder="Select role"
    >
      <Select.Option value={Role.ADMIN}>{intl.formatMessage({id: "jmix-store.role.ADMIN"})}</Select.Option>
      {/*<Select.Option value={Role.MANAGER}>{intl.formatMessage({id: "jmix-store.role.MANAGER"})}</Select.Option>*/}
      <Select.Option value={Role.WORKER}>{intl.formatMessage({id: "jmix-store.role.WORKER"})}</Select.Option>
    </Select>
  );
}