import React, { useState } from "react";
import { Button, Modal } from "antd";
import {
    LicenseKey,
    LicenseState,
    useSubscriptionLicenseKeys,
} from "services/rest/subscription";
import { StoreTable } from "toolkit/table/StoreTable";
import { ColumnsType } from "antd/es/table";
import { Pagination } from "types/general";
import { DateUtils } from "services/utils/DateUtils";
import {Customer} from "services/rest/general";

export type LicenseKeysToDeleteModalProps = {
  visible: boolean;
  onOk: (keys: LicenseKeyRow[]) => void;
  onCancel: () => void;
  subscriptionId?: string;
  licenseKeysCount?: number;
};

export type LicenseKeyRow = {
  key: string;
  id: string;
  licenseKey: string;
  customer: Customer;
  startedOn: string;
  expireOn: string;
};

const columns: ColumnsType = [
  {
    title: "License key",
    dataIndex: "licenseKey",
    key: "licenseKey",
  },
    {
    title: "Assigned to",
    render: (key: LicenseKey) => {
        if (!key.customer) {
            return (
                <div className="license-keys-card__not-assigned">Not Assigned</div>
            );
        }

        const customerName = key.customer.firstName+ " " + key.customer.lastName
        return (
            <div>
                <div className="license-keys-card__email">{key.customer.email}</div>
                <span className="license-keys-card__name">{customerName}</span>
            </div>
        );
    },
  },
  {
    title: "Started on",
    dataIndex: "startedOn",
    key: "startedOn",
  },
  {
    title: "Expire on",
    dataIndex: "expireOn",
    key: "expireOn",
  },
];

export function LicenseKeysToDeleteModal(props: LicenseKeysToDeleteModalProps) {
  const { visible, subscriptionId, onOk, onCancel, licenseKeysCount } = props;
  const [licenseKeys, setLicenseKeys] = useState<LicenseKeyRow[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 10,
  });
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

  if (!subscriptionId) {
    return <Modal />;
  }
  const { response } = useSubscriptionLicenseKeys(
    subscriptionId,
    pagination.page,
    pagination.size
  );

  const dataSource = response?.content
    .filter((key) => key.state != LicenseState.NON_RENEWING)
    .map((key) => {
      return {
        key: key.id,
        id: key.id,
        licenseKey: key.sid,
        customer: key.customer,
        startedOn: DateUtils.getFormatDate(key.startDate),
        expireOn: DateUtils.getFormatDate(key.endDate),
      };
    });

  return (
    <Modal
      centered
      destroyOnClose={true}
      width="800px"
      title="Choose keys to disable"
      open={visible}
      onOk={() => onOk(licenseKeys)}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          disabled={updateButtonDisabled}
          type={"primary"}
          key="update"
          onClick={() => {
            onOk(licenseKeys);
          }}
        >
          Update
        </Button>,
      ]}
      okText="Update"
    >
      <StoreTable
        pagination={{
          total: response?.total,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: (page) => setPagination({ page: page, size: pagination.size }),
        }}
        rowSelection={{
          type: "checkbox",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: LicenseKeyRow[]
          ) => {
            if (selectedRowKeys.length === licenseKeysCount) {
              setUpdateButtonDisabled(false);
            } else {
              setUpdateButtonDisabled(true);
            }
            setLicenseKeys(selectedRows);
          },
        }}
        columns={columns}
        dataSource={dataSource}
      />
    </Modal>
  );
}
