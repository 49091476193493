import { baseUrl, useAxios } from "services/rest/request";
import { Pageable, Role } from "types/general";
import { ValidateResponse } from "services/rest/general";
import { DependencyList } from "react";

export type Member = {
  id: string,
  firstName: string,
  lastName: string,
  enabled: boolean,
  email: string,
  role: Role,
}

export function useMembers(page = 1, size = 5) {
  return useAxios<Pageable<Member>>({
    url: `${baseUrl}/company/members?page=${page}&size=${size}`,
    method: "get",
  }, [page, size]);
}

export function useDeleteMember(id: string) {
  return useAxios({
    url: `${baseUrl}/company/members/${id}`,
    method: "delete",
  }, [], false)
}

export type EditMemberRequest = {
  role: Role,
}

export function useEditMember(id: string) {
  return useAxios<EditMemberRequest>({
    url: `${baseUrl}/company/members/${id}`,
    method: "put",
  }, [], false)
}

export type AddMemberRequest = {
  email: string,
  role: Role,
}

export function useAddMember() {
  return useAxios<AddMemberRequest>({
    url: `${baseUrl}/company/members`,
    method: "post",
  }, [], false);
}

export function useCheckMember(email: string, deps: DependencyList) {
  return useAxios<ValidateResponse>({
    url: `${baseUrl}/company/members/email-verification?email=${email}`,
    method: "get",
  }, deps, false);
}

export function useCheckDeleteMember(memberId: string) {
  return useAxios<ValidateResponse>({
    url: `${baseUrl}/validate/delete-member?memberId=${memberId}`,
    method: "get",
  }, [], false);
}