import React from "react";
import { Statistic, StatisticProps } from "antd";

export type StoreStatisticProps = StatisticProps

import "./StoreStatistic.less";

export function StoreStatistic(props: StoreStatisticProps) {
  const { className, ...rest } = props;

  return (
    <Statistic
      className={`store-statistic ${className} `}
      {...rest}
    />
  );
}