import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SubscriptionBillingInfo } from "pages/store/subscription/billing-info/SubscriptionBillingInfo";
import { SubscriptionConfigure } from "pages/store/subscription/new/configure/SubscriptionConfigure";
import { NewSubscriptionSuccessPage } from "pages/store/subscription/new/success/NewSubscriptionSuccessPage";

export function NewSubscriptionRoutes() {
  return (
    <Routes>
      <Route path="/billing" element={<SubscriptionBillingInfo />} />
      <Route path="/success" element={<NewSubscriptionSuccessPage />} />
      <Route path="/configure" element={<SubscriptionConfigure />} />
      <Route index element={<Navigate to="/store/subscription/new/configure" />} />
    </Routes>
  );
}