import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Account } from "pages/account/Account";
import { BillingInfo } from "pages/billing_info/BillingInfo";
import { Store } from "pages/store/Store";
import { StoreError } from "pages/system/error/StoreError";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { StoreNotFound } from "pages/system/not-found/StoreNotFound";

export function StoreGeneralRoutes() {
  return (
    <Routes>
      <Route
        path="/billing-info"
        element={<BillingInfo />}
      />
      <Route
        path="/account/*"
        element={<Account />}
      />
      <Route
        path="/loading"
        element={<StoreLoading />}
      />
      <Route
        path="/error"
        element={<StoreError />}
      />
      <Route
        path="/store/*"
        element={<Store />}
      />
      <Route
        path="/"
        element={<Navigate to="/store" />}
      />
      <Route
        path="*"
        element={<StoreNotFound />}
      />
    </Routes>
  );
}
