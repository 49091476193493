import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Col, Form, InputNumber, Modal, Radio, Row, Steps, } from "antd";
import React, { useEffect, useState } from "react";
import { LicenseKeyRow, LicenseKeysModal, } from "pages/store/subscription/new/modal/license-keys/LicenseKeysModal";
import { useForm, useWatch } from "antd/es/form/Form";
import {usePlans, useProfileLicenseKeys, useProfileSubscriptions} from "services/rest/subscription";
import { DateUtils } from "services/utils/DateUtils";
import { LicenseType } from "types/subscription";
import { AcademicCard } from "components/academic-card/AcademicCard";
import { SubscriptionCostCard } from "components/subscription-cost-card/SubscriptionCostCard";

import { StoreCard } from "toolkit/card/StoreCard";
import { AgreementCheckBox } from "components/agreement-checkbox/AgreementCheckBox";
import { RadioProps } from "antd/lib/radio/interface";
import { useAccountInfo } from "services/account/hook";
import { CustomerType } from "types/general";
import { CheckboxOptionType } from "antd/lib/checkbox/Group";

import { useIntl } from "react-intl";
import { StoreError } from "pages/system/error/StoreError";
import { StoreLoading } from "pages/system/loading/StoreLoading";

import {
  SubscriptionModal,
  SubscriptionRow,
} from "pages/store/subscription/new/modal/subscriptions/SubscriptionModal";

import { IAccountInfo } from "services/account/AccountContext";

import "./SubscriptionConfigure.less";
import { HelpLink } from "components/help-link/HelpLink";
import { SubscriptionUtils } from "services/utils/SubscriptionUtils";
import {InfoCircleTwoTone} from "@ant-design/icons";

export type NewSubscriptionForm = {
  type: string;
  quantity: number;
};

export function SubscriptionConfigure() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = useForm<NewSubscriptionForm>();
  const { info } = useAccountInfo();
  const { response: licenseKeysResponse  } = useProfileLicenseKeys();

  const initialLicenseType = getInitialLicenseType(info);

  const {
    response: plansResponse,
    loading: plansLoading,
    error: plansError
  } = usePlans();
  const {
    response: subscriptionsResponse,
    loading: subscriptionsLoading,
    error: subscriptionsError
  } = useProfileSubscriptions();

  const activeSubscriptions = subscriptionsResponse != undefined
    ? SubscriptionUtils.getActiveSubscriptions(subscriptionsResponse)
    : undefined;

  const licenseType = useWatch<LicenseType>("type", form) || initialLicenseType;
  const quantity = useWatch("quantity", form);

  const [tariff, setTariff] = useState(0);
  const [period, setPeriod] = useState(0);

  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  const [licenseKeysModalVisible, setLicenseKeysModalVisible] = useState(false);
  const [licenseKeysCheckboxChecked, setLicenseKeysCheckboxChecked] = useState(false);

  useEffect(() => {
    setSubscriptionModalVisible(!!activeSubscriptions && activeSubscriptions.length > 0);
  }, []);

  const isLicenseKeyCheckboxVisible = licenseKeysResponse !== undefined && licenseKeysResponse.length > 0
  const [licenseKeys, setLicenseKeys] = useState<LicenseKeyRow[]>([]);

  if (plansError || subscriptionsError) {
    return (
      <StoreError />
    );
  }

  if (!plansResponse || !subscriptionsResponse || plansLoading || subscriptionsLoading) {
    return (
      <StoreLoading />
    );
  }

  const planOptions =
    plansResponse.map((tariff, index) => {
      return {
        label: tariff.name,
        value: index,
      };
    }) || [];

  const periodOptions = plansResponse
    ? plansResponse[tariff].plans.map((plan, index) => {
      return {
        label: `${DateUtils.getFormatDuration(plan.duration)}`,
        value: index,
      };
    })
    : [];

  const plan = plansResponse[tariff].plans[period];

  const isCompany = licenseType === "company";
  const isIndividual = licenseType === "individual";
  const isAcademic = licenseType === "academic";

  return (
    <StoreCard className="new-subscription__card">
      <Row gutter={[16, 16]} align="stretch">
        <Col flex="1 1 580px">
          <Row className="configuration-subscription__header" justify="space-between" align="middle">
            <div className="configuration-subscription__steps">
              <Steps current={0}>
                <Steps.Step
                  key="config"
                  title="License Settings"
                />
                <Steps.Step
                  key="billing-info"
                  title="Billing Info"
                />
              </Steps>
            </div>
            <HelpLink name="jmix-store.help-link.license"
                      to="https://docs.jmix.io/jmix/account-management.html#subscription-purchase" />
          </Row>
          <Form layout="vertical" form={form}>
            <Row gutter={32}>
              <Col flex="1 1 320px">
                <Form.Item
                  label={intl.formatMessage({
                    id: "jmix-store.subscription-configure.license-type",
                  })}
                  name="type"
                  initialValue={initialLicenseType}
                >
                  <LicenseTypeGroup />
                </Form.Item>
              </Col>
              {
                !isAcademic &&
                <Col flex="1 1 320px">
                  <Form.Item
                    label={intl.formatMessage({
                      id: "jmix-store.subscription-configure.plan",
                    })}
                    name="plan"
                    initialValue={tariff}
                    tooltip={{
                      overlay: <Link to="store">See Jmix plan</Link>,
                      color: "#CADAE3",
                    }}
                  >
                    <Radio.Group
                      value={tariff}
                      options={planOptions}
                      optionType="button"
                      onChange={(e) => {
                        setTariff(e.target.value);
                        setPeriod(0);
                      }}
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
            {
              isIndividual &&
              <Row>
                <Col flex="640px">
                  <div className="configuration-subscription__individual-description">
                    {intl.formatMessage({
                      id: "jmix-store.subscription-configure.individual-error",
                    })}
                  </div>
                </Col>
              </Row>
            }
            <Row gutter={32}>
              {
                isCompany &&
                <Col flex="1 1 320px">
                  <Form.Item
                    label={intl.formatMessage({
                      id: "jmix-store.subscription-configure.users",
                    })}
                    name="quantity"
                    initialValue={1}
                    rules={[
                      {
                        message: "Please input users",
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber min={1} style={{ width: "160px" }} />
                  </Form.Item>
                </Col>
              }
              {
                !isAcademic &&
                <Col flex="1 1 320px">
                  <Form.Item
                    label={intl.formatMessage({
                      id: "jmix-store.subscription-configure.period",
                    })}
                    name="period"
                    initialValue={period}
                  >
                    <Radio.Group
                      value={period}
                      options={periodOptions}
                      optionType="button"
                      onChange={(e) => setPeriod(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              }
            </Row>
            <div className="configuration-subscription__checks">
              <Row gutter={[32, 16]}>
                {
                  !isAcademic && isLicenseKeyCheckboxVisible &&
                  <Col flex="100%">
                    <Checkbox
                      checked={licenseKeysCheckboxChecked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setLicenseKeysModalVisible(e.target.checked);
                        } else {
                          setLicenseKeys([]);
                        }
                        setLicenseKeysCheckboxChecked(e.target.checked);
                      }}
                    >
                      Use my current license keys
                    </Checkbox>
                  </Col>
                }

                <Col flex="100%">
                  <Form.Item
                    required
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                              new Error(
                                intl.formatMessage({
                                  id: "jmix-store.subscription-configure.agreement.rule",
                                })
                              )
                            ),
                      },
                    ]}
                  >
                    <AgreementCheckBox agreement="license" />
                  </Form.Item>
                  <LicenseKeysInfo
                    licenseKeys={licenseKeys}
                  />
                </Col>
              </Row>
              {
                activeSubscriptions?.length !== 0 && (
                  <Button
                    onClick={() => setSubscriptionModalVisible(true)}>
                    Edit existing subscription
                  </Button>)
              }
            </div>
          </Form>
        </Col>
        <Col flex="1 1 300px">
          {
            isAcademic ?
              <AcademicCard onProceed={form.validateFields} /> :
              <SubscriptionCostCard
                quantity={quantity}
                planId={plan.id}
                licenseType={licenseType}
                licenseKeys={licenseKeys}
                onProceed={form.validateFields}
              />
          }
        </Col>
      </Row>
      <LicenseKeysModal
        visible={licenseKeysModalVisible}
        licenseKeysProps={licenseKeysResponse}
        onOk={(keys: LicenseKeyRow[]) => {
          if (keys.find(key=>new Date(key.expireOn) >= new Date())) {
            Modal.confirm({
              title: "New subscription with active license key",
              content: <div>You chose a license key with the expiration date in the future. The expiration date will be set according to the new subscription.<br/>
                Are you sure you would like to proceed? </div>,
              icon: <InfoCircleTwoTone/>,
              centered: true,
              onOk() {
                setLicenseKeysCheckboxChecked(keys.length > 0);
                setLicenseKeys(keys);
                setLicenseKeysModalVisible(false);
              }
            })
          } else {
            setLicenseKeysCheckboxChecked(keys.length > 0);
            setLicenseKeys(keys);
            setLicenseKeysModalVisible(false);
          }
        }}
        onCancel={() => {
          setLicenseKeysCheckboxChecked(licenseKeys.length > 0);
          setLicenseKeysModalVisible(false);
        }}
      />
      {
        <SubscriptionModal
          visible={subscriptionModalVisible}
          loading={subscriptionsLoading}
          activeSubscriptions={activeSubscriptions}
          onOk={(subscription: SubscriptionRow) => {
            if (subscription != undefined) {
              navigate(`/store/subscription/${subscription.id}`);
            }
          }}
          onCancel={() => {
            setSubscriptionModalVisible(false);
          }}
        />
      }
    </StoreCard>
  );
}

function getInitialLicenseType(info: IAccountInfo) {
  const params = new URLSearchParams(window.location.search);

  const paramLicenseType = Object.entries(LicenseType).find(
    ([, val]) => val === params?.get("license_to")
  )?.[1];

  if (!paramLicenseType || (paramLicenseType == LicenseType.INDIVIDUAL && info.type != CustomerType.INDIVIDUAL)) {
    return LicenseType.COMPANY;
  }

  return paramLicenseType;
}

type LicenseTypeGroupProps = RadioProps;

type SecuredCheckboxOptionType = CheckboxOptionType & {
  hidden?: boolean;
};

function LicenseTypeGroup(props: LicenseTypeGroupProps) {
  const { info } = useAccountInfo();

  const typeOptions: SecuredCheckboxOptionType[] = [
    {
      label: "Company",
      value: "company",
    },
    {
      label: "Individual",
      value: "individual",
      hidden: info.type != CustomerType.INDIVIDUAL,
    },
    {
      label: "Academic",
      value: "academic",
    },
  ];

  const options = typeOptions.filter((option) => !option.hidden);

  return <Radio.Group {...props} options={options} optionType="button" />;
}

type LicenseKeysInfoProps = {
  licenseKeys: LicenseKeyRow[];
};

function LicenseKeysInfo(props: LicenseKeysInfoProps) {
  const { licenseKeys } = props;

  if (licenseKeys.length == 0) {
    return null;
  }

  return (
    <div className="new-subscription__license-keys">
      {
        licenseKeys.map((key) =>
          <div key={key.id} className="new-subscription__license-keys-item">
            <div>{key.licenseKey}</div>
            <span>
              Started on {key.startedOn} / Expired on {key.expireOn}
            </span>
          </div>
        )
      }
    </div>
  );
}
