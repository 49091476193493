import React, { useState } from "react";
import { Button, Col, Divider, Row, } from "antd";

import { StoreCard } from "toolkit/card/StoreCard";

import { useConsultingCost } from "services/rest/consulting";

import { DiscountInfo } from "components/discount-info/DiscountInfo";

import { PromoCodeButton } from "components/promo-code-button/PromoCodeButton";
import { BasketType, useBasket } from "services/basket/hook";
import { useNavigate } from "react-router-dom";
import { ProductType } from "types/general";
import { VatInfo } from "components/vat-info/VatInfo";
import { env } from "config";

import { NewPurchaseConsultingForm } from "pages/store/consulting/configure/ConsultingConfigure";
import { StoreError } from "pages/system/error/StoreError";

import "./ConsultingCostCard.less";

type ICostCardProps = {
  hours: number,
  language: string,
  onProcess: () => Promise<NewPurchaseConsultingForm>,
}

export function ConsultingCostCard(props: ICostCardProps) {
  const { language, hours, onProcess } = props;
  const [promoCode, setPromoCode] = useState("");

  const { item, setItem } = useBasket();
  const navigate = useNavigate();

  const { response, loading, error, fetch } = useConsultingCost({
    promoCode: promoCode,
    quantity: hours,
  }, [hours, promoCode]);

  const content = response &&
    (<>
      <Row justify="space-between">
        <Col>
          Subtotal
        </Col>
        <Col>
          <span>{hours} x {response?.currency.sign}{response?.pricePerHour}</span>
        </Col>
      </Row>
      <div className="consulting-cost-card__discounts">
        <DiscountInfo currency={response?.currency} discounts={response?.discountNotes || []} />
      </div>
      <div className="consulting-cost-card_promocode">
        <PromoCodeButton
          type={ProductType.CONSULTING}
          // className="cost-card__promocode"
          onApply={(promoCode) => setPromoCode(promoCode)}
        >
          Apply promo code
        </PromoCodeButton>
      </div>
      <Divider />
      {
        response.tax != 0 &&
        <div>
          <VatInfo currency={response.currency} cost={response.tax} />
          <Divider />
        </div>
      }
      <div className="consulting-cost-total">
        <div>
          <strong>Total</strong>
        </div>
        <div>
          <strong>{response?.currency.sign}{response?.total}</strong>
        </div>
      </div>
      {
        hours > 80 ?
          <>
            <Description />
            <Button
              className="cost-card__button"
              type="primary"
              size="large"
              onClick={() => window.open(env.REACT_APP_JMIX_CONTACTS, "_blank")}
            >
              Contact us
            </Button>
          </> :
          <Button
            className="cost-card__button"
            type="primary"
            size="large"
            onClick={() => {
              onProcess()
                .then(() => {
                  setItem(createBasket(hours, language, promoCode, item));
                  navigate("/store/consulting/billing");
                });
            }}
          >
            Proceed to billing info
          </Button>
      }
    </>);

  return (
    <StoreCard className="consulting-cost-card" loading={loading}>
      {
        error ?
          <StoreError
            autoHeight={true}
            reload={fetch}
            bordered={false}
          /> :
          content
      }
    </StoreCard>
  );
}

function Description() {
  return (
    <div className="cost-card__description">
      3 to 80 hours are available for purchase. Contact us if you need a different amount.
    </div>
  );
}

function createBasket(count: number, languageId: string, promoCode: string, item: BasketType): BasketType {
  const consulting = {
    promoCode: promoCode,
    languageId: languageId,
    quantity: count,
  };

  if (item != null) {
    item.consulting = consulting;
    return item;
  }

  return {
    consulting: consulting
  };
}
