import { useIntl } from "react-intl";
import { useForm, useWatch } from "antd/es/form/Form";
import { useAddMember, useCheckMember } from "services/rest/member";
import { Form, Input, Modal, notification } from "antd";
import { RoleSelect } from "components/role-select/RoleSelect";
import React, { useState } from "react";
import { RoleDescription } from "components/role-description/RoleDescription";
import { SendInviteModal } from "components/team-member/modal/SenInviteModal";
import { FormatUtils } from "services/utils/FormatUtils";
import { AxiosResponse } from "axios";
import { ValidateCode, ValidateResponse } from "services/rest/general";

export type AddUserModalProps = {
  visible: boolean,
  onAfterOk: () => void,
  onCancel: () => void,
}

export function AddUserModal(props: AddUserModalProps) {
  const { visible, onAfterOk, onCancel } = props;
  const intl = useIntl();
  const [form] = useForm();
  const [isInviteModalVisible, setInviteModalVisible] = useState(false);

  const email = useWatch("email", form);
  const role = useWatch("role", form);

  const { fetch: addMemberFetch, loading: addMemberLoading } = useAddMember();

  const {
    fetch: checkMemberFetch,
    loading: checkMemberLoading,
  } = useCheckMember(encodeURIComponent(email), [email]);

  const addUser = () => {
    addMemberFetch(form.getFieldsValue())
      .then(() => {
        Modal.success({
          centered: true,
          title: intl.formatMessage({ id: "jmix-store.account.add-user-modal.title" }),
          content: (
            <>
              <div>
                {intl.formatMessage({ id: "jmix-store.account.add-user-modal.content_1" }, {
                  email: <strong>{email}</strong>
                })}
              </div>
              <div>
                {intl.formatMessage({ id: "jmix-store.account.add-user-modal.content_2" })}
              </div>
            </>
          )
        });

        onAfterOk();
        onCancel();
      })
      .catch(() => {
        notification.error({
          message: "Add user failed.",
          placement: "bottomLeft",
        });
      });
  };

  const validateEmail = () => {
    return checkMemberFetch()
      .then((response: AxiosResponse<ValidateResponse>) => {
        const code = response.data.code;

        if (code == ValidateCode.CUSTOMER_IN_ANOTHER_COMPANY) {
          return Promise.reject("User with this email already exists in another company");
        } else if (code == ValidateCode.CUSTOMER_IN_THIS_COMPANY) {
          return Promise.reject("User with this email already exists in the company");
        } else {
          return Promise.resolve();
        }
      });
  };

  return (
    <>
      <Modal
        centered
        destroyOnClose
        title="Add User"
        visible={visible}
        confirmLoading={addMemberLoading || checkMemberLoading}
        onOk={() => {
          form.validateFields().then(addUser);
        }}
        onCancel={onCancel}
        className="team-member__add-user-modal"
      >
        <Form
          form={form}
          preserve={false}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please input email",
              },
              {
                message: "Email not valid",
                type: "email",
              },
              {
                validateTrigger: "onSubmit",
                transform: (value: string) => value.toLowerCase(),
                validator: () => validateEmail(),
              }
            ]}
          >
            <Input
              onBlur={(value) => form.setFieldsValue({ email: FormatUtils.getEmailFormat(value.target.value) })}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            required
            tooltip={<RoleDescription />}
            rules={[
              {
                required: true,
                message: "Please input role",
              }]
            }
          >
            <RoleSelect />
          </Form.Item>
        </Form>
      </Modal>
      <SendInviteModal
        visible={isInviteModalVisible}
        email={email}
        role={role}
        onAfterOk={() => {
          onAfterOk();
          onCancel();
        }}
        onCancel={() => setInviteModalVisible(false)} />
    </>
  );
}