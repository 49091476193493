import React from "react";
import { Col, Layout, Row, } from "antd";
import { useIntl } from "react-intl";

import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { ServiceCard } from "components/service-card/ServiceCard";

import { useNavigate } from "react-router-dom";

import SubscriptionLogo from "assets/products/subscription.svg";
import ConsultingLogo from "assets/products/consulting.svg";

import "./ServicesPage.less";

export function ServicesPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  // const { response, loading, error } = useLicenseList();

  //const [isSubscriptionModalVisible, setSubscriptionModalVisible] = useState(false);

  return (
    <>
      <StorePageHeader title="Store" />
      <Layout className="store_layout">
        <Row
          gutter={[32, 32]}
          justify="space-between"
          className="store__products"
        >
          <Col flex="1 1 500px">
            <ServiceCard
              logo={SubscriptionLogo}
              title={intl.formatMessage({ id: "jmix-store.services.subscription.title" })}
              description={intl.formatMessage({ id: "jmix-store.services.subscription.description" })}
              buttonName={intl.formatMessage({ id: "jmix-store.services.subscription.button-name" })}
              onClick={() => {
                // if(response?.length) {
                //   setSubscriptionModalVisible(true);
                // } else {
                navigate("/store/subscription");
                // }
              }}
            />
          </Col>
          <Col flex="1 1 500px">
            <ServiceCard
              logo={ConsultingLogo}
              title={intl.formatMessage({ id: "jmix-store.services.consulting.title" })}
              description={intl.formatMessage({ id: "jmix-store.services.consulting.description" })}
              buttonName={intl.formatMessage({ id: "jmix-store.services.consulting.button-name" })}
              onClick={() => navigate("/store/consulting")}
            />
          </Col>
        </Row>
        {/*<SubscriptionTableModal*/}
        {/*  subscriptions={response || []}*/}
        {/*  visible={isSubscriptionModalVisible}*/}
        {/*  onOk={(subscription) => console.log(subscription)}*/}
        {/*  onCancel={() => setSubscriptionModalVisible(false)}*/}
        {/*/>*/}
      </Layout>
    </>
  );
}
