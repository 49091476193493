import { SubscriptionInfo, useCancelSubscription } from "services/rest/subscription";
import { Button, Modal, notification } from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DateUtils } from "services/utils/DateUtils";
import React from "react";

export type CancelRenewalButtonProps = {
  subscription: SubscriptionInfo,
  onAfter: () => void,
}

export function CancelRenewalButton(props: CancelRenewalButtonProps) {
  const { subscription, onAfter } = props;
  const { id, nextPayment } = subscription;

  const { fetch } = useCancelSubscription(id);

  const cancelRenewalModal = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#FC1264" }} />,
      title: "Cancel Subscription",
      content: `This action cannot be reverted! Your current licenses will still be active till ${DateUtils.getFormatDate(nextPayment.date)}.`,
      centered: true,
      okButtonProps: { title: "Confirm" },
      cancelButtonProps: { icon: <CloseOutlined /> },
      onOk() {
        fetch()
          .then(() => {
            notification.success({
              message: "Subscription canceled successfully",
            });

            onAfter();
          })
          .catch(() => {
            notification.error({
              message: "Subscription cancel failed",
            });
          });
      },
    });
  };

  return (
    <Button
      type="primary"
      ghost
      onClick={cancelRenewalModal}
    >
      Cancel renewal
    </Button>
  );
}