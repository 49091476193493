import { Col, Form, Row, Select, Steps } from "antd";
import React from "react";
import { useConsultingInfo } from "services/rest/consulting";
import { HoursSelect } from "components/hours-select/HoursSelect";
import { AgreementCheckBox } from "components/agreement-checkbox/AgreementCheckBox";
import { ConsultingCostCard } from "components/consulting-cost-card/ConsultingCostCard";
import { useForm, useWatch } from "antd/es/form/Form";
import { ConsultingLanguage } from "types/consulting";

import { StoreCard } from "toolkit/card/StoreCard";

import { useIntl } from "react-intl";

import { StoreLoading } from "pages/system/loading/StoreLoading";
import { StoreError } from "pages/system/error/StoreError";

import { HelpLink } from "components/help-link/HelpLink";

import "pages/store/consulting/configure/ConsultingConfigure.less";

export type NewPurchaseConsultingForm = {
  hours: number,
  language: ConsultingLanguage,
  agreement: boolean,
}

export function ConsultingConfigure() {
  const intl = useIntl();
  const { response, loading, fetch, error } = useConsultingInfo();

  const [form] = useForm<NewPurchaseConsultingForm>();
  const hours = useWatch("hours", form);
  const language = useWatch("language", form);

  const options = response?.languages.map((language) => {
    return {
      label: language.description,
      value: language.id,
    };
  });

  if (error) {
    return (
      <StoreError
        reload={fetch}
      />
    );
  }

  if (loading) {
    return (
      <StoreLoading />
    );
  }

  return (
    <StoreCard className="consulting-configure__card">
      <Row gutter={[16, 16]}>
        <Col flex="1 1 580px">
          <Row className="consulting-configure__header" justify="space-between" align="middle">
            <div className="consulting-configure__steps">
              <Steps current={0}>
                <Steps.Step
                  key="config"
                  title="Consulting Settings"
                />
                <Steps.Step
                  key="billing"
                  title="Billing Info"
                />
              </Steps>
            </div>
            <HelpLink
              name="jmix-store.help-link.consulting"
              to="https://docs.jmix.io/jmix/account-management.html#buy-consulting"
            />
          </Row>
          <Form
            form={form}
            layout="vertical">
            <Row>
              <Col>
                <ConsultingDescription />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col flex="0 0 250px">
                <Form.Item
                  label={intl.formatMessage({ id: "jmix-store.consulting-configure.language" })}
                  name="language"
                  initialValue={response?.languages[0]?.id}
                >
                  <Select
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col flex="1 0 300px">
                <Form.Item
                  label={intl.formatMessage({ id: "jmix-store.consulting-configure.hours" })}
                  name="hours"
                  initialValue="20"
                  rules={[
                    {
                      validator: (_, value: number) => {
                        return value >= 3
                          ? Promise.resolve()
                          : Promise.reject(new Error(intl.formatMessage({ id: "jmix-store.consulting-configure.hours.rule" })));
                      }
                    }
                  ]}
                >
                  <HoursSelect />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                required
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(intl.formatMessage({ id: "jmix-store.consulting-configure.agreement.rule" }))),
                  },
                ]}
              >
                <AgreementCheckBox agreement="software" />
              </Form.Item>
            </Row>
          </Form>
        </Col>
        <Col flex="1 1 300px">
          {
            hours &&
            <ConsultingCostCard
              hours={hours}
              language={language.id}
              onProcess={form.validateFields}
            />
          }
        </Col>
      </Row>
    </StoreCard>
  );
}

function ConsultingDescription() {
  const intl = useIntl();

  return (
    <>
      <h2>{intl.formatMessage({ id: "jmix-store.consulting-configure.title" })}</h2>
      <ul>
        <li>{intl.formatMessage({ id: "jmix-store.consulting-configure.description_1" })}</li>
        <li>{intl.formatMessage({ id: "jmix-store.consulting-configure.description_2" })}</li>
        <li>{intl.formatMessage({ id: "jmix-store.consulting-configure.description_3" })}</li>
        <li>{intl.formatMessage({ id: "jmix-store.consulting-configure.description_4" })}</li>
      </ul>
    </>
  );
}