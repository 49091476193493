import { baseUrl, useAxios } from "services/rest/request";
import { DependencyList } from "react";
import { CustomerType } from "types/general";

export type Countries = {
  countries: Country[];
};

export type Country = {
  code: string;
  name: string;
  vatApplied: boolean;
};

export type State = {
  states: string[];
};

export type StateRequest = {
  countryCode: string;
};

export type CustomerInfo = {
  customer: Customer;
};

export type BillingInfo = {
  addressLine1: string;
  city: string;
  companyName: string;
  contactName: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  state: string;
  taxNumber: string;
  type: CustomerType;
};

export enum BillingInfoType {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
}

export type Customer = {
  billingInfo: BillingInfo;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  infoSource: string;
};

export type UpdateBillingInfo = {
  success: boolean;
};

export type Card = {
  brand?: string;
  id?: string;
  last4?: string;
  primary?: boolean;
  type?: string;
};

export const useCountries = () => {
  return useAxios<Country[]>(
    {
      url: `${baseUrl}/geo/countries`,
      method: "get",
    },
    [],
  );
};

export const useStates = (data: StateRequest, deps: DependencyList) => {
  if (!data.countryCode) {
    return { response: null, loading: false, error: null };
  }

  return useAxios<string[]>(
    {
      url: `${baseUrl}/geo/countries/${data.countryCode}/states`,
      method: "get",
      data: data,
    },
    deps,
  );
};

export const useBillingInfo = () => {
  return useAxios<CustomerInfo>(
    {
      url: `${baseUrl}/billing/info`,
      method: "get",
    },
    [],
  );
};

export type BillingInfoRequest = {
  type: CustomerType;
  companyName: string;
  taxNumber?: string;
  contactName?: string;
  email: string;
  country: string;
  state: string;
  firstName?: string;
  lastName?: string;
};

export const useUpdateBillingInfo = () => {
  return useAxios<BillingInfoRequest>(
    {
      url: `${baseUrl}/billing/info`,
      method: "post",
    },
    [],
    false,
  );
};

export function usePaymentCards() {
  return useAxios<Card[]>(
    {
      url: `${baseUrl}/billing/cards`,
      method: "get",
    },
    [],
  );
}

export function deletePrimaryPaymentCard() {
  return useAxios(
    {
      url: `${baseUrl}/billing/primaryCard`,
      method: "delete",
    },
    [],
    false,
  );
}

export type ChangePaymentCard = {
  created_at: string;
  embed: string;
  expires_at: string;
  id: string;
  object: string;
  resource_version: string;
  state: string;
  type: string;
  updated_at: string;
  url: string;
};
