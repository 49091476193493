import {baseUrl, useAxios} from "./request";
import {ProductCost, ProductInfo} from "types/product";
import {DependencyList} from "react";
import {CurrencyCode} from "types/general";

export type ProductCostBody = {
    country?: string,
    productId: string,
    currencyCode?: CurrencyCode,
}

export const usePayProduct = () => {
    return useAxios<ProductInfo>({
        url: `${baseUrl}/product/pay`,
        method: "post",
    }, [], false);
};

export const useProductInfo = () => {
    return useAxios<ProductInfo[]>({
        url: `${baseUrl}/product/info`,
        method: "get",
    }, []);
};

export const useProductCost = (body: ProductCostBody, deps: DependencyList) => {
    return useAxios<ProductCost>({
        url: `${baseUrl}/product/calculate`,
        method: "post",
        data: body
    }, deps);
};
