import { DateUtils } from "./DateUtils";
import { SubscriptionInfo } from "../rest/subscription";

export class SubscriptionUtils {
  static getActiveSubscriptions(subscriptions: SubscriptionInfo[]) {
    return subscriptions
      .filter((subscription) => subscription.type == "COMMERCIAL")
      .filter(
        (subscription) => {
          return new Date(DateUtils.getFormatDate(subscription.endDate)) >= new Date()
        } );
  }
}
