import React from "react";
import Keycloak from "keycloak-js";

import { client } from "./client";

export type IKeycloakContext = {
  keycloak: Keycloak.KeycloakInstance
}

export const KeycloakContext = React.createContext<IKeycloakContext>({
  keycloak: client,
});
