import React from "react";
import { Col, Divider, Form, notification, Row, Steps } from "antd";
import { CompanyBillingInfo } from "components/company-billing-info/CompanyBillingInfo";
import { AddressBillingInfo } from "components/address-billing-info/AddressBillingInfo";
import { useForm, useWatch } from "antd/es/form/Form";

import { useBillingInfo } from "services/rest/billingInfo";
import { ConsultingTotalCostCard } from "components/consulting-total-cost-card/ConsultingTotalCostCard";
import { usePayConsulting } from "services/rest/consulting";
import { useBasket } from "services/basket/hook";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreError } from "pages/system/error/StoreError";
import { useNavigate } from "react-router-dom";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { StoreCard } from "toolkit/card/StoreCard";
import { CustomerType } from "types/general";
import { PaymentMethodFormItem } from "components/payment-billing-info/PaymentMethodFormItem";
import { IndividualBillingInfo } from "components/individual-billing-info/IndividualBillingInfo";
import { useAccountInfo } from "services/account/hook";

import { ProductType, sendPurchaseEvent } from "services/analytics/analytics";

import { useError } from "services/error/hook";
import { ErrorResponse } from "services/rest/general";
import { useIntl } from "react-intl";

import { ConsultingInfo } from "services/rest/subscription";
import { AxiosResponse } from "axios";

import "pages/store/consulting/billing/ConsultingBilling.less";
import { HelpLink } from "components/help-link/HelpLink";

export function ConsultingBilling() {
  const { info } = useAccountInfo();
  const navigate = useNavigate();
  const { item } = useBasket();
  const [form] = useForm();
  const errorHook = useError();
  const intl = useIntl();

  const country = useWatch("country", form);
  const paymentType = useWatch("paymentType", form);
  const currency = useWatch("currency", form);

  if (!item.consulting) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }

  const { response, loading: billingInfoLoading } = useBillingInfo();

  const { loading: addConsultingLoading, fetch } = usePayConsulting();

  const onPayment = () => {
    form.validateFields()
      .then(() => {
        fetch({
          quantity: item.consulting?.quantity,
          selectedLanguageId: item.consulting?.languageId,
          paymentType: paymentType,
          currencyCode: currency,
          licenseInfo: {
            email: form.getFieldValue("email"),
            firstName: form.getFieldValue("firstName"),
            lastName: form.getFieldValue("lastName"),
            companyName: form.getFieldValue("companyName"),
            contactName: form.getFieldValue("contactName"),
            type: info.type,
          },
          promoCode: item.consulting?.promoCode,
          billingInfo: {
            companyName: form.getFieldValue("companyName"),
            taxNumber: form.getFieldValue("taxNumber"),
            contactName: form.getFieldValue("contactName"),
            email: form.getFieldValue("email"),
            country: form.getFieldValue("country"),
            state: form.getFieldValue("state"),
            firstName: form.getFieldValue("firstName"),
            lastName: form.getFieldValue("lastName"),
            addressLine1: form.getFieldValue("addressLine1"),
            city: form.getFieldValue("city"),
            zip: form.getFieldValue("zip"),
            type: info.type,
          },
        })
          .then((response: AxiosResponse<ConsultingInfo>) => {
            sendPurchaseEvent(ProductType.CONSULTING, paymentType);

            navigate("/store/consulting/success", {
              state: {
                ...response.data,
              }
            });
          })
          .catch((err: ErrorResponse) => {
            notification.error({
              message: intl.formatMessage({ id: "jmix-store.payment-error.title" }),
              description: errorHook.getDescription(err),
              placement: "bottomLeft",
              duration: 15,
            });
          });
      });
  };

  if (addConsultingLoading || billingInfoLoading) {
    return (
      <StoreLoading />
    );
  }

  return (
    <StoreCard className="consulting-billing__card">
      <Row gutter={[16, 16]} justify="space-between">
        <Col flex="0 1 700px">
          <Row className="consulting-billing-info__header" justify="space-between" align="middle">
            <div className="consulting-billing-info__steps">
              <Steps current={1}>
                <Steps.Step
                  key="config"
                  title="Consulting Settings"
                />
                <Steps.Step
                  key="billing"
                  title="Billing Info"
                />
              </Steps>
            </div>
            <HelpLink name="jmix-store.help-link.consulting"
                      to="https://docs.jmix.io/jmix/account-management.html#buy-consulting" />
          </Row>
          <Form
            form={form}
            initialValues={{ ...response?.customer.billingInfo }}
            layout="vertical"
          >
            <Row>
              <Col flex="auto">
                {
                  info.type == CustomerType.COMPANY
                    ? <CompanyBillingInfo />
                    : <IndividualBillingInfo />
                }
              </Col>
            </Row>
            <Divider className="billing-info__divider" />
            <Row>
              <Col flex="auto">
                <AddressBillingInfo />
              </Col>
            </Row>
            <Divider className="billing-info__divider" />
            <Row>
              <Col flex="auto">
                <Row>
                  <Col>
                    <div className="subscription-billing-info__title">
                      Payment
                    </div>
                  </Col>
                </Row>
                <PaymentMethodFormItem allowedOffline={true} />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col flex="0 1 423px">
          <ConsultingTotalCostCard
            country={country}
            currency={currency}
            onClick={onPayment}
          />
        </Col>
      </Row>
    </StoreCard>
  );
}