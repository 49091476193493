import React from "react";
import { Col, Form, Input, Row } from "antd";
import { useIntl } from "react-intl";

import "./CompanyBillingInfo.less";

export function CompanyBillingInfo() {
  const intl = useIntl();

  return (
    <>
      <div style={{ flex: 1 }} className="company-billing-info__title">
        {intl.formatMessage({ id: "jmix-store.company-billing-info.title" })}
      </div>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            label={intl.formatMessage({ id: "jmix-store.company-billing-info.company-name" })}
            name="companyName"
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.company-billing-info.company-name.rule-message" }),
                required: true,
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.company-billing-info.company-name.placeholder" })} />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            label={intl.formatMessage({ id: "jmix-store.company-billing-info.tax-number" })}
            name="taxNumber"
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.company-billing-info.tax-number.placeholder" })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            name="contactName"
            label={intl.formatMessage({ id: "jmix-store.company-billing-info.contact-name" })}
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.company-billing-info.contact-name.rule-message" }),
                required: true,
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.company-billing-info.contact-name.placeholder" })} />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: "jmix-store.company-billing-info.email" })}
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.company-billing-info.email.rule-message_1" }),
                required: true,
              },
              {
                message: intl.formatMessage({ id: "jmix-store.company-billing-info.email.rule-message_2" }),
                type: "email",
              }
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: "jmix-store.company-billing-info.email.placeholder" })} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
