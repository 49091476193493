import React, { useMemo, useState } from "react";
import { Button, Col, Divider, Form, notification, Row, Space, Spin, } from "antd";

import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { CompanyBillingInfo } from "components/company-billing-info/CompanyBillingInfo";
import { AddressBillingInfo } from "components/address-billing-info/AddressBillingInfo";

import { useForm } from "antd/es/form/Form";
import { CustomerInfo, useBillingInfo, useUpdateBillingInfo } from "services/rest/billingInfo";
import { StoreError } from "pages/system/error/StoreError";
import { PaymentCard } from "components/payment-card/PaymentCard";
import { AxiosResponse } from "axios";
import { useAccountInfo } from "services/account/hook";
import { CustomerType } from "types/general";
import { IndividualBillingInfo } from "components/individual-billing-info/IndividualBillingInfo";

import "./BillingInfo.less";
import { useError } from "services/error/hook";
import { ErrorResponse } from "services/rest/general";

export type BillingInfoForm = {
  companyName?: string,
  taxNumber?: string,
  contactName?: string,
  email?: string,
  country?: string,
  state?: string,
}

export function BillingInfo() {
  const { loading, error, fetch } = useBillingInfo();
  const { info } = useAccountInfo();
  const [form] = useForm<BillingInfoForm>();
  const errorHook = useError();
  const [isFormChanged, setFormChanged] = useState(false);

  const { fetch: updateBillingInfo, loading: updateLoading } = useUpdateBillingInfo();

  if (error) {
    return (
      <StoreError />
    );
  }

  useMemo(() => {
    form.validateFields()
      .then(() => {
        fetch().then((resp: AxiosResponse<CustomerInfo>) => {
          if (resp) {
            form.setFieldsValue({ ...resp.data?.customer.billingInfo });
          }
        });
      });
  }, []);

  const onUpdateBillingInfo = () => {
    form.validateFields()
      .then(() => {
        updateBillingInfo({
          type: info.type,
          companyName: form.getFieldValue("companyName"),
          taxNumber: form.getFieldValue("taxNumber"),
          contactName: form.getFieldValue("contactName"),
          email: form.getFieldValue("email"),
          country: form.getFieldValue("country"),
          state: form.getFieldValue("state"),
          firstName: form.getFieldValue("firstName"),
          lastName: form.getFieldValue("lastName"),
          addressLine1: form.getFieldValue("addressLine1"),
          city: form.getFieldValue("city"),
          zip: form.getFieldValue("zip"),
        })
          .then(() => notification.success({
            message: "Billing information updated",
            placement: "bottomLeft",
          }))
          .catch((error: ErrorResponse) => notification.error({
            message: "Failed to update billing information",
            description: errorHook.getDescription(error),
            placement: "bottomLeft",
          }));
      });
  };


  return (
    <>
      <StorePageHeader title="Billing Information" />
      <StoreLayout>
        <StoreCard
          className="billing-info"
        >
          <Spin spinning={loading || updateLoading}>
            <Form
              form={form}
              layout="vertical"
              className="billing-info__form"
              onFieldsChange={() => setFormChanged(true)}
            >
              <Row>
                <Col flex="auto">
                  <div className="billing-info__payment-card ">
                    <div className="billing-info__title">Payment card</div>
                    <PaymentCard />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <div className="billing-info__title">
                    Billing Information
                  </div>
                  <div className="billing-info__description">
                    {
                      info.type == CustomerType.COMPANY
                        ? <CompanyBillingInfo />
                        : <IndividualBillingInfo />
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <Divider className="billing-info__divider" />
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <AddressBillingInfo />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col flex="1 1 300px">
                  <Form.Item>
                    <Space>
                      <Button
                        type="primary"
                        disabled={!isFormChanged}
                        onClick={onUpdateBillingInfo}
                      >
                        Save changes
                      </Button>
                      <Button
                        disabled={!isFormChanged}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </StoreCard>
      </StoreLayout>
    </>
  );
}
