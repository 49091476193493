import Keycloak from "keycloak-js";
import { Avatar, Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useKeycloak } from "services/keycloak/hook";
import { useIntl } from "react-intl";
import { env } from "config";

import "./UserAvatar.less";

export type UserAvatarProps = {
  className: string,
}

export function UserAvatar(props: UserAvatarProps) {
  const { className } = props;
  const [name, setName] = useState("U");
  const keycloak = useKeycloak();
  const intl = useIntl();

  useEffect(() => {
    keycloak.loadUserProfile().then(() => {
      setName(getAvatarName(keycloak?.profile));
    });
  });

  const externalMenuItems: ItemType[] = [
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: (
        <a href={`${env.REACT_APP_JMIX_ACCOUNT_URL}/settings`}>
          {intl.formatMessage({ id: "jmix-store.header.setting" })}
        </a>
      )
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: intl.formatMessage({ id: "jmix-store.header.logout" }),
      onClick: () => keycloak.logout({ redirectUri: env.REACT_APP_JMIX_ACCOUNT_URL })
    }
  ];

  return (
    <Space className={`user-avatar ${className}`}>
      <Dropdown
        overlay={<Menu items={externalMenuItems} />}
        trigger={["click"]}
      >
        <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>{name}</Avatar>
      </Dropdown>
    </Space>
  );
}

function getAvatarName(profile: Keycloak.KeycloakProfile | undefined): string {
  if (profile) {
    const { firstName, lastName } = profile;

    let name = "";
    if (firstName) {
      name += firstName.charAt(0).toUpperCase();
    }

    if (lastName) {
      name += lastName.charAt(0).toUpperCase();
    }
    return name;
  }

  return "U";
}
