import { Currency } from "types/general";
import { DiscountNote } from "types/general";
import React from "react";

import "./DiscountInfo.less";

export type DiscountInfoProps = {
  currency: Currency,
  discounts: DiscountNote[],
}

export function DiscountInfo(props: DiscountInfoProps) {
  const { currency, discounts } = props;

  return (
    <>
      {
        discounts.map((discount, _index) => {
          const amount = `-${currency.sign}${discount.discount}`;

          return (
            <div key={_index} className="discount-info">
              <div className="discount-info__note">
                {discount.note}
              </div>
              <div className="discount-info__value">
                {amount}
              </div>
            </div>
          );
        })
      }
    </>
  );
}