import React from "react";
import { Card, CardProps } from "antd";

import "./StoreCard.less";

export type IStoreCardProps = CardProps

export function StoreCard(props: IStoreCardProps) {
  return (
    <div className="store-card">
      <Card
        {...props}
      >
        {props.children}
      </Card>
    </div>
  );
}
