import Keycloak from "keycloak-js";
import { env } from "config";

export const client = Keycloak({
  url: env.REACT_APP_KEYCLOAK_URL,
  realm: env.REACT_APP_KEYCLOAK_REALM || "",
  clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
});

client.onTokenExpired = () => {
  console.log("Token expired!");

  client.updateToken(30)
    .then((refreshed) => {
      if (refreshed) {
        console.log("Token was successfully refreshed");
      } else {
        console.log("Token is still valid");
      }
    })
    .catch(() => client.login({
      redirectUri: window.location.origin,
    }));
};
