import {StorePageHeader} from "toolkit/pageHeader/StorePageHeader";
import {StoreLayout} from "components/store-layout/StoreLayout";
import React from "react";
import {ProductRoutes} from "./ProductRoutes";
import {useIntl} from "react-intl";

export function ProductPage() {
    const intl = useIntl();

    return (
        <>
            <StorePageHeader
                title={intl.formatMessage({id: "jmix-store.product-page.title"})}
            />
            <StoreLayout>
                <ProductRoutes/>
            </StoreLayout>
        </>
    );
}