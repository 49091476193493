import { Currency } from "types/general";

export class CurrencyUtils {

  static getFormatCurrency(currency: Currency, amount: number) {
    const sign = currency ? currency.sign : "$";
    const price = amount ? amount.toFixed(2) : 0;

    return sign + price;
  }

}