import React, { useEffect } from "react";
import { Col, Divider, Form, notification, Row, Steps } from "antd";
import { CompanyBillingInfo } from "components/company-billing-info/CompanyBillingInfo";
import { AddressBillingInfo } from "components/address-billing-info/AddressBillingInfo";
import { useForm, useWatch } from "antd/es/form/Form";
import { PaymentMethodFormItem } from "components/payment-billing-info/PaymentMethodFormItem";
import { SubscriptionTotalCostCard } from "components/subscription-total-card/SubscriptionTotalCostCard";

import { useBasket } from "services/basket/hook";
import { StoreError } from "pages/system/error/StoreError";
import { useBillingInfo } from "services/rest/billingInfo";
import { SubscriptionInfo, usePaySubscription } from "services/rest/subscription";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { StoreCard } from "toolkit/card/StoreCard";
import { useNavigate } from "react-router-dom";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { useAccountInfo } from "services/account/hook";

import { LicenseType } from "types/subscription";

import { IndividualBillingInfo } from "components/individual-billing-info/IndividualBillingInfo";
import { AxiosResponse } from "axios";
import { CustomerUtils } from "services/utils/CustomerUtils";

import { ProductType, sendPurchaseEvent } from "services/analytics/analytics";
import { ErrorResponse } from "services/rest/general";

import { useError } from "services/error/hook";

import { useIntl } from "react-intl";

import "./SubscriptionBillingInfo.less";
import { HelpLink } from "components/help-link/HelpLink";
import { PaymentType } from "types/general";

export function SubscriptionBillingInfo() {
  const [form] = useForm();
  const { item } = useBasket();
  const navigate = useNavigate();
  const { info, loading: accountInfoLoading, reload } = useAccountInfo();
  const errorHook = useError();
  const intl = useIntl();

  const country = useWatch("country", form);
  const currency = useWatch("currency", form);
  const paymentType = useWatch("paymentType", form);

  const { response, loading: billingInfoLoading, error } = useBillingInfo();

  useEffect(() => {
    form.setFieldsValue({ ...response?.customer.billingInfo });
  }, [response]);

  if (!item.subscription || !info || error) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }

  const customerType = CustomerUtils.getCustomerType(item.subscription.licenseType);

  const { loading: addSubscriptionLoading, fetch } = usePaySubscription();

  if (addSubscriptionLoading || accountInfoLoading || billingInfoLoading) {
    return (
      <StoreLoading />
    );
  }


  const onPurchase = () => {
    form.validateFields()
      .then(() => {
        const body = {
          planId: item.subscription?.planId,
          quantity: item.subscription?.quantity,
          paymentType: paymentType == null ? PaymentType.CARD : paymentType,
          currencyCode: currency,
          licenseInfo: {
            email: form.getFieldValue("email"),
            firstName: form.getFieldValue("firstName"),
            lastName: form.getFieldValue("lastName"),
            companyName: form.getFieldValue("companyName"),
            contactName: form.getFieldValue("contactName"),
            type: customerType,
          },
          licenseKeys: item.subscription?.licenseKeys.map((licenseKeyRow) => {
            return licenseKeyRow.licenseKey;
          }),
          promoCode: item.subscription?.promoCode,
          billingInfo: {
            companyName: form.getFieldValue("companyName"),
            taxNumber: form.getFieldValue("taxNumber"),
            contactName: form.getFieldValue("contactName"),
            email: form.getFieldValue("email"),
            country: form.getFieldValue("country"),
            state: form.getFieldValue("state"),
            firstName: form.getFieldValue("firstName"),
            lastName: form.getFieldValue("lastName"),
            addressLine1: form.getFieldValue("addressLine1"),
            city: form.getFieldValue("city"),
            zip: form.getFieldValue("zip"),
            type: customerType,
          }
        };

        fetch(body)
          .then((response: AxiosResponse<SubscriptionInfo>) => {
            reload()
              .finally(() => {
                sendPurchaseEvent(ProductType.SUBSCRIPTION, paymentType == null ? PaymentType.CARD : paymentType);

                navigate(`/store/subscription/new/success?subscriptionId=${response.data.id}`);
              });
          })
          .catch((err: ErrorResponse) => {
            notification.error({
              message: intl.formatMessage({ id: "jmix-store.payment-error.title" }),
              description: errorHook.getDescription(err),
              placement: "bottomLeft",
              duration: 15,
            });
          });
      });
  };

  return (
    <StoreCard className="new-subscription__card">
      <Row gutter={[16, 16]} justify="space-between">
        <Col flex="0 1 700px">
          <Row className="subscription-billing-info__header" justify="space-between" align="middle">
            <div className="subscription-billing-info__steps">
              <Steps current={1}>
                <Steps.Step
                  key="config"
                  title="License Settings"
                />
                <Steps.Step
                  key="billing-info"
                  title="Billing Info"
                />
              </Steps>
            </div>
            <HelpLink name="jmix-store.help-link.license"
                      to="https://docs.jmix.io/jmix/account-management.html#subscription-purchase" />
          </Row>
          <Form
            form={form}
            layout="vertical"
          >
            <Row>
              <Col flex="auto">
                {
                  item.subscription.licenseType == LicenseType.COMPANY
                    ? <CompanyBillingInfo />
                    : <IndividualBillingInfo />
                }
              </Col>
            </Row>
            <Divider className="billing-info__divider" />
            <Row>
              <Col flex="auto">
                <AddressBillingInfo />
              </Col>
            </Row>
            <Divider className="billing-info__divider" />
            <Row>
              <Col flex="auto">
                <Row>
                  <Col>
                    <div className="subscription-billing-info__title">
                      Payment
                    </div>
                  </Col>
                </Row>
                <PaymentMethodFormItem
                    allowedOffline={item.subscription.licenseType === LicenseType.COMPANY}
                    hideDescription
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col flex="0 1 423px">
          <SubscriptionTotalCostCard
            country={country}
            quantity={item.subscription.quantity}
            isCompany={item.subscription.licenseType === LicenseType.COMPANY}
            planId={item.subscription.planId}
            promoCode={item.subscription.promoCode}
            currencyCode={currency}
            onClick={onPurchase}
          />
        </Col>
      </Row>
    </StoreCard>
  );
}