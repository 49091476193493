import { LicenseType } from "types/subscription";
import { CustomerType } from "types/general";

export class CustomerUtils {

  static getCustomerType(licenseType: LicenseType): CustomerType {
    switch (licenseType) {
      case LicenseType.COMPANY:
        return CustomerType.COMPANY;
      case LicenseType.INDIVIDUAL:
        return CustomerType.INDIVIDUAL;
      default: {
        throw new Error("Incorrect license type");
      }
    }
  }

}