import React from "react";
import { Checkbox, CheckboxProps } from "antd";
import { useIntl } from "react-intl";
import { env } from "config";

import "./AgreementCheckBox.less";

export type AgreementCheckBoxProps = CheckboxProps & IAgreementCheckBoxProps;

export type IAgreementCheckBoxProps = {
  agreement: "license" | "software",
}

export function AgreementCheckBox(props: AgreementCheckBoxProps) {
  const { agreement } = props;
  const intl = useIntl();

  const link =
    agreement == "license"
      ? (
        <a href={env.REACT_APP_JMIX_LICENSE_AGREEMENT_URL} target="_blank" rel="noopener noreferrer">
          {intl.formatMessage({ id: "jmix-store.license-agreement.link" })}
        </a>
      )
      : (
        <a href={env.REACT_APP_JMIX_SERVICE_AGREEMENT_URL} target="_blank" rel="noreferrer">
          {intl.formatMessage({ id: "jmix-store.service-agreement.link" })}
        </a>
      );

  return (
    <>
      <Checkbox
        {...props}
        className="agreement-checkbox__checkbox"
      />
      <span>
        {intl.formatMessage({ id: "jmix-store.agreement" }, { link: link })}
      </span>
    </>
  );
}
