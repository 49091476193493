import React from "react";

import StoreLogo from "assets/logo/logo.svg";

import "./SpalshScreen.less";

export function SplashScreen() {
  return (
    <div className="splash-screen">
      <img
        src={StoreLogo}
        className="splash-screen__logo"
        alt="Store Logo"
      />
    </div>
  );
}
