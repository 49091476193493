import { Month } from "services/utils/DateUtils";

export enum PaymentType {
  CARD = "CARD",
  OFFLINE = "OFFLINE",
}

export type Currency = {
  code: string,
  sign: string,
}

export enum CurrencyCode {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  RUB = "RUB",
  KZT = "KZT",
}

export type LocalDate = {
  year: number,
  month: Month,
  dayOfMonth: number,
}

export type FileResponse = {
  id: string,
  name: string,
  size: number,
}

export type Pageable<T> = {
  content: T[],
  total: number,
}

export type Pagination = {
  page: number,
  size: number,
}

export type DiscountNote = {
  note: string,
  discount: number,
}

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  WORKER = "WORKER",
}

export enum CustomerType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export enum ProductType {
  LICENSE = "LICENSE",
  CONSULTING = "CONSULTING",
}

export enum TariffType {
  ENTERPRISE = "ENTERPRISE",
  RAD = "RAD",
}

export enum PeriodUnit {
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}