import React from "react";
import { CustomerType, Role } from "types/general";
import { Eligible, ProfileInfo } from "services/rest/account";

export type IAccountInfo = {
  type?: CustomerType,
  companyName?: string,
  name?: string,
  role?: Role,
  eligible?: Eligible,
  isVerifyEmail?: boolean,
  email?: string,
}

export type IAccountContext = {
  info: IAccountInfo,

  loading: boolean,
  reload: () => Promise<ProfileInfo>,
}

// @ts-ignore
export const AccountContext = React.createContext<IAccountContext>(null);