import { InputNumber, Radio, RadioChangeEvent, Space } from "antd";
import React, { useState } from "react";

export type HoursSelectProps = {
  value?: number;
  onChange?: (value: number) => void;
}

export function HoursSelect(props: HoursSelectProps) {
  const [hourCountRadio, setHourCountRadio] = useState(props.value ? props.value : "20");
  const [hourCount, setHourCount] = useState(20);
  const [hourCountInputVisible, setHourCountInputVisible] = useState(false);

  const triggerChange = (changedValue: number) => {
    props.onChange?.(changedValue ? changedValue : hourCount);
  };

  const onChangeInputHourCount = (e: number) => {
    setHourCount(e);
    triggerChange(e);
  };

  const onChangeRadioHourCount = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setHourCountRadio(e.target.value);

    if (value === "other") {
      setHourCountInputVisible(true);
    } else {
      const hours = Number(value);

      setHourCount(hours);
      setHourCountInputVisible(false);
      triggerChange(hours);
    }
  };

  return (
    <Space>
      <Radio.Group
        value={hourCountRadio}
        onChange={(e) => onChangeRadioHourCount(e)}
      >
        <Radio.Button value="20">20</Radio.Button>
        <Radio.Button value="50">50</Radio.Button>
        <Radio.Button value="80">80</Radio.Button>
        <Radio.Button value="other">Other</Radio.Button>
      </Radio.Group>
      {
        hourCountInputVisible &&
        <InputNumber
          hidden={!hourCountInputVisible}
          value={hourCount}
          onChange={(e) => onChangeInputHourCount(e || 0)}
        />
      }
    </Space>
  );
}
