import {useIntl} from "react-intl";
import {StoreCard} from "toolkit/card/StoreCard";
import {Col, Result, Row, Space} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {NavigateButton} from "components/navigate-button/NavigateButton";
import React from "react";

import "pages/store/product/success/NewProductSuccessPage.less";

export function NewProductSuccessPage() {
    const intl = useIntl();

    return (
        <StoreCard className="new-product-success-page">
            <Row
                className="new-product-success__content"
                justify="space-around"
                align="middle"
            >
                <Col>
                    <Space direction="vertical" align="center">
                        <Result
                            className="new-product-success__description"
                            icon={<CheckOutlined className="new-product-success_check-icon"/>}
                            title={intl.formatMessage({id: "jmix-store.new-product-success-page.title"})}
                            subTitle={intl.formatMessage({id: "jmix-store.new-product-success-page.description"})}
                            extra={
                                <NavigateButton
                                    name={intl.formatMessage({id: "jmix-store.forward-jmix-account-button"})}
                                    path="/account"
                                    type="primary"
                                    ghost={false}
                                />
                            }
                        />
                    </Space>
                </Col>
            </Row>
        </StoreCard>
    );
}