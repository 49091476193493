import React from "react";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import { Col, Result, Row, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import "./RequestSubscriptionSuccessPage.less";

export function RequestSubscriptionSuccessPage() {
  return (
    <StoreLayout>
      <StoreCard className="academic-result-page">
        <Row
          className="academic-result-page__content"
          justify="space-around"
          align="middle"
        >
          <Col>
            <Space direction="vertical" align="center">
              <Result
                icon={<CheckOutlined className="academic-result-page_check-icon" />}
                title="Thank you for reaching out!"
                subTitle="We will review your application and contact you shortly by email."
              />
            </Space>
          </Col>
        </Row>
      </StoreCard>
    </StoreLayout>
  );
}