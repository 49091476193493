import { Alert, Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { SendEmailInfo, useSendVerifyEmail } from "services/rest/account";
import { IAccountInfo } from "services/account/AccountContext";
import { DateUtils } from "services/utils/DateUtils";

export type EmailVerifyBannerProps = {
  info: IAccountInfo,
}

export function EmailVerifyBanner(props: EmailVerifyBannerProps) {
  const { info } = props;
  const intl = useIntl();

  const [sendVerifyEmailModalVisible, setSendVerifyEmailModalVisible] = useState(false);

  if (info.isVerifyEmail) {
    return null;
  }

  const link = intl.formatMessage({ id: "jmix-store.email-verify-banner.link" });

  const message = intl.formatMessage({
    id: "jmix-store.email-verify-banner.message"
  }, {
    link: <a onClick={() => setSendVerifyEmailModalVisible(true)}>{link}</a>
  });

  return (
    <>
      <Alert
        type="warning"
        message={message}
        banner
      />
      <SendVerifyEmailModal
        visible={sendVerifyEmailModalVisible}
        email={info.email}
        onClose={() => setSendVerifyEmailModalVisible(false)}
      />
    </>
  );
}

export type SendVerifyEmailModalProps = {
  visible: boolean,
  email?: string,

  onClose: () => void;
}

export function SendVerifyEmailModal(props: SendVerifyEmailModalProps) {
  const { visible, email, onClose } = props;

  const intl = useIntl();
  const { loading, fetch } = useSendVerifyEmail();

  return (
    <Modal
      open={visible}
      centered
      title={intl.formatMessage({ id: "jmix-store.email-verify-modal.title" })}
      confirmLoading={loading}
      onCancel={onClose}
      onOk={() => {
        fetch()
          .then((resp: AxiosResponse<SendEmailInfo>) => {
            onClose();

            if (resp.data.success) {
              notification.success({
                placement: "bottomLeft",
                message: intl.formatMessage({ id: "jmix-store.email-verify-modal.success" })
              });
            } else {
              notification.error({
                placement: "bottomLeft",
                message: intl.formatMessage({
                  id: "jmix-store.email-verify-modal.timeout"
                }, {
                  repeatIn: DateUtils.getFormatTime(resp.data.repeatIn)
                })
              });
            }
          })
          .catch(() => {
            onClose();

            notification.error({
              placement: "bottomLeft",
              message: intl.formatMessage({ id: "jmix-store.email-verify-modal.error" })
            });
          });
      }}
    >
      {intl.formatMessage({ id: "jmix-store.email-verify-modal.message" }, {
        email: <strong>{email}</strong>
      })}
    </Modal>
  );
}