import React from "react";

import "./StoreLayout.less";

export type IStoreLayoutProps = {
  children?: React.ReactNode;
}

export function StoreLayout(props: IStoreLayoutProps) {
  return (
    <div className="store-layout">
      {props.children}
    </div>
  );
}
