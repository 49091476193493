import React, { useEffect } from "react";

import { MainLayout } from "components/main-layout/MainLayout";
import { StoreGeneralRoutes } from "components/routes/StoreGeneralRoutes";
import { KeycloakProvider } from "services/keycloak/KeycloakProvider";

import { Maintenance } from "components/maintenance/Maintenance";
import { useHealthCheck } from "services/rest/health";
import { SplashScreen } from "components/splash/SplashScreen";
import { StoreNotAvailable } from "pages/system/not-available/StoreNotAvailable";
import { AccountProvider } from "services/account/AccountProvider";
import { env } from "config";

import { useLocation } from "react-router-dom";

import "./App.less";
import { sendPageViewEvent } from "services/analytics/analytics";

function App() {
  const { loading, error } = useHealthCheck();
  const location = useLocation();

  useEffect(() => {
    sendPageViewEvent();
  }, [location]);

  if (env.REACT_APP_JMIX_STORE_MAINTENANCE_MODE === "on") {
    const description = env.REACT_APP_JMIX_STORE_MAINTENANCE_MODE_DESCRIPTION;
    const title = env.REACT_APP_JMIX_STORE_MAINTENANCE_MODE_TITLE;

    return (
      <Maintenance
        title={title || "Maintenance mode"}
        description={description || "Sorry, Jmix Store to maintenance mode."}
      />
    );
  }

  if (loading) {
    return (
      <SplashScreen />
    );
  }

  if (error) {
    return (
      <StoreNotAvailable />
    );
  }


  return (
    <KeycloakProvider>
      <AccountProvider>
        <MainLayout>
          <StoreGeneralRoutes />
        </MainLayout>
      </AccountProvider>
    </KeycloakProvider>
  );
}

export default App;
