import { PaymentType } from "types/general";

import ReactGA from "react-ga4";
import ym from "react-yandex-metrika";

export enum ProductType {
  CONSULTING = "CONSULTING",
  SUBSCRIPTION = "SUBSCRIPTION"
}

export enum GAEventType {
  PAGE_VIEW = "page_view",
  PURCHASE = "purchase",
  PURCHASE_OFFLINE = "purchase-offline"
}

export enum YMEventType {
  ORDER_LICENSE = "order-license",
  ORDER_LICENSE_OFFLINE = "order-license-offline",
  ORDER_CONSULTANCY = "order-consultancy",
  ORDER_CONSULTANCY_OFFLINE = "order-consultancy-offline"
}

const REACH_GOAL = "reachGoal";
const HIT = "hit";

export function sendPageViewEvent() {
  ym(HIT, window.location.pathname);
  ReactGA.event(GAEventType.PAGE_VIEW);
}

export function sendPurchaseEvent(productType: ProductType, paymentType: PaymentType) {
  if (paymentType == PaymentType.CARD) {
    ReactGA.event(GAEventType.PURCHASE);

    if (productType == ProductType.SUBSCRIPTION) {
      ym(REACH_GOAL, YMEventType.ORDER_LICENSE);
    } else if (productType == ProductType.CONSULTING) {
      ym(REACH_GOAL, YMEventType.ORDER_CONSULTANCY);
    }
  } else if (paymentType == PaymentType.OFFLINE) {
    ReactGA.event(GAEventType.PURCHASE_OFFLINE);

    if (productType == ProductType.SUBSCRIPTION) {
      ym(REACH_GOAL, YMEventType.ORDER_LICENSE_OFFLINE);
    } else if (productType == ProductType.CONSULTING) {
      ym(REACH_GOAL, YMEventType.ORDER_CONSULTANCY_OFFLINE);
    }
  }
}