import { useIntl } from "react-intl";
import { Col, Form, Input, Row } from "antd";
import React from "react";

import "./IndividualBillingInfo.less";

export function IndividualBillingInfo() {
  const intl = useIntl();

  return (
    <>
      <div className="individual-billing-info__title">
        {intl.formatMessage({ id: "jmix-store.individual-billing-info.title" })}
      </div>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            label={intl.formatMessage({ id: "jmix-store.individual-billing-info.first-name" })}
            name="firstName"
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.individual-billing-info.first-name.rule-message" }),
                required: true,
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.individual-billing-info.first-name.placeholder" })} />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: "jmix-store.individual-billing-info.last-name" })}
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.individual-billing-info.last-name.rule-message" }),
                required: true,
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.individual-billing-info.last-name.placeholder" })} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: "jmix-store.individual-billing-info.email" })}
            required
            rules={[
              {
                message: intl.formatMessage({ id: "jmix-store.individual-billing-info.email.rule-message_1" }),
                required: true,
              },
              {
                message: intl.formatMessage({ id: "jmix-store.individual-billing-info.email.rule-message_2" }),
                type: "email",
              }
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: "jmix-store.individual-billing-info.email.placeholder" })} />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            label={intl.formatMessage({ id: "jmix-store.individual-billing-info.tax-number" })}
            name="taxNumber"
          >
            <Input
              placeholder={intl.formatMessage({ id: "jmix-store.individual-billing-info.tax-number.placeholder" })}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}