import { LocalDate } from "types/general";
import { baseUrl, useAxios } from "services/rest/request";

export type IncidentInfo = {
  expireDate: LocalDate,
  eventList: EventItem[],
  unitsLeft: number,
  unitsUsed: number,
  unitsTotal: number,
}

export type EventItem = {
  date: LocalDate,
  isWriteOff: boolean,
  units: number,
  fileId: string,
  fileType: string,
}

export const useIncident = () => {
  return useAxios<IncidentInfo>({
    url: `${baseUrl}/profile/balance/incident`,
    method: "get"
  }, []);
};