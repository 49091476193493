import React from "react";
import { Button, Col, List, notification, Row, Space, Statistic } from "antd";
import { useNavigate } from "react-router-dom";

import { AutoRenewal } from "components/auto-renewal/AutoRenewal";
import { SubscriptionInfo } from "services/rest/subscription";
import { CurrencyUtils } from "services/utils/CurrencyUtils";
import { DateUtils } from "services/utils/DateUtils";

import { CancelRenewalButton } from "components/cancel-renewal-button/CancelRenewalButton";
import { useAccountInfo } from "services/account/hook";
import { CustomerType, Role } from "types/general";
import { useIntl } from "react-intl";

import "./SubscriptionItem.less";
import { QuestionCircleFilled } from "@ant-design/icons";

type ISubscriptionCardProps = {
  subscription: SubscriptionInfo;
  onAfter: () => void;
};

export function SubscriptionItem(props: ISubscriptionCardProps) {
  const { subscription, onAfter } = props;
  const intl = useIntl();
  const { name, userCount, startDate, nextPayment, autoRenewal } =
    props.subscription;
  const title = name + (userCount > 0 ? ` (Users: ${userCount})` : "");

  const startDateFormat = DateUtils.getFormatDate(startDate);
  const nextPaymentDateFormat = DateUtils.getFormatDate(nextPayment.date);

  const nextPaymentFormat = CurrencyUtils.getFormatCurrency(
    nextPayment.currency,
    nextPayment.price,
  );

  return (
    <List.Item className="subscription-card">
      <List.Item.Meta
        description={
          <div>
            <Row>
              <Col>
                <div className="subscription-item__header">{title}</div>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="space-between">
              <Col flex="0 0 200px">
                <Statistic
                  title={intl.formatMessage({
                    id: "jmix-store.subscription-item.start-label",
                  })}
                  value={startDateFormat}
                />
              </Col>
              <Col flex="0 0 200px">
                <Statistic
                  title={
                    autoRenewal
                      ? intl.formatMessage({
                          id: "jmix-store.subscription-item.next-payment-label",
                        })
                      : intl.formatMessage({
                          id: "jmix-store.subscription-item.end-label",
                        })
                  }
                  value={nextPaymentDateFormat}
                />
              </Col>
              {!autoRenewal || (
                <Col flex="0 0 200px">
                  <Statistic
                    title={intl.formatMessage({
                      id: "jmix-store.subscription-item.payment-amount-label",
                    })}
                    value={nextPaymentFormat}
                  />
                </Col>
              )}
              <Col flex="1 1 100px">
                <AutoRenewal autoRenewal={autoRenewal} />
              </Col>
              <Col flex="0 -1 200px">
                <ActionsPanel subscription={subscription} onAfter={onAfter} />
              </Col>
            </Row>
          </div>
        }
      />
    </List.Item>
  );
}

type ActionsPanelProps = {
  subscription: SubscriptionInfo;
  onAfter: () => void;
};

function ActionsPanel(props: ActionsPanelProps) {
  const { subscription, onAfter } = props;
  const intl = useIntl();
  const { info } = useAccountInfo();
  const { id, autoRenewal, editAllowed, endDate } = subscription;
  const navigate = useNavigate();

  const isAdmin = info.type == CustomerType.COMPANY && info.role == Role.ADMIN;
  const isIndividual = info.type == CustomerType.INDIVIDUAL;
  const isTrial = subscription.trialDownloadUrl != null;

  return (
    <Space direction="horizontal" align="end">
      {isTrial && (
        <div>
          <a
            className="help-link"
            href={
              "https://docs.jmix.io/jmix/account-management.html#enterprise-trial"
            }
            target="_blank"
            rel="noreferrer"
          >
            <QuestionCircleFilled />
          </a>
          <Button
            type="primary"
            ghost
            onClick={() => {
              if (new Date(DateUtils.getFormatDate(endDate)) >= new Date()) {
                window.location.href = subscription.trialDownloadUrl;
              } else {
                const link = (
                  <a
                    href="https://www.jmix.io/contacts/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: "jmix-store.contact-us" })}
                  </a>
                );
                notification.error({
                  message: intl.formatMessage(
                    { id: "jmix-store.enterprise-trial.error" },
                    { link: link },
                  ),
                  placement: "bottomLeft",
                });
              }
            }}
          >
            {intl.formatMessage({
              id: "jmix-store.subscription-item.download-trial-button",
            })}
          </Button>
        </div>
      )}
      {!isTrial && (
        <Button
          type="primary"
          ghost
          onClick={() => {
            navigate(`/account/subscription/${id}`);
          }}
        >
          {intl.formatMessage({
            id: "jmix-store.subscription-item.manage-button",
          })}
        </Button>
      )}
      {(isAdmin || isIndividual) && autoRenewal && editAllowed && (
        <CancelRenewalButton subscription={subscription} onAfter={onAfter} />
      )}
    </Space>
  );
}
