import {useAccountInfo} from "services/account/hook";
import {useNavigate} from "react-router-dom";
import {useBasket} from "services/basket/hook";
import {useForm, useWatch} from "antd/es/form/Form";
import {useError} from "services/error/hook";
import {useIntl} from "react-intl";
import {StoreLayout} from "components/store-layout/StoreLayout";
import {StoreError} from "pages/system/error/StoreError";
import {useBillingInfo} from "services/rest/billingInfo";
import {usePayProduct} from "services/rest/product";
import {AxiosResponse} from "axios";
import {ErrorResponse} from "services/rest/general";
import {Col, Divider, Form, notification, Row, Steps} from "antd";
import {StoreLoading} from "pages/system/loading/StoreLoading";
import {StoreCard} from "toolkit/card/StoreCard";
import {CustomerType} from "types/general";
import {CompanyBillingInfo} from "components/company-billing-info/CompanyBillingInfo";
import {IndividualBillingInfo} from "components/individual-billing-info/IndividualBillingInfo";
import {AddressBillingInfo} from "components/address-billing-info/AddressBillingInfo";
import {PaymentMethodFormItem} from "components/payment-billing-info/PaymentMethodFormItem";
import React from "react";
import {ProductInfo} from "types/product";
import {ProductTotalCostCard} from "components/product-total-cost-card/ProductTotalCostCard";

import "pages/store/product/billing/ProductBillingPage.less";

export function ProductBillingPage() {
    const {info} = useAccountInfo();
    const navigate = useNavigate();
    const {item} = useBasket();
    const [form] = useForm();
    const errorHook = useError();
    const intl = useIntl();

    const country = useWatch("country", form);
    const paymentType = useWatch("paymentType", form);
    const currency = useWatch("currency", form);

    if (!item.product) {
        return (
            <StoreLayout>
                <StoreError/>
            </StoreLayout>
        );
    }

    const {response, loading: billingInfoLoading} = useBillingInfo();

    const {loading: payProductLoading, fetch} = usePayProduct();

    const onPayment = () => {
        form.validateFields()
            .then(() => {
                fetch({
                    productId: item.product?.productId,
                    paymentType: paymentType,
                    currencyCode: currency,
                    licenseInfo: {
                        email: form.getFieldValue("email"),
                        firstName: form.getFieldValue("firstName"),
                        lastName: form.getFieldValue("lastName"),
                        companyName: form.getFieldValue("companyName"),
                        contactName: form.getFieldValue("contactName"),
                        type: info.type,
                    },
                    promoCode: item.consulting?.promoCode,
                    billingInfo: {
                        companyName: form.getFieldValue("companyName"),
                        taxNumber: form.getFieldValue("taxNumber"),
                        contactName: form.getFieldValue("contactName"),
                        email: form.getFieldValue("email"),
                        country: form.getFieldValue("country"),
                        state: form.getFieldValue("state"),
                        firstName: form.getFieldValue("firstName"),
                        lastName: form.getFieldValue("lastName"),
                        addressLine1: form.getFieldValue("addressLine1"),
                        city: form.getFieldValue("city"),
                        zip: form.getFieldValue("zip"),
                        type: info.type,
                    },
                })
                    .then((response: AxiosResponse<ProductInfo>) => {
                        // sendPurchaseEvent(ProductType.CONSULTING, paymentType);
                        navigate("/store/product/success");
                    })
                    .catch((err: ErrorResponse) => {
                        notification.error({
                            message: intl.formatMessage({id: "jmix-store.payment-error.title"}),
                            description: errorHook.getDescription(err),
                            placement: "bottomLeft",
                            duration: 15,
                        });
                    });
            });
    };

    if (payProductLoading || billingInfoLoading) {
        return (
            <StoreLoading/>
        );
    }

    return (
        <StoreCard className="product-billing-page__card">
            <Row gutter={[16, 16]} justify="space-between">
                <Col flex="0 1 700px">
                    <Row className="product-billing-page__header" justify="space-between" align="middle">
                        <div className="product-billing-page__steps">
                            <Steps current={1}>
                                <Steps.Step
                                    key="config"
                                    title={intl.formatMessage({id: "jmix-store.product-page.settings-step"})}
                                />
                                <Steps.Step
                                    key="billing"
                                    title={intl.formatMessage({id: "jmix-store.product-page.billing-step"})}
                                />
                            </Steps>
                        </div>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{...response?.customer.billingInfo}}
                        layout="vertical"
                    >
                        <Row>
                            <Col flex="auto">
                                {
                                    info.type == CustomerType.COMPANY
                                        ? <CompanyBillingInfo/>
                                        : <IndividualBillingInfo/>
                                }
                            </Col>
                        </Row>
                        <Divider className="product-billing-page__divider"/>
                        <Row>
                            <Col flex="auto">
                                <AddressBillingInfo/>
                            </Col>
                        </Row>
                        <Divider className="product-billing-page__divider"/>
                        <Row>
                            <Col flex="auto">
                                <Row>
                                    <Col>
                                        <div className="product-billing-page__title">
                                            {intl.formatMessage({id: "jmix-store.product-billing-page.payment"})}
                                        </div>
                                    </Col>
                                </Row>
                                <PaymentMethodFormItem allowedOffline={true}/>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col flex="0 1 423px">
                    <ProductTotalCostCard
                        country={country}
                        currency={currency}
                        onClick={onPayment}
                    />
                </Col>
            </Row>
        </StoreCard>
    );
}