import React, { useMemo } from "react";
import { Col, Form, Input, Row, Select, } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { useCountries, useStates } from "services/rest/billingInfo";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import "./AddressBillingInfo.less";


export function AddressBillingInfo() {
  const form = useFormInstance();
  const country = useWatch("country", form);

  return (
    <>
      <div className="address-billing-info__title">
        Address
      </div>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            label="Country"
            name="country"
            required
            rules={[
              {
                message: "Please input country",
                required: true,
              }
            ]}
          >
            <CountrySelect
              onSelect={() => form.resetFields(["state"])}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            label="State"
            name="state"
          >
            <StateSelect
              country={country}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            label="City"
            name="city"
            required
            rules={[
              {
                message: "Please input city",
                required: true,
              }
            ]}
          >
            <Input
              placeholder="City"
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 300px">
          <Form.Item
            label="Postal code"
            name="zip"
          >
            <Input placeholder="Postal code" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col flex="1 1 300px">
          <Form.Item
            label="Address"
            name="addressLine1"
            required
            rules={[
              {
                message: "Please input address",
                required: true,
              }
            ]}
          >
            <TextArea
              rows={1}
              placeholder="Address"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

type CountrySelectProps = {
  value?: string,
  onChange?: (value: string) => void,
  onSelect?: () => void,
}

function CountrySelect(props: CountrySelectProps) {
  const { value, onChange, onSelect } = props;
  const { response: countriesResponse, loading: countriesLoading } = useCountries();

  const countryOptions = useMemo(() => {
    return countriesResponse?.map((country) => {
      return {
        label: country.name,
        value: country.code,
      };
    });
  }, [countriesResponse]);


  return (
    <Select
      showSearch
      onChange={onChange}
      value={value}
      placeholder="Country"
      loading={countriesLoading}
      options={countryOptions}
      filterOption={(input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
      }}
      onSelect={onSelect}
    />
  );
}

type StateSelectProps = {
  country: string,
  value?: string,
  disabled?: boolean,
  onChange?: (value: string) => void,
}

function StateSelect(props: StateSelectProps) {
  const { country, value, onChange, disabled } = props;
  const { response, loading } = useStates({ countryCode: country }, [country]);

  const stateOptions = response && country != null
    ? response.map((state) => {
      return {
        label: state,
        value: state,
      };
    })
    : [];

  return (
    <Select
      showSearch
      value={value}
      onChange={onChange}
      placeholder="State"
      disabled={disabled}
      loading={loading}
      options={stateOptions}
      filterOption={(input, option) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
      }}
    />
  );
}
