import { Button, Form, Input, Modal, notification, Spin, Typography } from "antd";
import React, { useEffect, useMemo } from "react";
import { TariffType, useSubscriptionLicenseKey } from "services/rest/subscription";
import { useForm } from "antd/es/form/Form";
import { CopyOutlined } from "@ant-design/icons";

export type LicenseKeyInfoModalProps = {
  subscriptionId: string,
  licenseId: string,
  visible: boolean,
  onCancel: () => void,
}

export function LicenseKeyInfoModal(props: LicenseKeyInfoModalProps) {
  const { subscriptionId, licenseId, visible, onCancel } = props;
  const [form] = useForm();

  const { response, loading, error } = useSubscriptionLicenseKey(subscriptionId, licenseId);

  useMemo(() => {
    if (error) {
      form.resetFields();

      notification.error({
        message: "View key failed.",
        placement: "bottomLeft",
      });
    }
  }, [error]);

  useEffect(() => {
    if (response) {
      form.setFieldsValue({
        "sid": response.sid,
      });
    }
  }, [response]);

  const clipboard = () => {
    if (response && !error) {
      notification.success({
        message: "Key copied successfully",
        placement: "top",
      });
      navigator.clipboard.writeText(response.sid);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      title="License Key Info"
      visible={visible}
      cancelButtonProps={{
        hidden: true
      }}
      onOk={onCancel}
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Your license key"
          >
            <Input.Group compact>
              <Form.Item
                name="sid"
                noStyle
              >
                <Input
                  style={{ width: "calc(100% - 200px)" }}
                  disabled
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  icon={<CopyOutlined />}
                  onClick={clipboard}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          {
            response && !error &&
            <Typography>
              {
                response.subscription.tariff == TariffType.ENTERPRISE
                  ? "We recommend using the license key only for setting up CI/CD. Use your credentials to activate Jmix Studio."
                  : "The license key is showed for the information. Please use your account credentials to activate Jmix Studio."
              }
            </Typography>
          }
        </Form>
      </Spin>
    </Modal>
  );
}