import { Anchor, Button, Col, Divider, Row, Space } from "antd";
import { StoreCard } from "toolkit/card/StoreCard";
import React from "react";

import "components/subscription-total-card/SubscriptionTotalCostCard.less";
import { useSubscriptionCost } from "services/rest/subscription";
import { CurrencyCode } from "types/general";
import { VatInfo } from "components/vat-info/VatInfo";
import { StoreError } from "pages/system/error/StoreError";
import { DiscountInfo } from "../discount-info/DiscountInfo";

export type TotalCostProps = {
  planId: string;
  country: string;
  quantity: number;
  currencyCode: CurrencyCode;
  isCompany: boolean;
  promoCode: string;

  onClick: () => void;
};

export function SubscriptionTotalCostCard(props: TotalCostProps) {
  const {
    isCompany,
    country,
    planId,
    quantity,
    promoCode,
    currencyCode,
    onClick,
  } = props;

  const { response, loading, error, fetch } = useSubscriptionCost(
    {
      isCompany: isCompany,
      country: country,
      planId: planId,
      promoCode: promoCode,
      quantity: quantity,
      currencyCode: currencyCode || CurrencyCode.USD,
    },
    [quantity, isCompany, planId, promoCode, country, currencyCode],
  );

  const content = response && (
    <>
      <Row justify="space-between">
        <Col>
          {response.plan.name} X {quantity}
        </Col>
        <Col>
          <Space direction="vertical" align="end" size={1}>
            <span>
              {response.currency.sign}
              {response.price}
            </span>
          </Space>
        </Col>
      </Row>
      <div className="subscription-cost-card__discounts">
        <DiscountInfo
          currency={response.currency}
          discounts={response.discountNotes || []}
        />
      </div>
      <Divider />
      {response.tax != 0 && (
        <div>
          <VatInfo currency={response.currency} cost={response.tax} />
          <Divider />
        </div>
      )}
      <Row gutter={[12, 12]} justify="space-between">
        <Col>
          <strong>Total</strong>
        </Col>
        <Col>
          {response.currency.sign}
          {response.total}
        </Col>
        <Col span={24}>
          <Button
            className="cost-card__button"
            type="primary"
            size="large"
            onClick={onClick}
          >
            Complete Purchase
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Anchor className="total-cost-card__anchor" offsetTop={50}>
      <StoreCard loading={loading} className="total-cost-card__card">
        {error ? (
          <StoreError reload={fetch} autoHeight={true} bordered={false} />
        ) : (
          content
        )}
      </StoreCard>
    </Anchor>
  );
}
