import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import en from "i18n/en.json";
import { IntlProvider } from "react-intl";
import ReactGA from "react-ga4";
import { env } from "config";

import { YMInitializer } from "react-yandex-metrika";

import "./index.css";

ReactGA.initialize(env.REACT_APP_GA_ID);

// @ts-ignore
window.Chargebee.init({
  site: env.REACT_APP_CHARGE_BEE_SITE
});

const ymId = Number(env.REACT_APP_YM_ID);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <YMInitializer accounts={[ymId]}/>
      <IntlProvider
        messages={en}
        locale="en"
        defaultLocale="en"
      >
        <App />
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
