import { useIntl } from "react-intl";
import React, { useState } from "react";
import { Button, Form, notification, Radio, Space, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";

import { InfoSourceType, ProductInfo, SearchType, useSendProductInfo } from "services/rest/account";

import "./Survey.less";
import { CheckCircleOutlined } from "@ant-design/icons";

export function Survey() {
  const intl = useIntl();
  const [form] = useForm();
  const [isSurveySent, setSurveySent] = useState(false);
  const { loading, fetch } = useSendProductInfo();

  if (isSurveySent) {
    return (
      <div className="survey__feedback">
        <Space>
          <CheckCircleOutlined className="survey__feedback-icon" />
          <span>{intl.formatMessage({ id: "jmix-store.survey.feedback" })}</span>
        </Space>
      </div>
    );
  }

  const onSend = () => {
    form.validateFields()
      .then(() => {
        const fields: ProductInfo = form.getFieldsValue();

        fetch(fields)
          .then(() => {
            setSurveySent(true);
          })
          .catch(() => {
            notification.error({
              message: "Error sending survey",
              placement: "bottomLeft"
            });
          });
      });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form}
            layout="vertical">
        <div className="survey__title">
          {intl.formatMessage({ id: "jmix-store.survey.info-source.title" })}
        </div>
        <Form.Item name="infoSource"
                   required={true}
                   rules={[
                     {
                       validator: (_, value) => {
                         return value
                           ? Promise.resolve()
                           : Promise.reject(new Error(intl.formatMessage({ id: "jmix-store.survey.not-selected-error" })));
                       }
                     }
                   ]}
        >
          <Radio.Group>
            <Space direction="vertical" align="start">
              <Radio value={InfoSourceType.SEARCH_ENGINE}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.SEARCH_ENGINE" })}
              </Radio>
              <Radio value={InfoSourceType.ONLINE_ADVERTISING}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.ONLINE_ADVERTISING" })}
              </Radio>
              <Radio value={InfoSourceType.SOCIAL_NETWORKS}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.SOCIAL_NETWORKS" })}
              </Radio>
              <Radio value={InfoSourceType.WEBSITE}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.WEBSITE" })}
              </Radio>
              <Radio value={InfoSourceType.PERSONAL_RECOMMENDATION}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.PERSONAL_RECOMMENDATION" })}
              </Radio>
              <Radio value={InfoSourceType.OTHER}>
                {intl.formatMessage({ id: "jmix-store.info-source-type.OTHER" })}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="infoSourceDescription"
                   label="Description:">
          <TextArea />
        </Form.Item>
        <div className="survey__title">
          {intl.formatMessage({ id: "jmix-store.survey.search-type.title" })}
        </div>
        <Form.Item name="searchType"
                   required={true}
                   rules={[
                     {
                       validator: (_, value) => {
                         return value
                           ? Promise.resolve()
                           : Promise.reject(new Error(intl.formatMessage({ id: "jmix-store.survey.not-selected-error" })));
                       }
                     }
                   ]}
        >
          <Radio.Group>
            <Space direction="vertical" align="start">
              <Radio value={SearchType.PRODUCT_REPLACEMENT}>
                {intl.formatMessage({ id: "jmix-store.search-type.PRODUCT_REPLACEMENT" })}
              </Radio>
              <Radio value={SearchType.PRODUCT_DEVELOPMENT}>
                {intl.formatMessage({ id: "jmix-store.search-type.PRODUCT_DEVELOPMENT" })}
              </Radio>
              <Radio value={SearchType.SEARCH_INTERNET}>
                {intl.formatMessage({ id: "jmix-store.search-type.SEARCH_INTERNET" })}
              </Radio>
              <Radio value={SearchType.OTHER}>
                {intl.formatMessage({ id: "jmix-store.search-type.OTHER" })}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="searchTypeDescription"
                   label={intl.formatMessage({ id: "jmix-store.survey.description" })}>
          <TextArea />
        </Form.Item>
        <div className="survey__submit">
          <Form.Item>
            <Button type="primary"
                    onClick={onSend}>
              {intl.formatMessage({ id: "jmix-store.survey.submit" })}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
}