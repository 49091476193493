import React, { useState } from "react";
import { Card, Col, Row, Statistic } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

import { LicenseKeysCard } from "components/license-keys-card/LicenseKyesCard";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";

import {
  useProfileSubscription,
  useSubscriptionInvoice,
} from "services/rest/subscription";
import { useNavigate, useParams } from "react-router-dom";
import { StoreError } from "pages/system/error/StoreError";
import { StoreLoading } from "pages/system/loading/StoreLoading";
import { DateUtils } from "services/utils/DateUtils";
import { CurrencyUtils } from "services/utils/CurrencyUtils";
import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";
import {
  InvoiceData,
  PostedInvoiceListCard,
} from "components/posted-invoice-list-card/PostedInvoiceListCard";
import { PaidInvoiceListCard } from "components/paid-invoice-card/PaidInvoiceListCard";
import { AutoRenewal } from "components/auto-renewal/AutoRenewal";
import { CancelRenewalButton } from "components/cancel-renewal-button/CancelRenewalButton";
import { useAccountInfo } from "services/account/hook";

import "./Subscription.less";
import { CustomerType, Pagination, Role } from "types/general";
import { NavigateButton } from "components/navigate-button/NavigateButton";
import { useIntl } from "react-intl";

const routes: Route[] = [
  {
    path: "/account",
    breadcrumbName: "Account",
  },
  {
    path: "",
    breadcrumbName: "",
  },
];

export type SubscriptionPageParams = {
  subscriptionId: string;
};

export function Subscription() {
  const { info } = useAccountInfo();
  const params = useParams<SubscriptionPageParams>();
  const subscriptionId =
    params.subscriptionId !== undefined ? params.subscriptionId : "";
  const { response, loading, fetch, error } =
    useProfileSubscription(subscriptionId);
  const navigate = useNavigate();

  const intl = useIntl();
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 5,
  });

  const {
    response: invoiceResponse,
    loading: invoiceLoading,
    fetch: invoiceFetch,
    error: invoiceError,
  } = useSubscriptionInvoice(
    subscriptionId,
    "unpaid",
    pagination.page,
    pagination.size,
    [pagination],
  );

  const invoiceData: InvoiceData = {
    loading: invoiceLoading,
    error: invoiceError,
    response: invoiceResponse,
    fetch: invoiceFetch,
  };

  const isAdmin = info.type == CustomerType.COMPANY && info.role == Role.ADMIN;
  const isIndividual = info.type == CustomerType.INDIVIDUAL;

  if (loading) {
    return (
      <StoreLayout>
        <StoreLoading />
      </StoreLayout>
    );
  }

  if (!subscriptionId || error || !response) {
    return (
      <StoreLayout>
        <StoreError reload={fetch} />
      </StoreLayout>
    );
  }

  const {
    name,
    userCount,
    tariff,
    duration,
    autoRenewal,
    startDate,
    nextPayment,
    editAllowed,
    trialSubscription,
  } = response;

  const title = `${name} (Users: ${userCount})`;

  return (
    <>
      <StorePageHeader
        breadcrumb={<StoreBreadcrumb routes={routes} />}
        title={title}
      >
        <Card className="subscription__header-info" bordered={false}>
          <Row gutter={[8, 8]}>
            <Col flex="0 0 200px">
              <Statistic
                title="Plan"
                value={`${tariff.name} - ${DateUtils.getFormatDuration(
                  duration,
                )}`}
              />
            </Col>
            <Col flex="0 0 130px">
              <Statistic
                title="Start"
                value={DateUtils.getFormatDate(startDate)}
              />
            </Col>
            <Col flex="0 0 130px">
              <Statistic
                title={
                  autoRenewal
                    ? intl.formatMessage({
                        id: "jmix-store.subscription-item.next-payment-label",
                      })
                    : intl.formatMessage({
                        id: "jmix-store.subscription-item.end-label",
                      })
                }
                value={DateUtils.getFormatDate(nextPayment.date)}
              />
            </Col>
            {nextPayment.price != null && (
              <Col flex="0 0 130px">
                <Statistic
                  title="Payment Amount"
                  value={CurrencyUtils.getFormatCurrency(
                    nextPayment.currency,
                    nextPayment.price,
                  )}
                />
              </Col>
            )}
            <Col flex="1 0 130px">
              <AutoRenewal autoRenewal={autoRenewal} />
            </Col>
            {(isIndividual || isAdmin) && !trialSubscription && editAllowed && (
              <Col flex="0 0 200px">
                <NavigateButton
                  type="primary"
                  ghost={false}
                  name="Change number of users or plan"
                  path={`/store/subscription/${subscriptionId}`}
                />
              </Col>
            )}
            {(isIndividual || isAdmin) && autoRenewal && editAllowed && (
              <Col flex="0 0 150px">
                <CancelRenewalButton
                  subscription={response}
                  onAfter={() => navigate("/account")}
                />
              </Col>
            )}
          </Row>
        </Card>
      </StorePageHeader>
      <StoreLayout>
        <Row gutter={[32, 32]}>
          <Col flex="100%">
            <LicenseKeysCard
              subscriptionId={subscriptionId}
              isTrial={trialSubscription}
            />
          </Col>
          {(isAdmin || isIndividual) && (
            <Col flex="100%">
              <PostedInvoiceListCard
                subscriptionId={subscriptionId}
                invoiceData={invoiceData}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Col>
          )}
          {(isAdmin || isIndividual) && (
            <Col flex="100%">
              <PaidInvoiceListCard subscriptionId={subscriptionId} />
            </Col>
          )}
        </Row>
      </StoreLayout>
    </>
  );
}
