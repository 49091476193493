import { LocalDate, PeriodUnit } from "types/general";

export enum Month {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export type Duration = {
  duration: number,
  durationUnit: PeriodUnit
}

export class DateUtils {

  static getFormatDate(date?: LocalDate) {
    return date ? `${date.dayOfMonth} ${this.getShortName(date.month)} ${date.year}` : "-";
  }

  static getDate(date?: Date) {
    return date ? `${date.getDay()} ${this.getShortName(date.getMonth())} ${date.getFullYear()}` : "-";
  }

  static getShortName(mouth: Month) {
    const mouthString = mouth.toString();
    return mouthString.charAt(0) + mouthString.slice(1, 3).toLowerCase();
  }

  static getFormatDuration(dur: Duration) {
    const { durationUnit, duration } = dur;
    return `${duration} ${durationUnit.toString().toLowerCase()}`;
  }

  static getFormatTime(value: number) {
    const min = Math.floor(value / 60);
    const sec = value % 60;

    return `${min}:${sec}`;
  }
}