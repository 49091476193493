import React from "react";
import { Currency } from "types/general";

import "./VatInfo.less";

export type VatInfoProps = {
  currency: Currency,
  cost: number,
}

export function VatInfo(props: VatInfoProps) {
  const { cost, currency } = props;

  return (
    <div className="vat-info">
      <div>
        VAT
      </div>
      <div>
        {currency.sign}{cost}
      </div>
    </div>
  );
}