import { useKeycloak } from "services/keycloak/hook";

export function useDownload() {
  const keycloak = useKeycloak();

  const download = (url: string) => {
    keycloak.updateToken(60)
      .then(() => {
        const link = `${url}?access_token=${keycloak.token}`;

        window.open(link, "_blank");
      })
      .catch(() => {
        keycloak.login();
      });
  };

  return { download };
}