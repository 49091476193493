import React from "react";

import { Button, Layout, Result, Row, Space } from "antd";

import "./Maintenance.less";

export type MaintenanceProps = {
  title: string,
  description: string,
}

export function Maintenance(props: MaintenanceProps) {
  const { title, description } = props;

  return (
    <Layout className="maintenance-mode">
      <Row
        justify="center"
        align="middle"
      >
        <Space direction="vertical" align="center">
          <Result
            status="500"
            title={title}
            subTitle={description}
            extra={
              <Button
                type="primary"
                onClick={() => window.location.replace("https://jmix.io")}
              >
                Back to jmix.io
              </Button>
            }
          />
        </Space>
      </Row>
    </Layout>
  );
}