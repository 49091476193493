import { StoreCard } from "toolkit/card/StoreCard";
import { Col, Result, Row, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { useLocation, useParams } from "react-router-dom";
import { SubscriptionPageParams } from "pages/account/subscription/Subscription";
import { useIntl } from "react-intl";
import { SubscriptionInfo } from "services/rest/subscription";
import { env } from "config";
import { NavigateButton } from "components/navigate-button/NavigateButton";

export function EditSubscriptionSuccessPage() {
  const intl = useIntl();
  const location = useLocation();
  const { subscriptionId } = useParams<SubscriptionPageParams>();

  const subscriptionInfo = location.state as SubscriptionInfo;
  const subscriptionUrl = `/account/subscription/${subscriptionId}`;

  const title = subscriptionInfo.offline
    ? intl.formatMessage({ id: "jmix-store.success-page.offline.title" })
    : intl.formatMessage({ id: "jmix-store.success-page.edit-subscription.online.title" });

  const description = subscriptionInfo.offline
    ? (
      <div className="new-subscription-success__text">
        {intl.formatMessage({ id: "jmix-store.success-page.offline.description" }, {
          link: (str) => <a href={env.REACT_APP_JMIX_ACCOUNT_URL}>{str}</a>,
        })}
      </div>
    )
    : (
      <div className="new-subscription-success__text">
        {intl.formatMessage({ id: "jmix-store.success-page.edit-subscription.online.description" })}
      </div>
    );

  return (
    <StoreLayout>
      <StoreCard className="new-subscription-success-page">
        <Row
          className="new-subscription-success__content"
          justify="space-around"
          align="middle"
        >
          <Col>
            <Space direction="vertical" align="center">
              <Result
                className="new-subscription-success__description"
                icon={<CheckOutlined className="new-subscription-success_check-icon" />}
                title={title}
                subTitle={description}
                extra={
                  subscriptionInfo.offline
                    ? <NavigateButton
                      name="Go to Jmix Account"
                      path="/account"
                      type="primary"
                      ghost={false}
                    />
                    : <NavigateButton
                      name="Go to subscription page"
                      path={subscriptionUrl}
                      type="primary"
                      ghost={false}
                    />
                }
              />
            </Space>
          </Col>
        </Row>
      </StoreCard>
    </StoreLayout>
  );
}