import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {Consulting} from "pages/store/consulting/Consulting";

import {NewSubscription} from "./subscription/new/NewSubscription";
import {ServicesPage} from "./main/ServicesPage";
import {RequestSubscriptionSuccessPage} from "pages/store/subscription/result/RequestSubscriptionSuccessPage";
import {EditSubscriptionRoutes} from "pages/store/subscription/edit/EditSubscriptionRoutes";
import {ProductPage} from "./product/ProductPage";

export function StoreRoutes() {
    return (
        <Routes>
            <Route path="/consulting/*" element={<Consulting/>}/>
            <Route path="/subscription">
                <Route path=":subscriptionId/*" element={<EditSubscriptionRoutes/>}/>
                <Route path="new/*" element={<NewSubscription/>}/>
                <Route path="request-success" element={<RequestSubscriptionSuccessPage/>}/>
                <Route index element={<Navigate to="/store/subscription/new"/>}/>
            </Route>
            <Route path="/product/*" element={<ProductPage/>}/>
            <Route path="/" element={<ServicesPage/>}/>
        </Routes>
    );
}
