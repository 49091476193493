import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Result, Row, Space, } from "antd";

import "./StoreLoading.less";
import { StoreCard } from "toolkit/card/StoreCard";

export function StoreLoading() {
  const icon = (
    <LoadingOutlined
      style={{ fontSize: 72 }}
      spin
    />
  );

  return (
    <StoreCard className="store-loading">
      <Row
        className="store-loading__content"
        justify="space-around"
        align="middle"
      >
        <Col>
          <Space
            align="center"
            direction="vertical"
          >
            <Row
              className="store-loading__content"
              justify="center"
              align="middle"
            >
              <Col>
                <Result
                  icon={icon}
                  title="Please wait a few seconds"
                />
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    </StoreCard>
  );
}
