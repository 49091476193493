import React from "react";
import { PageHeader, PageHeaderProps } from "antd";

import "./StorePageHeader.less";

type StorePageHeaderProps = {
  children?: React.ReactNode
}

type IStorePageHeaderProps = StorePageHeaderProps & PageHeaderProps

export function StorePageHeader(props: IStorePageHeaderProps) {
  return (
    <PageHeader
      {...props}
      className="store-page-header"
    >
      {props.children}
    </PageHeader>
  );
}
