import { useIntl } from "react-intl";
import { Modal, notification } from "antd";
import React from "react";
import { useAddMember } from "services/rest/member";
import { Role } from "types/general";
import { env } from "config";

export type SendInviteModalProps = {
  visible: boolean,
  email: string,
  role: Role,
  onAfterOk: () => void,
  onCancel: () => void,
}

export function SendInviteModal(props: SendInviteModalProps) {
  const { email, role, visible, onCancel, onAfterOk } = props;
  const intl = useIntl();

  const { fetch, loading } = useAddMember();

  const addUser = () => {
    fetch({ email, role })
      .then(() => {
        Modal.success({
          centered: true,
          title: intl.formatMessage({ id: "jmix-store.account.add-user-modal.title", }),
          content: intl.formatMessage({
            id: "jmix-store.account.add-new-user-result-modal.content",
          }, {
            email
          })
        });

        onAfterOk();
        onCancel();
      })
      .catch(() => {
        notification.error({
          message: "Add user failed.",
          placement: "bottomLeft",
        });
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      confirmLoading={loading}
      visible={visible}
      title={intl.formatMessage({ id: "jmix-store.account.add-user-modal.title" })}
      okText={intl.formatMessage({ id: "jmix-store.modal.action.yes" })}
      onOk={addUser}
      onCancel={onCancel}
    >
      <div>
        {intl.formatMessage({ id: "jmix-store.account.add-new-user-modal.content_1", }, {
          email: <strong>{email}</strong>,
          link: <a href={env.REACT_APP_JMIX_IO} target="_blank" rel="noopener noreferrer">jmix.io</a>
        })}
      </div>
      <div>
        {intl.formatMessage({ id: "jmix-store.account.add-new-user-modal.content_2" })}
      </div>
    </Modal>
  );
}
