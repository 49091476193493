import { ConsultingCost, ConsultingShortInfo } from "types/consulting";
import { baseUrl, useAxios } from "services/rest/request";
import { DependencyList } from "react";
import { CurrencyCode, CustomerType, LocalDate } from "types/general";
import { BillingInfo } from "services/rest/billingInfo";
import { ConsultingInfo } from "services/rest/subscription";

export type ConsultingCostBody = {
  promoCode: string,
  quantity: number,
  country?: string,
  currencyCode?: CurrencyCode,
}

export type ConsultancyHoursBody = {
  eventList: EventItem[],
  unitsLeft: number,
  unitsUsed: number,
  unitsTotal: number,
}

export type EventItem = {
  date: LocalDate,
  isWriteOff: boolean,
  units: number,
  fileId: string,
  fileType: string,
}

export type NewConsulting = {
  billingInfo: BillingInfo,
  currencyCode?: CurrencyCode,
  licenseInfo: LicenseInfo,
  quantity: number,
  selectedLanguageId: string,
  promoCode: string,
}

export type LicenseInfo = {
  email: string,
  firstName: string,
  lastName: string,
  companyName: string,
  contactName: string,
  type: CustomerType;
}

export const useConsultingCost = (body: ConsultingCostBody, deps: DependencyList) => {
  return useAxios<ConsultingCost>({
    url: `${baseUrl}/consulting/calculate`,
    method: "post",
    data: body
  }, deps);
};

export const useConsultingInfo = () => {
  return useAxios<ConsultingShortInfo>({
    url: `${baseUrl}/consulting/info`,
    method: "get",
  }, []);
};

export const useConsultancyHours = () => {
  return useAxios<ConsultancyHoursBody>({
    url: `${baseUrl}/profile/balance/consultancy`,
    method: "get"
  }, []);
};

export const usePayConsulting = () => {
  return useAxios<ConsultingInfo>({
    url: `${baseUrl}/consulting/pay`,
    method: "post",
  }, [], false);
};