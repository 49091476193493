import { useContext } from "react";
import { AccountContext } from "services/account/AccountContext";

export function useAccountInfo() {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error("Account context must be inside a Provider with a value");
  }

  return context;
}