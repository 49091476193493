import React, { useEffect, useState } from "react";

import {
  SubscriptionInfo,
  useUpdateSubscriptionCost,
} from "services/rest/subscription";
import { StoreCard } from "toolkit/card/StoreCard";
import { Button, Divider } from "antd";

import { DiscountInfo } from "components/discount-info/DiscountInfo";

import { PromoCodeButton } from "components/promo-code-button/PromoCodeButton";
import { CurrencyCode, ProductType } from "types/general";

import "./SubscriptionEditCostCard.less";

export type SubscriptionEditCostCardProps = {
  subscriptionInfo: SubscriptionInfo;
  tariffId?: string;
  count?: number;
  currency: CurrencyCode;
  onProcess: () => void;
  onApplyEditSubscriptionPromoCode: (editSubscriptionPromoCode: string) => void;
};

export function SubscriptionEditCostCard(props: SubscriptionEditCostCardProps) {
  const {
    subscriptionInfo,
    tariffId,
    count,
    currency,
    onApplyEditSubscriptionPromoCode,
    onProcess,
  } = props;

  const [editCostCardPromoCode, setEditCostCardPromoCode] = useState("");

  useEffect(() => {
    if (subscriptionInfo.currentPromoCode !== undefined) {
      setEditCostCardPromoCode(subscriptionInfo.currentPromoCode);
    }
  }, [subscriptionInfo.currentPromoCode]);

  const { response, loading } = useUpdateSubscriptionCost(
    {
      subscriptionId: subscriptionInfo.id,
      isCompany: subscriptionInfo.isCompany,
      quantity: count,
      tariffId: tariffId,
      promoCode: editCostCardPromoCode,
      currency: currency,
    },
    [count, tariffId, editCostCardPromoCode, subscriptionInfo, currency],
  );

  const currencySign = response?.currency.sign;

  return (
    <>
      <div className="subscription-edit-cost-card">
        <div className="subscription-edit-cost-card__head">
          Regular payment at the end of the current period
        </div>
        <StoreCard
          className="subscription-edit-cost-card__card"
          bordered={false}
          loading={loading}
        >
          {response && (
            <>
              <div className="subscription-edit-cost-card__description">
                <div>
                  {response?.name} X {count}
                </div>
                <div>
                  {currencySign}
                  {response.price}
                </div>
              </div>
              <Divider className="subscription-edit-cost-card__divider" />
              <div className="subscription-edit-cost-card__description">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  <strong>
                    {currencySign}
                    {response.price}
                  </strong>
                </div>
              </div>
            </>
          )}
        </StoreCard>
        <div className="subscription-edit-cost-card__head">
          Subscription update one-off payment
        </div>
        <StoreCard
          className="subscription-edit-cost-card__card"
          bordered={false}
          loading={loading}
        >
          {response && (
            <>
              <div className="subscription-edit-cost-card__description">
                <div>Current regular payment</div>
                <div>
                  {currencySign}
                  {response?.currentPrice}
                </div>
              </div>
              <div className="subscription-edit-cost-card__description">
                <div>New regular payment</div>
                <div>
                  {currencySign}
                  {response?.newPrice}
                </div>
              </div>
              <div className="subscription-edit-cost-card__discounts">
                <DiscountInfo
                  currency={response.currency}
                  discounts={response.discountNotes || []}
                />
              </div>
              <div className="subscription-edit-cost-card__description">
                <div>Difference</div>
                <div>
                  {currencySign}
                  {response?.totalDifference}
                </div>
              </div>
              <div className="subscription-edit-cost-card__description">
                <div>Remaining days in current period</div>
                <div>
                  <div className="subscription-edit-cost-card__days-percent">
                    {response?.remainingPeriodDiscount}%
                  </div>
                  <span className="subscription-edit-cost-card__days">
                    ({response?.remainingDaysInPeriod}/{response?.daysInPeriod})
                  </span>
                </div>
              </div>
              <div className="subscription-edit-cost-card__description">
                <div>
                  Required payment ${response?.totalDifference}*
                  {response?.remainingPeriodDiscount}%
                </div>
                <div>
                  {currencySign}
                  {response?.updatePrice}
                </div>
              </div>
              <div className="subscription-edit-cost-card__description">
                {!subscriptionInfo.currentPromoCode && (
                  <PromoCodeButton
                    type={ProductType.LICENSE}
                    onApplyEditCostCardPromoCode={(editCostCardPromoCode) =>
                      setEditCostCardPromoCode(editCostCardPromoCode)
                    }
                    onApply={(editSubscriptionPromoCode) =>
                      onApplyEditSubscriptionPromoCode(
                        editSubscriptionPromoCode,
                      )
                    }
                  >
                    Apply promo code
                  </PromoCodeButton>
                )}
              </div>
              <Divider className="subscription-edit-cost-card__divider" />
              <div className="subscription-edit-cost-card__description">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  <strong>
                    {currencySign}
                    {response?.updateTotal}
                  </strong>
                </div>
              </div>
              <div className="subscription-edit-cost-card__purchase-block">
                <Button
                  className="subscription-edit-cost-card__purchase-button"
                  size="large"
                  type="primary"
                  onClick={onProcess}
                >
                  Purchase
                </Button>
              </div>
            </>
          )}
        </StoreCard>
      </div>
    </>
  );
}
