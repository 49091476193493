import { Button, Layout, Result, Row, Space } from "antd";
import React from "react";

import "./StoreNotAvailable.less";

export function StoreNotAvailable() {
  return (
    <Layout className="store-not-available">
      <Row
        justify="center"
        align="middle"
      >
        <Space direction="vertical" align="center">
          <Result
            status="500"
            title="Server not available"
            subTitle=""
            extra={
              <Button
                type="primary"
                onClick={() => window.location.replace("https://jmix.io")}
              >
                Back Jmix.io
              </Button>
            }
          />
        </Space>
      </Row>
    </Layout>
  );
}