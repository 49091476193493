import React from "react";
import { Divider, Modal } from "antd";
import { PaymentCard } from "components/payment-card/PaymentCard";

import "./PaymentModal.less";

export type PaymentModalProps = {
  visible: boolean,
  title: string,
  loading: boolean,
  amount: number,
  currency: string,
  onOk: () => void,
  onClose: () => void,
}

export function PaymentModal(props: PaymentModalProps) {
  const { title, amount, currency, loading, onOk, onClose, visible } = props;

  return (
    <Modal
      centered
      visible={visible}
      title={title}
      confirmLoading={loading}
      cancelButtonProps={{ disabled: loading }}
      closable={!loading}
      maskClosable={false}
      destroyOnClose={true}
      onOk={onOk}
      onCancel={onClose}
    >
      <div className="payment-modal__total">
        <div>
          Total:
        </div>
        <div>
          {currency}{amount}
        </div>
      </div>
      <Divider />
      <div>
        <PaymentCard disabled={loading} />
      </div>
    </Modal>
  );
}