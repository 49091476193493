import React, { useEffect, useState } from "react";

import { SplashScreen } from "components/splash/SplashScreen";

import { KeycloakContext } from "./KeycloakContext";
import { client } from "./client";

type KeycloakProviderProps = {
  children: React.ReactNode
}

export function KeycloakProvider(props: KeycloakProviderProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client.init({
      onLoad: "check-sso",
    })
      .then((authorization) => {
        if (authorization) {
          setLoading(false);
        } else {
          client.login();
        }
      })
      .catch(() => {
        console.log("Keycloak Error");
      });
  }, [client]);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <KeycloakContext.Provider value={{ keycloak: client }}>
      {props.children}
    </KeycloakContext.Provider>
  );
}
