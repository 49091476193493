import { baseUrl, useAxios } from "services/rest/request";
import { DependencyList } from "react";
import { Duration } from "services/utils/DateUtils";
import {
  Currency,
  CurrencyCode,
  CustomerType,
  DiscountNote,
  LocalDate,
  Pageable,
  PeriodUnit,
  ProductType,
  Role,
} from "types/general";
import { Invoice } from "services/rest/account";
import { BillingInfo } from "services/rest/billingInfo";

import { Customer, ValidateResponse } from "services/rest/general";
import { LicenseKeyRow } from "pages/store/subscription/new/modal/license-keys/LicenseKeysModal";

export type Tariff = {
  id: string;
  name: string;
  priority: number;
};

export type TariffInfo = {
  id: string;
  name: string;
  plans: PlanInfo[];
  priority: number;
};

export type PlanInfo = {
  id: string;
  name: string;
  companyPrice: number;
  individualPrice: number;
  defaultItem: boolean;
  discount: number;
  duration: Duration;
};

export type ShortSubscriptionInfo = {
  id: string;
  startDate: LocalDate;
  expireOn: LocalDate;
  keysCount: number;
  nextPayment: LocalDate;
  licenseType: string;
  status: string;
  tariff: TariffType;
};

export enum TariffType {
  RAD = "RAD",
  ENTERPRISE = "ENTERPRISE",
}

export enum LicenseState {
  ACTIVE = "ACTIVE",
  NOT_ACTIVATED = "NOT_ACTIVATED",
  BANNED = "BANNED",
  TO_PROLONG = "TO_PROLONG",
  NON_RENEWING = "NON_RENEWING",
  EXPIRED = "EXPIRED",
}

export type SubscriptionCostRequest = {
  country?: string;
  isCompany: boolean;
  planId: string;
  licenseKeys?: LicenseKeyRow[];
  promoCode?: string;
  quantity: number;
  currencyCode: CurrencyCode;
};

export type SubscriptionUpdateCostRequest = {
  subscriptionId?: string;
  isCompany: boolean;
  quantity?: number;
  tariffId?: string;
  promoCode?: string;
  currency?: CurrencyCode;
};

export type Plan = {
  id: string;
  name: string;
  amount: number;
};

export type SubscriptionCost = {
  plan: Plan;
  currency: Currency;
  discount: number;
  discountNotes: DiscountNote[];
  price: number;
  tax: number;
  total: number;
  withoutDiscont: number;
  termDiscountText: string;
  duration: Duration;
};

export type SubscriptionUpdateCostResponse = {
  name: string;
  currentPrice: number;
  currency: Currency;
  price: number;
  newPrice: number;
  totalDifference: number;
  daysInPeriod: number;
  remainingDaysInPeriod: number;
  remainingPeriodDiscount: number;
  updatePrice: number;
  updateTax: number;
  updateTotal: number;
  usedCredits: number;
  updateTotalWithoutCredits: number;
  // List<PriceNoteInfo> priceNotes = new ArrayList<>();
  discountNotes: DiscountNote[];
};

export type SubscriptionInfo = {
  id: string;
  name: string;
  autoRenewal: boolean;
  startDate: LocalDate;
  endDate: LocalDate;
  nextPayment: Payment;
  userCount: number;
  tariff: Tariff;
  duration: Duration;
  editAllowed: boolean;
  trialDownloadUrl: string;
  status: string;
  type: string;
  trialSubscription: boolean;
  licenseEmail: string;
  currency: Currency;
  price: string;
  isCompany: boolean;
  offline: boolean;
  individualPrice: boolean;
  currentPromoCode: string;
  eligibleForFreeConsulting: boolean;
};

export type ConsultingInfo = {
  id: string;
  name: string;
  autoRenewal: boolean;
  startDate: LocalDate;
  endDate: LocalDate;
  editAllowed: boolean;
  status: string;
  type: string;
  licenseEmail: string;
  currency: Currency;
  price: string;
  isCompany: boolean;
  offline: boolean;
  hours: number;
};

export type Payment = {
  date: LocalDate;
  price: number;
  currency: Currency;
};

export type LicenseKey = {
  id: string;
  sid: string;
  customer: Customer;
  subscription: ShortSubscriptionInfo;
  renewing: boolean;
  startDate: LocalDate;
  endDate: LocalDate;
  state: LicenseState;
};

export type AddonInfo = {
  addons: Addon[];
};

export type Addon = {
  id: string;
  name: string;
  enabled: boolean;
  link: string;
};

export type NewSubscription = {
  planId: string;
  quantity: number;
  licenseInfo: LicenseInfo;
  licenseKeys: string[];

  promoCode: string;
  billingInfo: BillingInfo;
  creditsWithdraw?: boolean;
  currencyCode?: CurrencyCode;
  gaCookie?: string;
  trafficSource?: string;
};

export type UpdateSubscription = {
  subscriptionId: string;
  tariffId: string;
  quantity: number;
  offlineInvoice?: boolean;
  promocode: string;
};

export type LicenseInfo = {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  contactName: string;
  type: CustomerType;
};

export type AssignLicenseKeyResponse = {
  memberId: string;
};

export type SendEmail = {
  success: boolean;
  repeatIn: number;
};

export type CheckPromoCode = {
  promoCode: string;
  type: string;
};

export type LicenseKeyToMemberRequest = {
  memberId: string;
};

export type LicenseKeyToNewMemberRequest = {
  email: string;
  role: Role;
};

export type IndividualRequest = {
  projectInfo: string;
};

export type AcademicRequest = {
  fileId: string;
  projectInfo: string;
  isStudent: boolean;
};

export function useSendEmail(subscriptionId: string, licenseId: string) {
  return useAxios<SendEmail>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}/email`,
      method: "post",
    },
    [],
    false,
  );
}

export const usePlans = (
  period?: number,
  periodUnit?: PeriodUnit,
  deps: DependencyList = [],
) => {
  return useAxios<TariffInfo[]>(
    {
      url: `${baseUrl}/subscriptions/tariffs`,
      method: "get",
      params: {
        period: period,
        periodUnit: periodUnit,
      },
    },
    deps,
  );
};

export const useLicenseList = () => {
  return useAxios<ShortSubscriptionInfo[]>(
    {
      url: `${baseUrl}/profile/activeLicenses`,
      method: "get",
    },
    [],
  );
};

export const useLicense = () => {
  return useAxios<ShortSubscriptionInfo>(
    {
      url: `${baseUrl}/profile/activeLicenses`,
      method: "get",
    },
    [],
  );
};

export const useSubscriptionCost = (
  data: SubscriptionCostRequest,
  deps: DependencyList,
) => {
  return useAxios<SubscriptionCost>(
    {
      url: `${baseUrl}/subscriptions/calculate`,
      method: "post",
      data: data,
    },
    deps,
  );
};

export const useUpdateSubscriptionCost = (
  data: SubscriptionUpdateCostRequest,
  deps: DependencyList,
) => {
  return useAxios<SubscriptionUpdateCostResponse>(
    {
      url: `${baseUrl}/subscriptions/update/calculate`,
      method: "post",
      data: data,
    },
    deps,
  );
};

export const useSubscriptionLicenseKeys = (
  subscriptionId: string,
  page = 1,
  size = 5,
) => {
  return useAxios<Pageable<LicenseKey>>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses?page=${page}&size=${size}`,
      method: "get",
    },
    [page, size],
  );
};

export const useSubscriptionLicenseKey = (
  subscriptionId: string,
  licenseId: string,
) => {
  return useAxios<LicenseKey>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}`,
      method: "get",
    },
    [subscriptionId, licenseId],
  );
};

export const useAssignLicenseKeyToMember = (
  subscriptionId: string,
  licenseId: string,
  data?: LicenseKeyToMemberRequest,
) => {
  return useAxios<AssignLicenseKeyResponse>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}/member`,
      method: "put",
      data: data,
    },
    [],
    false,
  );
};
export const useUnassignLicenseKey = (
  subscriptionId: string,
  licenseId: string,
  data?: LicenseKeyToMemberRequest,
) => {
  return useAxios<AssignLicenseKeyResponse>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}/member`,
      method: "delete",
      data: data,
    },
    [],
    false,
  );
};

export const useCheckLicenseKey = (
  subscriptionId: string,
  licenseId: string,
  memberId: string,
  email: string,
  deps: DependencyList,
) => {
  return useAxios<ValidateResponse>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}/license-verification`,
      params: {
        memberId: memberId,
        email: email,
      },
      method: "get",
    },
    deps,
    false,
  );
};

export const useAssignLicenseKeyToNewMember = (
  subscriptionId: string,
  licenseId: string,
  data?: LicenseKeyToNewMemberRequest,
) => {
  return useAxios<AssignLicenseKeyResponse>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/licenses/${licenseId}/new`,
      method: "put",
      data: data,
    },
    [],
    false,
  );
};

export const useProfileSubscriptions = () => {
  return useAxios<SubscriptionInfo[]>(
    {
      url: `${baseUrl}/subscriptions`,
      method: "get",
    },
    [],
  );
};

export const useProfileSubscription = (
  subscriptionId: string | undefined | null,
) => {
  return useAxios<SubscriptionInfo>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}`,
      method: "get",
    },
    [],
  );
};

export const usePaySubscription = (body?: NewSubscription) => {
  return useAxios<SubscriptionInfo>(
    {
      url: `${baseUrl}/subscriptions/pay`,
      method: "post",
      data: body,
    },
    [],
    false,
  );
};

export const usePayUpdateSubscription = (body?: UpdateSubscription) => {
  return useAxios<SubscriptionInfo>(
    {
      url: `${baseUrl}/subscriptions/update/pay`,
      method: "post",
      data: body,
    },
    [],
    false,
  );
};

export const useProfileLicenseKeys = () => {
  return useAxios<LicenseKey[]>(
    {
      url: `${baseUrl}/profile/licenses`,
      method: "get",
    },
    [],
  );
};

export const useRequestAcademic = () => {
  return useAxios(
    {
      url: `${baseUrl}/subscriptions/request/academic`,
      method: "post",
    },
    [],
    false,
  );
};

export const useRequestIndividual = (data?: IndividualRequest) => {
  return useAxios(
    {
      url: `${baseUrl}/subscriptions/request/individual`,
      method: "post",
      data: data,
    },
    [],
    false,
  );
};

export const useSubscriptionInvoice = (
  subscriptionId: string,
  type: "paid" | "unpaid",
  page = 1,
  size = 5,
  deps: DependencyList,
) => {
  return useAxios<Pageable<Invoice>>(
    {
      url: `${baseUrl}/subscriptions/${subscriptionId}/invoices/${type}`,
      params: { page: page, size: size },
      method: "get",
    },
    deps,
  );
};

export const useInvoice = (
  type: "paid" | "unpaid",
  page = 1,
  size = 5,
  deps: DependencyList,
) => {
  return useAxios<Pageable<Invoice>>(
    {
      url: `${baseUrl}/profile/invoices/${type}`,
      params: { page: page, size: size },
      method: "get",
    },
    deps,
  );
};

export const useCheckPromocode = (code: string, type: ProductType) => {
  return useAxios<ValidateResponse>(
    {
      url: `${baseUrl}/validate/promocode?code=${code}&type=${type}`,
      method: "get",
    },
    [],
    false,
  );
};

export const useCancelSubscription = (subscriptionId: string) => {
  return useAxios(
    {
      url: `${baseUrl}/subscriptions/cancel?subscriptionId=${subscriptionId}`,
      method: "post",
    },
    [],
    false,
  );
};

export const useCheckTrial = () => {
  return useAxios<SubscriptionInfo[]>(
    {
      url: `${baseUrl}/subscriptions`,
      method: "get",
    },
    [],
  );
};

export const useGetTrial = () => {
  return useAxios<SubscriptionInfo[]>(
    {
      url: `${baseUrl}/subscriptions`,
      method: "get",
    },
    [],
  );
};
