import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useAssignLicenseKeyToMember, useUnassignLicenseKey } from "services/rest/subscription";
import { Button, Form, Modal, notification, Radio } from "antd";
import { UserTeamSelect } from "components/user-team-select/UserTeamSelect";
import { ILicenseKey } from "components/license-keys-card/LicenseKyesCard";
import { UserType } from "components/license-keys-card/utils/utils";
import { useIntl } from "react-intl";

export type ReAssignModalProps = {
  licenseKey: ILicenseKey,
  visible: boolean,
  subscriptionId: string,
  onAfterOk: () => void,
  onCancel: () => void,
}

export function ReAssignModal(props: ReAssignModalProps) {
  const { licenseKey, subscriptionId, visible, onAfterOk, onCancel } = props;

  const intl = useIntl();
  const [form] = useForm();
  const [assignType, setAssignType] = useState(UserType.TEAM);

  const { loading, fetch } = useAssignLicenseKeyToMember(subscriptionId, licenseKey.id);
  const { loading: unassignLoading, fetch: unassignFetch } = useUnassignLicenseKey(subscriptionId, licenseKey.id);

  form.setFieldsValue({
    "user": licenseKey.memberId,
  });

  const reAssignLicenseKey = () => {
    form.validateFields()
      .then(() => {
        fetch({
          memberId: form.getFieldValue("user"),
        })
          .then(() => {
            notification.success({
              message: intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.success" }),
              placement: "bottomLeft",
            });

            onCancel();
            onAfterOk();
          })
          .catch(() => {
            notification.error({
              message: intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.failed" }),
              placement: "bottomLeft",
            });
          });
      });
  };

  const name = `${licenseKey.email} ` + (licenseKey.name ? `(${licenseKey.name})` : "");

  return (
    <Modal
      centered
      title={intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.title" })}
      visible={visible}
      onOk={reAssignLicenseKey}
      okButtonProps={{
        loading: loading
      }}
      onCancel={onCancel}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item>
          <div>
            <strong>{intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.key" })}</strong>
            <span>{licenseKey.keyId}</span>
          </div>
          <div>
            <strong>{intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.assigned" })}</strong>
            <span>{name}</span>
          </div>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.assign" })}>
          <Radio.Group defaultValue={assignType}>
            <Radio.Button
              value="team"
              onClick={() => setAssignType(UserType.TEAM)}
            >
              User from team
            </Radio.Button>
            <Radio.Button
              value="new"
              onClick={() => setAssignType(UserType.NEW)}
              disabled
            >
              New User
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          required
          label={intl.formatMessage({ id: "jmix-store.subscription.re-assign-license-modal.user" })}
          name="user"
        >
          <UserTeamSelect />
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            onClick={() => {
              Modal.confirm({
                title: "License key unassignment",
                content: <div>Are you sure you want to unassign the license key? </div>,
                centered: true,
                okButtonProps: {
                  loading: unassignLoading,
                },
                onOk() {
                  unassignFetch()
                    .then(() => {
                        onCancel();
                        onAfterOk();
                      }
                    )
                    .catch(() => {
                      notification.error({
                        message: intl.formatMessage({ id: "jmix-store.subscription.unassign-license-modal.failed" }),
                        placement: "bottomLeft",
                      });
                    });
                }
              });
            }}
          >
            Unassign the key
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}