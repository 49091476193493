import React from "react";
import { Route, Routes } from "react-router-dom";

import { AccountPage } from "pages/account/AccountPage";
import { Consultancy } from "./consultancy/Consultancy";
import { Subscription } from "./subscription/Subscription";
import { Incidents } from "pages/account/incidents/Incidents";

export function Account() {
  return (
    <Routes>
      <Route
        path="/subscription/:subscriptionId"
        element={<Subscription />}
      />
      <Route
        path="/consultancy"
        element={<Consultancy />}
      />
      <Route
        path="/incidents"
        element={<Incidents />}
      />
      <Route
        path="/"
        element={<AccountPage />}
      />
    </Routes>
  );
}
