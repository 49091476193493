import React from "react";
import {
  Col, Layout, Row, Space,
} from "antd";
import pack from "./../../../package.json";

import "./StoreFooter.less";
import { env } from "config";

export const StoreFooter = () => {
  const isShowVersion = env.REACT_APP_PROD_VERSION === "false";

  return (
    <Layout.Footer className="store-footer">
      <Layout className="store-footer__container">
        <Row gutter={[16, 16]}>
          <Col flex="0 2 300px">
            Developed and sponsored by
            {" "}
            <a
              target="_blank"
              href="https://www.haulmont.com/"
              rel="noreferrer"
            >
              Haulmont
            </a>
          </Col>
          <Col flex="1 0 150px">
            <a href="https://www.jmix.io/terms-of-use/">Terms of Use</a>
          </Col>
          {
            isShowVersion &&
            <Col flex="0 1 150px">
              <div className="store-footer__version">
                Jmix Store {pack.version}
              </div>
            </Col>
          }
          <Col flex="0 6 200px">
            <Space align="end">
              <ul className="social footer_social">
                <a
                  href="https://github.com/Haulmont/jmix-core"
                  target="_blank"
                  className="social_link"
                  rel="noreferrer"
                >
                  <li className="social_item git" />
                </a>
                <a
                  href="https://www.linkedin.com/company/10601072/"
                  target="_blank"
                  className="social_link"
                  rel="noreferrer"
                >
                  <li className="social_item ln" />
                </a>
                <a
                  href="https://www.facebook.com/JmixFramework"
                  target="_blank"
                  className="social_link"
                  rel="noreferrer"
                >
                  <li className="social_item fb" />
                </a>
                <a
                  href="https://twitter.com/Jmix_framework"
                  target="_blank"
                  className="social_link"
                  rel="noreferrer"
                >
                  <li className="social_item tw" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEmWc8OwhgHnAV7vVVxtglQ"
                  target="_blank"
                  className="social_link"
                  rel="noreferrer"
                >
                  <li className="social_item yt" />
                </a>
              </ul>
            </Space>
          </Col>
        </Row>
      </Layout>
    </Layout.Footer>
  );
};
