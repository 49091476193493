import { useForm, useWatch } from "antd/es/form/Form";
import React from "react";
import {
  useAssignLicenseKeyToMember,
  useAssignLicenseKeyToNewMember,
  useCheckLicenseKey
} from "services/rest/subscription";
import { Form, Input, Modal, notification, Radio } from "antd";
import { RoleSelect } from "components/role-select/RoleSelect";
import { UserTeamSelect } from "components/user-team-select/UserTeamSelect";
import { ILicenseKey } from "components/license-keys-card/LicenseKyesCard";
import { UserType } from "components/license-keys-card/utils/utils";
import { FormatUtils } from "services/utils/FormatUtils";
import { AxiosResponse } from "axios";
import { ValidateCode, ValidateResponse } from "services/rest/general";

export type AssignLicenseKeyModalProps = {
  title: string,
  licenseKey: ILicenseKey,
  subscriptionId: string,
  visible: boolean,
  onAfterOk: () => void,
  onCancel: () => void,
}

export function AssignModal(props: AssignLicenseKeyModalProps) {
  const { title, subscriptionId, licenseKey, onAfterOk, onCancel } = props;
  const [form] = useForm();
  const type = useWatch("type", form);
  const email = useWatch("email", form);
  const memberId = useWatch("user", form);

  const {
    loading: assignToMemberLoading,
    fetch: assignToMemberFetch
  } = useAssignLicenseKeyToMember(subscriptionId, licenseKey.id);
  const {
    loading: assignToNewMemberLoading,
    fetch: assignToNewMemberFetch
  } = useAssignLicenseKeyToNewMember(subscriptionId, licenseKey.id);

  const {
    fetch: checkLicenseFetch,
    loading: checkLicenseLoading,
  } = useCheckLicenseKey(subscriptionId, licenseKey.id, memberId, email, [memberId]);

  const validateEmail = () => {
    return checkLicenseFetch()
      .then((response: AxiosResponse<ValidateResponse>) => {
        const code = response.data.code;

        if (code == ValidateCode.CUSTOMER_IN_ANOTHER_COMPANY) {
          return Promise.reject("User with this email already exists in another company");
        } else if (code == ValidateCode.CUSTOMER_IN_THIS_COMPANY) {
          return Promise.reject("User with this email already exists in the company");
        } else if (code == ValidateCode.MEMBER_HAS_LICENSE_KEY) {
          return Promise.reject("User has another license key");
        } else {
          return Promise.resolve();
        }
      });
  };

  const validateLicense = () => {
    return checkLicenseFetch()
      .then((response: AxiosResponse<ValidateResponse>) => {
        const code = response.data.code;

        if (code == ValidateCode.MEMBER_HAS_LICENSE_KEY) {
          return Promise.reject("User has another license key");
        } else {
          return Promise.resolve();
        }
      });
  };

  const newUserForm = (
    <>
      <Form.Item
        label="Email"
        name="email"
        required
        rules={[
          {
            required: true,
            message: "Please input email",
          },
          {
            type: "email",
            validateTrigger: "onSubmit",
            message: "Email not valid",
          },
          {
            validateTrigger: "onSubmit",
            transform: (value: string) => value.replace(/\s+/g, "").toLowerCase(),
            validator: () => validateEmail(),
          }
        ]}
      >
        <Input onBlur={(value) => form.setFieldsValue({ email: FormatUtils.getEmailFormat(value.target.value) })} />
      </Form.Item>
      <Form.Item
        label="Role"
        name="role"
        required
        rules={[
          {
            required: true,
            message: "Please input role",
          }
        ]}
      >
        <RoleSelect />
      </Form.Item>
    </>
  );

  const teamUserForm = (
    <Form.Item
      label="User"
      name="user"
      required
      rules={[
        {
          required: true,
          message: "Please select user",
        },
        {
          validateTrigger: "onSubmit",
          validator: () => validateLicense(),
        }
      ]}
    >
      <UserTeamSelect />
    </Form.Item>
  );

  const assignLicenseKeyToMember = () => {
    assignToMemberFetch({
      memberId: form.getFieldValue("user"),
    })
      .then(() => {
        Modal.success({
          centered: true,
          title: "Add User",
          content: (
            <>
              <div>
                The key is assigned to the user.
              </div>
              <div>
                We sent an email with the instructions to activate a license.
              </div>
            </>
          )
        });

        onCancel();
        onAfterOk();
      })
      .catch(() => {
        notification.error({
          message: "Assign license key failed.",
          placement: "bottomLeft",
        });
      });
  };

  const assignLicenseKeyToNewMember = () => {
    assignToNewMemberFetch({
      ...form.getFieldsValue()
    })
      .then(() => {
        Modal.success({
          centered: true,
          title: "Add User",
          content: (
            <>
              <div>
                The key is assigned to user <strong>{email}</strong>.
              </div>
              <div>
                We sent an email with the instructions to register on the website and activate a license.
                As soon as the invintation will be confirmed you will get a notification.
              </div>
            </>
          )
        });

        onCancel();
        onAfterOk();
      })
      .catch(() => {
        notification.error({
          message: "Assign license key failed.",
          placement: "bottomLeft",
        });
      });
  };

  return (
    <Modal
      centered
      destroyOnClose
      title={title}
      visible={props.visible}
      okButtonProps={{
        loading: assignToNewMemberLoading || assignToMemberLoading || checkLicenseLoading
      }}
      onOk={() => {
        form.validateFields()
          .then(() => {
            if (type == UserType.TEAM) {
              assignLicenseKeyToMember();
            } else {
              assignLicenseKeyToNewMember();
            }
          });
      }}
      onCancel={onCancel}
    >
      <Form
        layout="vertical"
        preserve={false}
        form={form}
      >
        <Form.Item>
          <strong>Key: </strong>
          <span>{licenseKey.keyId}</span>
        </Form.Item>
        <Form.Item
          label="Assign to"
          name="type"
          initialValue={UserType.TEAM}
        >
          <Radio.Group>
            <Radio.Button
              value={UserType.TEAM}
            >
              User from team
            </Radio.Button>
            <Radio.Button
              value={UserType.NEW}
            >
              New User
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {type === UserType.NEW ? newUserForm : teamUserForm}
      </Form>
    </Modal>
  );
}