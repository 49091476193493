import React from "react";
import { Button, notification, Result, Space } from "antd";
import {
  CheckOutlined,
  CoffeeOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import { SubscriptionItem } from "components/subscription-item/SubscriptionItem";

import { useProfileSubscriptions } from "services/rest/subscription";

import { StoreList } from "toolkit/list/StoreList";

import { NavigateButton } from "components/navigate-button/NavigateButton";
import { StoreCard } from "toolkit/card/StoreCard";
import { useCheckTrial, useGenerateTrial } from "services/rest/account";

import { useIntl } from "react-intl";

import { StoreError } from "pages/system/error/StoreError";

import { HelpLink } from "components/help-link/HelpLink";
import "./SubscriptionListCard.less";

export function SubscriptionListCard() {
  const intl = useIntl();
  const { response, loading, error, fetch } = useProfileSubscriptions();
  const {
    response: checkTrialResponse,
    loading: checkTrialLoading,
    error: checkTrialError,
    fetch: checkTrialFetch,
  } = useCheckTrial();

  const isAvailableTrial =
    !checkTrialLoading &&
    !checkTrialError &&
    checkTrialResponse &&
    checkTrialResponse.available;
  const isEmpty = !response || response.length <= 0;

  const content: React.ReactNode = isEmpty ? (
    <Result
      icon={<CoffeeOutlined style={{ color: "#DEE8ED" }} />}
      extra={intl.formatMessage({
        id: "jmix-store.subscription-list-card.empty-description",
      })}
    />
  ) : (
    response?.map((subscription) => {
      return (
        <SubscriptionItem
          key={subscription.id}
          subscription={subscription}
          onAfter={fetch}
        />
      );
    })
  );

  return (
    <StoreCard
      bordered={true}
      className="subscription-list-card"
      title={intl.formatMessage({
        id: "jmix-store.subscription-list-card.title",
      })}
      extra={
        <Space>
          <HelpLink name="jmix-store.help-link.need-help" to="https://docs.jmix.io/jmix/account-management.html#subscription-management"/>
          {
            isAvailableTrial && (
            <GetTrialButton
              onAfter={() => {
                fetch();
                checkTrialFetch();
              }}
            />
          )}
          <NavigateButton
            icon={<ShoppingCartOutlined />}
            ghost={false}
            size="small"
            name={intl.formatMessage({
              id: "jmix-store.subscription-list-card.buy-button",
            })}
            path="/store/subscription"
          />
        </Space>
      }
    >
      <StoreList
        loading={loading}
      >
        {
          error
            ? <StoreError
              reload={fetch}
              autoHeight={true}
              bordered={false}
            />
            : content
        }
      </StoreList>
    </StoreCard>
  );
}

type GetTrialButtonProps = {
  onAfter: () => void,
}

function GetTrialButton(props: GetTrialButtonProps) {
  const { onAfter } = props;
  const intl = useIntl();
  const { fetch, loading } = useGenerateTrial();

  return (
    <Button
      size="small"
      type="primary"
      ghost
      icon={<CheckOutlined />}
      loading={loading}
      onClick={() => {
        fetch()
          .then(() => {
            onAfter();
          })
          .catch(() => {
            notification.error({
              message: intl.formatMessage({ id: "jmix-store.subscription-list-card.get-trial-error" }),
            });
          });
      }}
    >
      {intl.formatMessage({ id: "jmix-store.subscription-list-card.get-trial-button" })}
    </Button>
  );
}
