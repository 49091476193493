import React from "react";
import { Button, Row, Space, } from "antd";

import { StoreCard } from "toolkit/card/StoreCard";

import Col from "antd/es/grid/col";

import "./ServiceCard.less";

export type ServiceCardProps = {
  logo: string,
  title: string,
  description: string,
  buttonName: string,

  onClick: () => void,
}

export function ServiceCard(props: ServiceCardProps) {
  const { logo, title, description, buttonName, onClick } = props;

  return (
    <StoreCard className="store__product">
      <Row
        align="middle"
        justify="center"
        className="store__content"
      >
        <Col>
          <Space
            direction="vertical"
            align="center"
          >
            <img
              src={logo}
              alt="Store Logo"
            />
            <div className="store__title">
              {title}
            </div>
            <div className="store__description">
              {description}
            </div>
            <Button
              className="store__button"
              type="primary"
              size="large"
              onClick={onClick}
            >
              {buttonName}
            </Button>
          </Space>
        </Col>
      </Row>
    </StoreCard>
  );
}
