import React from "react";

import "./ProgressBar.less";

export type ProgressBarProps = {
  complete: number,
  total: number,
  unitName: string,
}

export function ProgressBar(props: ProgressBarProps) {
  const { complete, total, unitName } = props;
  const available = total - complete;

  const progress = (complete / total) * 100;

  const isRed = progress > 95;

  return (
    <>
      <div className="progress-bar__container">
        <div
          className="progress-bar__current-progress"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="progress-bar__info">
        <span>{`${complete} used ${unitName}`}</span>
        <span className={isRed ? "progress-bar__available_red" : "progress-bar__available_green"}>
           {`${available} available ${unitName}`}
        </span>
      </div>
    </>
  );
}