import React, { useMemo, useState } from "react";
import { SplashScreen } from "components/splash/SplashScreen";
import { AccountContext, IAccountInfo } from "services/account/AccountContext";
import { ProfileInfo, useAccountInfo } from "services/rest/account";
import { StoreError } from "pages/system/error/StoreError";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { AxiosResponse } from "axios";

export type AccountProviderProps = {
  children: React.ReactNode;
}

export function AccountProvider(props: AccountProviderProps) {
  const { response, loading, fetch, error } = useAccountInfo();
  // @ts-ignore
  const [info, setInfo] = useState<IAccountInfo>(null);

  const reload = () => {
    return fetch()
      .then((response: AxiosResponse<ProfileInfo>) => {
        const data = response.data;
        setProfileInfo(data);
        return data;
      });
  };

  function setProfileInfo(response?: ProfileInfo) {
    setInfo({
      type: response?.type,
      name: response?.name,
      companyName: response?.companyName,
      role: response?.role,
      eligible: response?.eligible,
      email: response?.email,
      isVerifyEmail: response?.verifyEmail,
    });
  }

  useMemo(() => {
    setProfileInfo(response);
  }, [response]);

  if (error) {
    return (
      <StoreLayout>
        <StoreError bordered={false} />
      </StoreLayout>
    );
  }

  if (loading || !response) {
    return <SplashScreen />;
  }

  return (
    <AccountContext.Provider value={{ info, loading, reload }}>
      {props.children}
    </AccountContext.Provider>
  );
}