import { Col, Row } from "antd";
import React, { useState } from "react";

import { BalanceCard } from "components/balance-card/BalanceCard";
import { PaidInvoiceListCard } from "components/paid-invoice-card/PaidInvoiceListCard";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { SubscriptionListCard } from "components/subscription-list-card/SubscriptionListCard";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import {
  InvoiceData,
  PostedInvoiceListCard,
} from "components/posted-invoice-list-card/PostedInvoiceListCard";
import { useAccountInfo } from "services/account/hook";
import { CustomerType, Pagination, Role } from "types/general";
import { TeamMember } from "components/team-member/TeamMember";
import { useInvoice } from "../../services/rest/subscription";

export function AccountPage() {
  const { info } = useAccountInfo();

  const title =
    info.type == CustomerType.COMPANY ? info.companyName : info.name;

  const isIndividual = info.type == CustomerType.INDIVIDUAL;
  const isAdmin = info.type == CustomerType.COMPANY && info.role == Role.ADMIN;
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 5,
  });

  const {
    response: invoiceResponse,
    loading: invoiceLoading,
    fetch: invoiceFetch,
    error: invoiceError,
  } = useInvoice("unpaid", pagination.page, pagination.size, [pagination]);

  const invoiceData: InvoiceData = {
    loading: invoiceLoading,
    error: invoiceError,
    response: invoiceResponse,
    fetch: invoiceFetch,
  };

  return (
    <>
      <StorePageHeader title={title} />
      <StoreLayout>
        <Row gutter={[16, 16]}>
          {invoiceResponse &&
            invoiceResponse.total > 0 &&
            (isAdmin || isIndividual) && (
              <Col span={24}>
                <PostedInvoiceListCard
                  invoiceData={invoiceData}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </Col>
            )}
          {isAdmin && (
            <Col span={24}>
              <TeamMember />
            </Col>
          )}
          <Col span={24}>
            <SubscriptionListCard />
          </Col>
          {(isIndividual || isAdmin) && (
            <Col span={24}>
              <BalanceCard />
            </Col>
          )}
          {(isAdmin || isIndividual) && (
            <Col span={24}>
              <PaidInvoiceListCard />
            </Col>
          )}
          {(!invoiceResponse ||
            (invoiceResponse.total == 0 && (isAdmin || isIndividual))) && (
            <Col span={24}>
              <PostedInvoiceListCard
                invoiceData={invoiceData}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Col>
          )}
        </Row>
      </StoreLayout>
    </>
  );
}
