import {Button, Col, Divider, Row} from "antd";
import {VatInfo} from "components/vat-info/VatInfo";
import React from "react";
import {useProductCost} from "services/rest/product";
import {StoreCard} from "toolkit/card/StoreCard";
import {StoreError} from "pages/system/error/StoreError";
import {NewProductConsultingForm} from "pages/store/product/configure/ProductConfigurePage";
import {BasketType, useBasket} from "services/basket/hook";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

import "components/product-cost-card/ProductCostCard.less"

type ICostCardProps = {
    productId: string,
    onProcess: () => Promise<NewProductConsultingForm>,
}

export function ProductCostCard(props: ICostCardProps) {
    const {productId, onProcess} = props;

    const intl = useIntl();
    const {item, setItem} = useBasket();
    const navigate = useNavigate();

    const {response, loading, error, fetch} = useProductCost({
        productId: productId,
    }, [productId]);

    const content = response && (
        <>
            <Row justify="space-between">
                <Col>
                    {response.name}
                </Col>
                <Col>
                    <span>{response.currency.sign}{response.price}</span>
                </Col>
            </Row>
            <div className="product-cost-card__description">
                <div>
                    {response.publicDescription}
                </div>
            </div>
            <Divider/>
            {
                response.tax != 0 &&
                <div>
                    <VatInfo currency={response.currency} cost={response.tax}/>
                    <Divider/>
                </div>
            }
            <div className="product-cost-total">
                <div>
                    <strong>{intl.formatMessage({id: "jmix-store.product-cost-card.total"})}</strong>
                </div>
                <div>
                    <strong>{response.currency.sign}{response.total}</strong>
                </div>
            </div>
            <Button
                className="product-cost-card__button"
                type="primary"
                size="large"
                onClick={() => {
                    onProcess()
                        .then(() => {
                            setItem(createBasket(productId, item));
                            navigate("/store/product/billing");
                        });
                }}
            >
                {intl.formatMessage({id: "jmix-store.product-cost-card.process-button.title"})}
            </Button>
        </>
    );

    return (
        <StoreCard className="product-cost-card" loading={loading}>
            {
                error ?
                    <StoreError
                        autoHeight={true}
                        reload={fetch}
                        bordered={false}
                    /> :
                    content
            }
        </StoreCard>
    );
}

function createBasket(productId: string, item: BasketType): BasketType {
    const product = {
        productId: productId,
    };

    if (item != null) {
        item.product = product;
        return item;
    }

    return {
        product: product
    };
}
