import { ErrorResponse } from "services/rest/general";
import { useIntl } from "react-intl";
import React from "react";
import { env } from "config";


export enum ErrorCode {
  INVALID_FIELDS = "invalid_fields",
  PAYMENT_ERROR = "payment_error",
}

export function useError() {
  const intl = useIntl();

  const getDescription = (error: ErrorResponse): React.ReactNode => {
    const { message, errorCode, errorFields } = error;

    if (errorCode == ErrorCode.INVALID_FIELDS) {
      return Object.entries(errorFields)
        .map(([key, value]) => {
          const fieldValue = intl.formatMessage({ id: "jmix-store.validate-error." + value });
          const fieldName = intl.formatMessage({ id: "jmix-store.validate-error." + key });

          // eslint-disable-next-line react/jsx-key
          return (<div>{fieldName} {fieldValue}</div>);
        });
    }

    if (errorCode == ErrorCode.PAYMENT_ERROR) {
      if (message.includes("2001")) {
        return intl.formatMessage({ id: "jmix-store.payment-error.insufficient-funds" });
      } else if (message.includes("2002")) {
        return intl.formatMessage({ id: "jmix-store.payment-error.limit-exceeded" });
      } else if (message.includes("2057")) {
        return intl.formatMessage({ id: "jmix-store.payment-error.transaction-not-allowed" });
      } else if (message.includes("2000") || message.includes("2014") || message.includes("2015") || message.includes("2038") || message.includes("2044")) {
        return intl.formatMessage({ id: "jmix-store.payment-error.declined-transaction" });
      } else {
        return intl.formatMessage({ id: "jmix-store.payment-error.default" }, {
          link: (str) => <a href={env.REACT_APP_JMIX_CONTACTS} target="_blank" rel="noreferrer">{str}</a>
        });
      }
    }

    return intl.formatMessage({ id: "jmix-store.error.default.description" }, {
      link: (str) => <a href={env.REACT_APP_JMIX_CONTACTS} target="_blank" rel="noreferrer">{str}</a>
    });
  };

  return { getDescription };
}
