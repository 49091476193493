import React from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row } from "antd";

import { StoreCard } from "toolkit/card/StoreCard";
import { useIntl } from "react-intl";
import { env } from "config";

import "./StoreError.less";

export type StoreErrorProps = {
  reload?: () => void,
  bordered?: boolean,
  autoHeight?: boolean,
  showBack?: boolean
}

export function StoreError(props: StoreErrorProps) {
  const { bordered, autoHeight, reload, showBack } = props;
  const intl = useIntl();

  return (
    <StoreCard
      bordered={bordered}
      className="store-error">
      <Row
        className={autoHeight ? "" : "store-error__content"}
        justify="space-around"
        align="middle"
      >
        <Col>
          <Result
            icon={<WarningOutlined className="store-error__icon" size={72} />}
            status="warning"
            title={<strong>{intl.formatMessage({ id: "jmix-store.system-error.description" })}</strong>}
            subTitle={(
              <>
                <div>
                  {intl.formatMessage(
                    {
                      id: "jmix-store.system-error.contact-us"
                    },
                    {
                      link: <a href={env.REACT_APP_JMIX_CONTACTS} target="_blank" rel="noopener noreferrer">contact
                        us</a>
                    })
                  }
                </div>
                {
                  showBack &&
                  <div className="store-error__back">
                    {intl.formatMessage({
                        id: "jmix-store.system-error.back"
                      },
                      {
                        link: <a href="https://jmix.io">jmix.io</a>
                      })
                    }
                  </div>
                }
                {
                  reload &&
                  <div className="store-error__reload">
                    <Button type="primary" size="small" onClick={reload}>Reload</Button>
                  </div>
                }
              </>
            )}
          />
        </Col>
      </Row>
    </StoreCard>
  );
}
