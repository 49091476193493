import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {NewProductSuccessPage} from "./success/NewProductSuccessPage";
import {ProductConfigurePage} from "./configure/ProductConfigurePage";
import {ProductBillingPage} from "./billing/ProductBillingPage";

export function ProductRoutes() {
    return (
        <Routes>
            <Route path="/billing" element={<ProductBillingPage />} />
            <Route path="/success" element={<NewProductSuccessPage />} />
            <Route path="/configure" element={<ProductConfigurePage />} />
            <Route index element={<Navigate to="/store/product/configure" />} />
        </Routes>
    );
}