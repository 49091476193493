import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "antd";

export type NavigateButtonProps = {
  name: string,
  path: string,
} & ButtonProps;

export function NavigateButton(props: NavigateButtonProps) {
  const { name, path } = props;
  const navigate = useNavigate();

  return (
    <Button
      type="primary"
      ghost
      onClick={() => navigate(path)}
      {...props}
    >
      {name}
    </Button>
  );
}