import { Invoice } from "services/rest/account";
import { useMakePayment } from "services/rest/invoice";
import { notification } from "antd";
import { PaymentModal } from "components/payment-modal/PaymentModal";
import React from "react";
import { useIntl } from "react-intl";
import { useError } from "services/error/hook";
import { ErrorResponse } from "services/rest/general";

export type MakePaymentModalProps = {
  invoice: Invoice,
  visible: boolean;
  onClose: () => void,
  onAfterPayment: () => void,
}

export function MakePaymentModal(props: MakePaymentModalProps) {
  const { visible, invoice, onAfterPayment, onClose } = props;
  const intl = useIntl();
  const errorHook = useError();

  const { loading, fetch } = useMakePayment(invoice?.id);

  const title = intl.formatMessage({ id: "jmix-store.make-payment-modal.title" }, { invoice: invoice?.reference || "" });

  const makePayment = () => {
    fetch()
      .then(() => {
        onClose();
        onAfterPayment();

        notification.success({
          message: intl.formatMessage({ id: "jmix-store.payment-success.title" }),
          placement: "bottomLeft",
        });
      })
      .catch((err: ErrorResponse) => {
        notification.error({
          message: intl.formatMessage({ id: "jmix-store.payment-error.title" }),
          description: errorHook.getDescription(err),
          placement: "bottomLeft",
          duration: 15,
        });
      });
  };

  return (
    <PaymentModal
      visible={visible}
      title={title}
      loading={loading}
      currency={invoice?.currency.sign || "$"}
      amount={invoice?.amount || 0}
      onOk={makePayment}
      onClose={onClose} />
  );
}