import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreCard } from "toolkit/card/StoreCard";
import React from "react";
import { useIncident } from "services/rest/incidents";
import { StoreError } from "pages/system/error/StoreError";
import { StoreTable } from "toolkit/table/StoreTable";
import { DateUtils } from "services/utils/DateUtils";
import Text from "antd/lib/typography/Text";
import { Col, Row } from "antd";
import { ProgressBar } from "components/progress-bar/ProgressBar";
import { DownloadLink } from "components/download-link/DownloadFile";
import { env } from "config";

type EventRow = {
  key: number,
  date: string,
  incidents: number,
  fileId: string,
  fileType: string,
  isWriteOff: boolean,
}

const routes: Route[] = [
  {
    path: "/account",
    breadcrumbName: "Account",
  },
  {
    path: "",
    breadcrumbName: "",
  },
];

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Incidents",
    dataIndex: "incidents",
    key: "incidents",
    render: (text: number, record: EventRow) => (
      <Text
        className={record.isWriteOff ? "consultancy__hours_red" : "consultancy__hours_green"}
      >
        {record.isWriteOff ? "-" + text : "+" + text}
      </Text>
    ),
  },
  {
    title: "Report",
    dataIndex: "report",
    key: "report",
    render: (text: string, record: EventRow) => {
      if (!record.fileId) {
        return "";
      }

      const link = `${env.REACT_APP_JMIX_STORE_BACKEND_URL}/rest/v3/profile/balance/incident/${record.fileId}/file`;
      return (
        <DownloadLink link={link} fileType={record.fileType} />
      );
    }
  },
];

export function Incidents() {
  const { response, loading, error } = useIncident();

  if (error) {
    return (
      <StoreError />
    );
  }

  const data: EventRow[] = response?.eventList.map((event, index_) => {
    return {
      key: index_,
      date: DateUtils.getFormatDate(event.date),
      incidents: event.units,
      isWriteOff: event.isWriteOff,
      fileId: event.fileId,
      fileType: event.fileType,
    };
  }) || [];

  const used = response?.unitsUsed;
  const total = response?.unitsTotal;

  return (
    <>
      <StorePageHeader
        title="Incidents"
        breadcrumb={<StoreBreadcrumb routes={routes} />}
      />
      <StoreLayout>
        <StoreCard
          bordered
          loading={loading}
          className="incidents"
        >
          <Row gutter={[16, 16]}>
            <Col flex="100%">
              <ProgressBar
                complete={used || 0}
                total={total || 0}
                unitName="incidents"
              />
            </Col>
            <Col flex="100%">
              <StoreTable
                dataSource={data}
                columns={columns}
              />
            </Col>
          </Row>
        </StoreCard>
      </StoreLayout>
    </>
  );
}
