import { Button } from "antd";
import { FileOutlined } from "@ant-design/icons";
import React from "react";
import { useDownload } from "services/download/Download";


export type DownloadLinkProps = {
  link: string,
  fileType: string,
}

export function DownloadLink(props: DownloadLinkProps) {
  const { link, fileType } = props;
  const { download } = useDownload();

  return (
    <Button type="link" icon={<FileOutlined />} onClick={() => download(link)}>
      {fileType}
    </Button>
  );
}