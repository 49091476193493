import React, { useEffect } from "react";
import { Button, Col, Modal, notification, Row, Space, Spin } from "antd";
import {
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import {
  deletePrimaryPaymentCard,
  usePaymentCards,
} from "services/rest/billingInfo";

import axios from "axios";
import { baseUrl } from "services/rest/request";
import { useKeycloak } from "services/keycloak/hook";

import "./PaymentCard.less";
import { useIntl } from "react-intl";

export type PaymentCardProps = {
  disabled?: boolean;
  value?: string;
  onChange?: (value?: string) => void;
};

export function PaymentCard(props: PaymentCardProps) {
  const keycloak = useKeycloak();
  const { fetch: deleteFetch, loading: deleteLoading } =
    deletePrimaryPaymentCard();
  const intl = useIntl();

  const { disabled, onChange } = props;

  const {
    response: listResponse,
    loading: listLoading,
    fetch: listFetch,
  } = usePaymentCards();

  const onEditCard = () => {
    //@ts-ignore
    window.Chargebee.getInstance().openCheckout({
      hostedPage: () => {
        return axios
          .request({
            url: `${baseUrl}/billing/cards`,
            method: "post",
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          })
          .then((resp) => resp.data);
      },
      close: () => {
        listFetch();
      },
    });
  };

  const isEmptyCards = !listResponse || listResponse.length == 0;

  const primaryCardName = listResponse
    ?.filter((card) => card.primary)
    .map((card) => `${card?.brand} *${card?.last4}`)
    .shift();

  const deletePrimaryCardModal = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: "jmix-store.payment-card.delete-modal.title",
      }),
      content: intl.formatMessage({
        id: "jmix-store.payment-card.delete-modal.message",
      }),
      centered: true,
      okButtonProps: {
        loading: deleteLoading,
      },
      onOk() {
        deleteFetch()
          .then(() => {
            listFetch();
            notification.success({
              message: intl.formatMessage({
                id: "jmix-store.payment-card.delete-modal.success",
              }),
            });
          })
          .catch(() => {
            notification.error({
              message: intl.formatMessage({
                id: "jmix-store.payment-card.delete-modal.error",
              }),
            });
          });
      },
    });
  };

  useEffect(() => {
    if (onChange && primaryCardName) {
      onChange(primaryCardName);
    }
  }, [primaryCardName]);

  return (
    <div className="payment-card">
      <Spin spinning={listLoading}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space direction="horizontal" align="center">
              <CreditCardOutlined />
              <div>{primaryCardName || "No card"}</div>
            </Space>
          </Col>
          <Col>
            <Button
              disabled={disabled}
              type="link"
              icon={<EditOutlined />}
              onClick={() => onEditCard()}
            >
              {isEmptyCards ? "Add" : "Edit"}
            </Button>
            {!isEmptyCards && (
              <span style={{ marginLeft: "10px" }}>
                <Button
                  disabled={disabled}
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    deletePrimaryCardModal();
                  }}
                >
                  {intl.formatMessage({
                    id: "jmix-store.payment-card.delete.title",
                  })}
                </Button>
              </span>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
