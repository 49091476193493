export enum LicenseType {
  ACADEMIC = "academic",
  COMPANY = "company",
  INDIVIDUAL = "individual"
}
export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  FUTURE = "FUTURE",
  CANCELLED = "CANCELLED",
  NON_RENEWING = "NON_RENEWING",
  UNKNOWN = "_UNKNOWN",
  IN_TRIAL = "IN_TRIAL",
  PAYMENT_DUE = "PAYMENT_DUE",
  PAYMENT_OVERDUE = "PAYMENT_OVERDUE",
}
export enum SubscriptionType {
  COMMERCIAL = "COMMERCIAL",
  TRIAL = "TRIAL",
  ACADEMIC = "ACADEMIC",

}

export enum AcademicType {
  STUDENT = "student",
  STAFF = "staff"
}

export type Subscription = {
  name: string
}
