import React from "react";
import { List, ListProps } from "antd";

import "./StoreList.less";

export function StoreList<T>(props: ListProps<T>) {
  return (
    <div className="store-list">
      <List
        {...props}
      />
    </div>
  );
}