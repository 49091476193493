import { BasketType } from "services/basket/hook";

export class Basket {

  getItem(): BasketType {
    const value = localStorage.getItem("jmix-store-basket");
    return value ? JSON.parse(value) : null;
  }

  setItem(value: BasketType) {
    localStorage.setItem("jmix-store-basket", JSON.stringify(value));
  }
}