import { Role } from "types/general";

export enum ValidateCode {
  PROMO_CODE_NOT_FOUND = "PROMO_CODE_NOT_FOUND",
  PROMO_CODE_EXPIRED = "PROMO_CODE_EXPIRED",
  PROMO_CODE_VALID = "PROMO_CODE_VALID",

  DELETE_MEMBER_NOT_VALID = "DELETE_MEMBER_NOT_VALID",

  CUSTOMER_IN_ANOTHER_COMPANY = "CUSTOMER_IN_ANOTHER_COMPANY",
  CUSTOMER_IN_THIS_COMPANY = "CUSTOMER_IN_THIS_COMPANY",

  MEMBER_HAS_LICENSE_KEY = "MEMBER_HAS_LICENSE_KEY",

  VALID = "VALID",
}

export type ValidateResponse = {
  code: ValidateCode;
};

export type Customer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  enabled: boolean;
  memberId: string;
};

export type ErrorResponse = {
  errorCode: string;
  errorFields: ErrorField[];
  message: string;
};

export type ErrorField = {
  [x: string]: string;
};
