import { Button, Col, List, Row, Space } from "antd";
import React from "react";

import { StoreStatistic } from "toolkit/statistic/StoreStatistic";
import { baseUrl } from "services/rest/request";

import { useDownload } from "services/download/Download";

import "./InvoiceItem.less";
import { useIntl } from "react-intl";

export type InvoiceItemProps = {
  id: string,
  title: string,
  date: string,
  amount: string,
  status?: string,
  isMakePayment?: boolean,
  onMakePayment?: () => void,
}

export function InvoiceItem(props: InvoiceItemProps) {
  const { id, title, date, amount, status, isMakePayment, onMakePayment } = props;
  const intl = useIntl();
  const { download } = useDownload();

  return (
    <List.Item className="invoice-item">
      <List.Item.Meta
        description={
          <div>
            <Row>
              <Col>
                <div className="invoice-item__header">
                  {title}
                </div>
              </Col>
            </Row>
            <Row justify="start">
              <Col flex="0 0 200px">
                <StoreStatistic
                  title="Date"
                  value={date}
                />
              </Col>
              <Col flex="0 0 200px">
                <StoreStatistic
                  title="Amount"
                  value={amount}
                />
              </Col>
              {
                status &&
                <Col flex="0 0 200px">
                  <StoreStatistic
                    className="invoice-item__status_warning"
                    title="Status"
                    value={status}
                  />
                </Col>
              }
              <Col flex="1 0 0px" />
              <Col flex="0 0 100px">
                <Space>
                  {
                    isMakePayment &&
                    <Button
                      type="primary"
                      onClick={onMakePayment}
                    >
                      {intl.formatMessage({ id: "jmix-store.invoice-item.make-payment-button" })}
                    </Button>
                  }
                  <Button
                    ghost
                    type="primary"
                    onClick={() => download(`${baseUrl}/profile/invoices/${id}/file`)}
                  >
                    {intl.formatMessage({ id: "jmix-store.invoice-item.download-button" })}
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        }
      />
    </List.Item>
  );
}