import React, { useState } from "react";
import { Collapse, Result } from "antd";

import { InvoiceItem } from "components/invoice-item/InvoiceItem";
import { DateUtils } from "services/utils/DateUtils";
import { CurrencyUtils } from "services/utils/CurrencyUtils";
import { StoreList } from "toolkit/list/StoreList";
import { useInvoice, useSubscriptionInvoice } from "services/rest/subscription";

import { FileTextOutlined } from "@ant-design/icons";

import { StoreError } from "pages/system/error/StoreError";
import { HelpLink } from "components/help-link/HelpLink";

import "./PaidInvoiceListCard.less";

export type PaidInvoiceListCardProps = {
  subscriptionId?: string
}

export function PaidInvoiceListCard(props: PaidInvoiceListCardProps) {
  const { subscriptionId } = props;
  const [pagination, setPagination] = useState({ page: 1, size: 5 });

  const { response, loading, error, fetch } = subscriptionId
    ? useSubscriptionInvoice(subscriptionId, "paid", pagination.page, pagination.size, [pagination])
    : useInvoice("paid", pagination.page, pagination.size, [pagination]);

  const content = response && response.total > 0
    ? response?.content.map(invoice => {
      return (
        <InvoiceItem
          key={invoice.id}
          id={invoice.id}
          title={invoice.reference}
          date={DateUtils.getFormatDate(invoice.creationDate)}
          amount={CurrencyUtils.getFormatCurrency(invoice.currency, invoice.amount)}
        />
      );
    })
    : (
      <Result
        icon={(
          <FileTextOutlined
            size={50}
            style={{ color: "#DEE8ED" }}
          />
        )}
        subTitle="No paid invoices"
      />
    );

  return (
    <Collapse
      defaultActiveKey={["1"]}
      className="invoice-card"
    >
      <Collapse.Panel
        header="Paid Invoices"
        key="1"
        extra={
          <HelpLink name="jmix-store.help-link.need-help"
                    to="https://docs.jmix.io/jmix/account-management.html#paid-invoices" />
        }
      >
        <StoreList
          loading={loading}
          className="invoice-card__list"
          pagination={{
            total: response?.total,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: (page) => setPagination({ page: page, size: pagination.size }),
          }}
        >
          {
            error
              ? <StoreError
                reload={fetch}
                autoHeight={true}
                bordered={false}
              />
              : content
          }
        </StoreList>
      </Collapse.Panel>
    </Collapse>
  );
}
