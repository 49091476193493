import React from "react";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { useParams } from "react-router-dom";
import { useProfileSubscription } from "services/rest/subscription";
import { SubscriptionPageParams } from "pages/account/subscription/Subscription";
import { useIntl } from "react-intl";

import { EditSubscriptionConfigure } from "pages/store/subscription/edit/configure/EditSubscriptionConfigure";
import { StoreError } from "pages/system/error/StoreError";

import "./EditSubscription.less";

export type EditSubscriptionForm = {
  count: number,
  tariff: string,
}

export function EditSubscription() {
  const intl = useIntl();

  const { subscriptionId } = useParams<SubscriptionPageParams>();
  const {
    response: subscriptionResponse,
    loading: subscriptionLoading,
    error: subscriptionError
  } = useProfileSubscription(subscriptionId);

  if (subscriptionError) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }

  const routes = [
    {
      path: "/account",
      breadcrumbName: "Account",
    },
    {
      path: `/account/subscription/${subscriptionId}`,
      breadcrumbName: intl.formatMessage({ id: "jmix-store.edit-subscription.breadcrumb-name" }, {
        name: subscriptionResponse?.name,
        count: subscriptionResponse?.userCount
      }),
    },
    {
      path: "/",
      breadcrumbName: ""
    },
  ];

  return (
    <>
      <StorePageHeader
        title={intl.formatMessage({ id: "jmix-store.edit-subscription.title" })}
        breadcrumb={<StoreBreadcrumb routes={routes} />}
        className="store__page-header"
      />
      <StoreLayout>
        <EditSubscriptionConfigure
          subscriptionInfo={subscriptionResponse}
          loading={subscriptionLoading}
        />
      </StoreLayout>
    </>
  );
}
