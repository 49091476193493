import { useCheckDeleteMember, useDeleteMember } from "services/rest/member";
import { Modal, notification, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import { IMember } from "components/team-member/TeamMember";
import { useIntl } from "react-intl";
import { AxiosResponse } from "axios";
import { ValidateCode, ValidateResponse } from "services/rest/general";

export type DeleteUserModalProps = {
  member: IMember,
  visible: boolean,
  onAfterOk: () => void,
  onCancel: () => void,
}

export function DeleteUserModal(props: DeleteUserModalProps) {
  const { member, visible, onAfterOk, onCancel } = props;
  const intl = useIntl();
  const { loading: deleteLoading, fetch: deleteFetch } = useDeleteMember(member.id);
  const { loading: checkLoading, fetch: checkFetch } = useCheckDeleteMember(member.id);

  const deleteUser = () => {
    deleteFetch()
      .then(() => {
        notification.success({
          message: "User deleted successfully",
          placement: "bottomLeft",
        });

        onCancel();
        onAfterOk();
      })
      .catch(() => {
        notification.error({
          message: "User deleted failed",
          placement: "bottomLeft",
        });
      });
  };

  const warningModal = (code: string) => {
    const contactUs = intl.formatMessage({ id: "jmix-store.contact-us" });

    Modal.warning({
      centered: true,
      title: "Warning",
      content:
        intl.formatMessage({
          id: "jmix-store.validate.delete-member." + code
        }, {
          "contact_us": contactUs,
        }),
    });
  };

  return (
    <Modal
      title={<Space><ExclamationCircleOutlined style={{ color: "#FC1264" }} />Delete user</Space>}
      visible={visible}
      onOk={() => {
        checkFetch()
          .then((resp: AxiosResponse<ValidateResponse>) => {
            const { code } = resp.data;

            if (code == ValidateCode.VALID) {
              deleteUser();
            } else {
              warningModal(code);
            }

            onCancel();
          });
      }}
      okButtonProps={{ loading: deleteLoading || checkLoading }}
      onCancel={onCancel}
      okText="Yes"
      centered
    >
      <div>
        Delete user <strong>{member.email}</strong>?
      </div>
    </Modal>
  );
}