import { StoreCard } from "toolkit/card/StoreCard";
import { Anchor, Button, Col, Divider, Row, Space } from "antd";
import React from "react";
import { useConsultingCost } from "services/rest/consulting";

import { useBasket } from "services/basket/hook";
import { DiscountInfo } from "components/discount-info/DiscountInfo";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StoreError } from "pages/system/error/StoreError";

import "./ConsultingTotalCostCard.less";
import { VatInfo } from "components/vat-info/VatInfo";
import { CurrencyCode } from "types/general";

export type ConsultingTotalCostCardProps = {
  country: string,
  currency: CurrencyCode,
  onClick: () => void,
}

export function ConsultingTotalCostCard(props: ConsultingTotalCostCardProps) {
  const { country, currency, onClick } = props;
  const { item } = useBasket();

  if (!item.consulting) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }

  const { response, loading, error, fetch } = useConsultingCost({
    promoCode: item.consulting.promoCode,
    quantity: item.consulting.quantity,
    country: country,
    currencyCode: currency,
  }, [country, currency]);

  const content = response &&
    (
      <>
        <Row justify="space-between">
          <Col>
            Consulting X {item.consulting.quantity}
          </Col>
          <Col>
            <Space
              direction="vertical"
              align="end"
              size={1}
            >
              <span>{response?.currency.sign}{response?.price}</span>
            </Space>
          </Col>
        </Row>
        <div className="consulting-total-cost-card__discounts">
          <DiscountInfo currency={response.currency} discounts={response.discountNotes || []} />
        </div>
        <Divider />
        {
          response.tax != 0 &&
          <div>
            <VatInfo currency={response.currency} cost={response.tax} />
            <Divider />
          </div>
        }
        <Row
          gutter={[12, 12]}
          justify="space-between"
        >
          <Col>
            <strong>Total</strong>
          </Col>
          <Col>
            {response?.currency.sign}{response?.total}
          </Col>
          <Col span={24}>
            <Button
              className="cost-card__button"
              type="primary"
              size="large"
              onClick={onClick}
            >
              Complete Purchase
            </Button>
          </Col>
        </Row>
      </>
    );

  return (
    <Anchor
      className="consulting-total-cost-card__anchor"
      offsetTop={50}
    >
      <StoreCard
        loading={loading}
        className="consulting-total-cost-card__card"
      >
        {error ?
          <StoreError
            autoHeight={true}
            reload={fetch}
            bordered={false}
          /> :
          content
        }
      </StoreCard>
    </Anchor>
  );
}