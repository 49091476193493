import React from "react";
import { Layout } from "antd";

import "./StoreContent.less";

type IStoreContentProps = {
  children?: React.ReactNode
}

export const StoreContent = (props: IStoreContentProps) => {
  return (
    <Layout.Content className="store-content">
      <Layout>
        {props.children}
      </Layout>
    </Layout.Content>
  );
};
