import React, { useState } from "react";
import { EyeOutlined, MailOutlined, UserSwitchOutlined, } from "@ant-design/icons";
import { Button, Row, Space } from "antd";

import { StoreCard } from "toolkit/card/StoreCard";
import { StoreTable } from "toolkit/table/StoreTable";

import { useSubscriptionLicenseKeys } from "services/rest/subscription";
import { StoreError } from "pages/system/error/StoreError";
import { CustomerType, Pagination, Role } from "types/general";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { LicenseKeyInfoModal } from "components/license-keys-card/modal/LicenseKeyInfoModal";
import { SendModal } from "components/license-keys-card/modal/SendModal";
import { AssignModal } from "components/license-keys-card/modal/AssignModal";
import { useAccountInfo } from "services/account/hook";

import { ReAssignModal } from "components/license-keys-card/modal/ReAssignModal";

import { useIntl } from "react-intl";
import { HelpLink } from "components/help-link/HelpLink";

import "./LicenseKeysCard.less";

export interface ILicenseKey {
  key: string;
  id: string;
  keyId: string;
  memberId: string | null;
  enabled: boolean;
  name: string | null;
  email: string | null;
}

export type LicenseKeysCardProps = {
  subscriptionId: string,
  isTrial: boolean,
}

export function LicenseKeysCard(props: LicenseKeysCardProps) {
  const { subscriptionId, isTrial } = props;

  const [isAssignModalVisible, setAssignModalVisible] = useState(false);
  const [isSendModalVisible, setSendModalVisible] = useState(false);
  const [isReAssignModalVisible, setReAssignModalVisible] = useState(false);
  const [isLicenseKeyInfoModalVisible, setLicenseKeyInfoModalVisible] = useState(false);
  const [currentLicenseKey, setCurrentLicenseKey] = useState<ILicenseKey>();
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: 5 });

  const { info } = useAccountInfo();

  const intl = useIntl();

  const isAdmin = info.type == CustomerType.COMPANY && info.role == Role.ADMIN;

  if (!subscriptionId) {
    return (
      <StoreLayout>
        <StoreError />
      </StoreLayout>
    );
  }

  const {
    response,
    loading,
    error,
    fetch,
  } = useSubscriptionLicenseKeys(subscriptionId, pagination.page, pagination.size);

  const dataSource: ILicenseKey[] = response?.content.map(license => {
    const customer = license.customer;

    const name = customer && customer.enabled
      ? `${customer.firstName} ${customer.lastName}`
      : null;

    return {
      id: license.id,
      key: license.id,
      keyId: license.sid,
      enabled: customer ? license.customer.enabled : false,
      memberId: customer ? customer.memberId : null,
      email: customer ? customer.email : null,
      name: name,
    };
  }) || [];


  const columns = [
    {
      title: "Key ID",
      dataIndex: "keyId",
      key: "keyId"
    },
    {
      title: "User",
      render: (record: ILicenseKey) => {
        if (!record.email) {
          return (
            <div className="license-keys-card__not-assigned">Not Assigned</div>
          );
        }

        const name = record.enabled ? record.name : "Pending";

        return (
          <div>
            <div className="license-keys-card__email">{record.email}</div>
            <span className="license-keys-card__name">{name}</span>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      render: (record: ILicenseKey) => {
        if (!record.email && isAdmin && !isTrial) {
          return (
            <Row
              justify="start"
            >
              <Space>
                <Button
                  type="link"
                  icon={<UserSwitchOutlined />}
                  size="small"
                  onClick={() => {
                    setAssignModalVisible(true);
                    setCurrentLicenseKey(record);
                  }}
                >
                  Assign
                </Button>
              </Space>
            </Row>
          );
        }

        return (
          <Row
            justify="start"
            className="license-keys-card__actions"
          >
            <Space>
              {
                (isAdmin && !isTrial) &&
                <Button
                  type="link"
                  icon={<UserSwitchOutlined />}
                  size="small"
                  onClick={() => {
                    setReAssignModalVisible(true);
                    setCurrentLicenseKey(record);
                  }}
                >
                  Re-assign
                </Button>
              }
              {
                isAdmin &&
                <Button
                  type="link"
                  icon={<MailOutlined />}
                  size="small"
                  onClick={() => {
                    setSendModalVisible(true);
                    setCurrentLicenseKey(record);
                  }}
                >
                  Send by email
                </Button>
              }
              <Button
                type="link"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => {
                  setLicenseKeyInfoModalVisible(true);
                  setCurrentLicenseKey(record);
                }}
              >
                View key
              </Button>
            </Space>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <StoreCard
        title={intl.formatMessage({ id: "jmix-store.license-keys-card.title" })}
        className="license-keys-card"
        extra={
          <HelpLink name="jmix-store.help-link.need-help"
                    to="https://docs.jmix.io/jmix/account-management.html#subscription-management" />
        }
      >
        {
          error ?
            <StoreError
              reload={fetch}
              autoHeight={true}
              bordered={false}
            /> :
            <StoreTable
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              className="license-keys-card__table"
              pagination={{
                total: response?.total,
                pageSize: pagination.size,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (page) => setPagination({ page: page, size: pagination.size })
              }}
            />
        }
      </StoreCard>
      {
        currentLicenseKey &&
        <AssignModal
          title="Assign License Key"
          licenseKey={currentLicenseKey}
          subscriptionId={subscriptionId}
          visible={isAssignModalVisible}
          onAfterOk={fetch}
          onCancel={() => setAssignModalVisible(false)}
        />
      }
      {
        currentLicenseKey &&
        <SendModal
          licenseKey={currentLicenseKey}
          subscriptionId={subscriptionId}
          visible={isSendModalVisible}
          onCancel={() => setSendModalVisible(false)}
        />
      }
      {
        currentLicenseKey &&
        <LicenseKeyInfoModal
          subscriptionId={subscriptionId}
          licenseId={currentLicenseKey.id}
          visible={isLicenseKeyInfoModalVisible}
          onCancel={() => setLicenseKeyInfoModalVisible(false)}
        />
      }
      {
        currentLicenseKey &&
        <ReAssignModal
          licenseKey={currentLicenseKey}
          visible={isReAssignModalVisible}
          subscriptionId={subscriptionId}
          onAfterOk={fetch}
          onCancel={() => setReAssignModalVisible(false)}
        />
      }
    </>
  );
}
