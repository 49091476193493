import { Button, Form, Modal, notification, Radio, Upload } from "antd";
import { AcademicType } from "types/subscription";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import { useForm } from "antd/es/form/Form";
import { useKeycloak } from "services/keycloak/hook";
import { useRequestAcademic } from "services/rest/subscription";
import { baseUrl } from "services/rest/request";
import { useAccountInfo } from "services/account/hook";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { env } from "config";

export type AcademicModalProps = {
  visible: boolean,

  onAfterOk: () => void,
  onCancel: () => void,
}

export function AcademicModal(props: AcademicModalProps) {
  const { visible, onAfterOk, onCancel } = props;
  const intl = useIntl();
  const [form] = useForm();
  const keycloak = useKeycloak();
  const { loading, reload } = useAccountInfo();
  const navigate = useNavigate();
  const { loading: requestLoading, fetch } = useRequestAcademic();

  return (
    <Modal
      title="Get Academic License"
      open={visible}
      centered
      okButtonProps={{
        loading: loading || requestLoading
      }}
      onOk={() => {
        form.validateFields()
          .then(fields => {
            const data = {
              fileId: fields["attachment"]?.file.response.id,
              projectInfo: fields["description"],
              isStudent: fields["type"] === AcademicType.STUDENT,
            };

            fetch(data)
              .then(() => {
                reload()
                  .finally(() => {
                    onAfterOk();
                    navigate("/store/subscription/request-success");
                  });
              })
              .catch(() => {
                notification.error({
                  message: intl.formatMessage({ id: "jmix-store.error.academic-request.title" }),
                  description: intl.formatMessage({ id: "jmix-store.error.default.description" }, {
                    link: (str) => <a href={env.REACT_APP_JMIX_CONTACTS}>{str}</a>
                  }),
                  placement: "bottomLeft",
                });
              });
          });
      }}
      onCancel={onCancel}
    >
      <div className="academic-card__subtitle">
        Please fill out the form below to apply for an academic license.
      </div>
      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Are you a student or a member of staff?"
          initialValue={AcademicType.STUDENT}
          name="type"
        >
          <Radio.Group>
            <Radio.Button value={AcademicType.STUDENT}>Student</Radio.Button>
            <Radio.Button value={AcademicType.STAFF}>Member of staff</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="How you are going to use Jmix and how many license keys you require"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input project info",
            }]
          }
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label="Provide supporting document to confirm your status"
          name="attachment"
          valuePropName="file"
          required
          rules={[
            {
              required: true,
              message: "Please upload supporting document",
            }
          ]}
        >
          <Upload
            maxCount={1}
            action={`${baseUrl}/files`}
            headers={{ "Authorization": `Bearer ${keycloak.token}` }}
          >
            <Button
              icon={<UploadOutlined />}
              type="primary"
              size="small"
              ghost
            >
              Select files
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}