import {useBasket} from "services/basket/hook";
import {StoreLayout} from "components/store-layout/StoreLayout";
import {StoreError} from "pages/system/error/StoreError";
import {useProductCost} from "services/rest/product";
import {Anchor, Button, Col, Divider, Row, Space} from "antd";
import {VatInfo} from "components/vat-info/VatInfo";
import {StoreCard} from "toolkit/card/StoreCard";
import React from "react";
import {CurrencyCode} from "types/general";
import {useIntl} from "react-intl";

import "./ProductTotalCostCard.less";

export type ProductTotalCostCardProps = {
    country: string,
    currency: CurrencyCode,
    onClick: () => void,
}

export function ProductTotalCostCard(props: ProductTotalCostCardProps) {
    const {country, onClick} = props;
    const intl = useIntl();
    const {item} = useBasket();

    if (!item.product) {
        return (
            <StoreLayout>
                <StoreError/>
            </StoreLayout>
        );
    }

    const {response, loading, error, fetch} = useProductCost({
        country: country,
        productId: item.product.productId,
    }, [country]);

    const content = response &&
        (
            <>
                <Row justify="space-between">
                    <Col>
                        {response?.name}
                    </Col>
                    <Col>
                        <Space
                            direction="vertical"
                            align="end"
                            size={1}
                        >
                            <span>{response?.currency.sign}{response?.price}</span>
                        </Space>
                    </Col>
                </Row>
                <Divider/>
                {
                    response.tax != 0 &&
                    <div>
                        <VatInfo currency={response.currency} cost={response.tax}/>
                        <Divider/>
                    </div>
                }
                <Row
                    gutter={[12, 12]}
                    justify="space-between"
                >
                    <Col>
                        <strong>
                            {intl.formatMessage({id: "jmix-store.product-total-cost-info.total"})}
                        </strong>
                    </Col>
                    <Col>
                        {response?.currency.sign}{response?.total}
                    </Col>
                    <Col span={24}>
                        <Button
                            className="product-total-cost-card__button"
                            type="primary"
                            size="large"
                            onClick={onClick}
                        >
                            {intl.formatMessage({id: "jmix-store.product-total-cost-info.complete-purchase.title"})}
                        </Button>
                    </Col>
                </Row>
            </>
        );

    return (
        <Anchor
            className="product-total-cost-card__anchor"
            offsetTop={50}
        >
            <StoreCard
                loading={loading}
                className="product-total-cost-card__card"
            >
                {error ?
                    <StoreError
                        autoHeight={true}
                        reload={fetch}
                        bordered={false}
                    /> :
                    content
                }
            </StoreCard>
        </Anchor>
    );
}