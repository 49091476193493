import React from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useIntl } from "react-intl";

import "./HelpLink.less";

export type HelpLinkProps = {
  name: string;
  to: string;
};

export function HelpLink(props: HelpLinkProps) {
  const { name, to } = props;
  const intl = useIntl();

  return (
    <a className="help-link" href={to} target="_blank" rel="noreferrer">
      <span>{intl.formatMessage({ id: name })}</span>
      <QuestionCircleFilled/>
    </a>
  );
}
