import { useForm } from "antd/es/form/Form";
import { useEditMember } from "services/rest/member";
import { Button, Col, Form, Input, Modal, notification, Row } from "antd";
import React from "react";
import { RoleSelect } from "components/role-select/RoleSelect";
import { IMember } from "components/team-member/TeamMember";

export type EditUserModalProps = {
  member: IMember,
  visible: boolean,
  onAfterOk: () => void,
  onCancel: () => void
}

export function EditUserModal(props: EditUserModalProps) {
  const { member, visible, onAfterOk, onCancel } = props;
  const [form] = useForm();
  const { loading, fetch } = useEditMember(member.id);

  form.setFieldsValue({ ...member });

  const editUser = () => {
    fetch(form.getFieldsValue())
      .then(() => {
        notification.success({
          message: "Edit user successfully",
          placement: "bottomLeft",
        });

        onCancel();
        onAfterOk();
      })
      .catch(() => notification.error({
        message: "Edit user failed",
        placement: "bottomLeft",
      }));
  };

  const footers: React.ReactNode = (
    <Row
      justify="end"
      gutter={[16, 16]}
    >
      <Col>
        <Button
          ghost
          type="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          loading={loading}
          onClick={editUser}
        >
          Save changes
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      title="Edit User"
      visible={visible}
      centered
      className="team-member__add-user-modal"
      destroyOnClose
      onCancel={onCancel}
      footer={footers}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input email",
            }]
          }
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          // tooltip={<RoleDescription />}
          rules={[
            {
              required: true,
              message: "Please input role",
            }]
          }
        >
          <RoleSelect />
        </Form.Item>
      </Form>
    </Modal>
  );
}