import React from "react";
import { StoreLayout } from "components/store-layout/StoreLayout";
import { StorePageHeader } from "toolkit/pageHeader/StorePageHeader";
import { NewSubscriptionRoutes } from "pages/store/subscription/new/NewSubscriptionRoutes";

import { StoreBreadcrumb } from "toolkit/breadcrumb/StoreBreadcrumb";

import "./NewSubscription.less";

const routes = [
  {
    path: "/store",
    breadcrumbName: "Store",
  },
  {
    path: "/",
    breadcrumbName: "",
  },
];

export function NewSubscription() {

  return (
    <>
      <StorePageHeader
        title="New Jmix Subscription"
        breadcrumb={<StoreBreadcrumb routes={routes} />}
        className="store__page-header"
      />
      <StoreLayout>
        <NewSubscriptionRoutes />
      </StoreLayout>
    </>
  );
}
