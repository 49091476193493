import { SendEmail, useSendEmail } from "services/rest/subscription";
import { DateUtils } from "services/utils/DateUtils";
import { AxiosResponse } from "axios";
import { Modal, notification, Typography } from "antd";
import React from "react";
import { ILicenseKey } from "components/license-keys-card/LicenseKyesCard";

export type SendModalProps = {
  licenseKey: ILicenseKey,
  subscriptionId: string,
  visible: boolean,
  onCancel: () => void,
}

export function SendModal(props: SendModalProps) {
  const { licenseKey, subscriptionId, visible, onCancel } = props;
  const { loading, fetch } = useSendEmail(subscriptionId, licenseKey.id);


  const onSend = () => {
    fetch()
      .then((resp: AxiosResponse<SendEmail>) => {
        if (resp.data.success) {
          Modal.success({
            title: "Send Instructions",
            content: <div>Email sent at <strong>{licenseKey.email}</strong></div>,
            centered: true,
          });
        } else {
          Modal.error({
            title: "Send Instructions",
            content: (
              <div>
                <p>Email not sent at <strong>{licenseKey.email}</strong></p>
                <p>Please try again in <strong>{DateUtils.getFormatTime(resp.data.repeatIn)}</strong></p>
              </div>
            ),
            centered: true,
          });
        }

        onCancel();
      })
      .catch(() => {
        notification.error({
          message: "Sending email failed",
          placement: "bottomLeft",
        });

        onCancel();
      });
  };

  return (
    <Modal
      centered
      title="Send Instructions"
      visible={visible}
      okText="Send"
      okButtonProps={{
        loading
      }}
      onOk={onSend}
      onCancel={onCancel}
    >
      <Typography>
        <Typography.Paragraph>
          Would you like to send an email at <strong>{licenseKey.email}</strong> with the instructions to activate a
          license?
        </Typography.Paragraph>
      </Typography>
    </Modal>
  );
}
