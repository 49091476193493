import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { DependencyList, useEffect, useState } from "react";
import { env } from "config";
import { client } from "services/keycloak/client";

export const baseUrl = `${env.REACT_APP_JMIX_STORE_BACKEND_URL}/rest/v3`;

export const useAxios = <R>(config: AxiosRequestConfig, deps: DependencyList, isFetch = true) => {
  const [response, setResponse] = useState<R>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(isFetch);

  const fetch = async <D> (data?: D) => {
    setLoading(true);

    try {
      await client.updateToken(60);

      const defaultConfig: AxiosRequestConfig = {
        method: config.method,
        url: config.url,
        params: {
          ...config.params
        },
        headers: {
          "Authorization": `Bearer ${client.token}`,
          ...config.headers
        },
        data: data ? data : config.data
      };

      return axios.request({
        ...defaultConfig,
      })
        .then((res: AxiosResponse<R>) => {
          setResponse(res.data);
          return res;
        })
        .catch((err: AxiosError<XMLHttpRequest>) => {
          setError(err.message);
          throw err.response?.data;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      client.login();
      throw new Error("Token expired!");
    }
  };

  useEffect(() => {
    if (isFetch) {
      fetch(config.data);
    }
  }, deps);

  return { response, error, loading, fetch };
};

export const useAxiosWithoutAuthorize = <Type>(config: AxiosRequestConfig, deps: DependencyList) => {
  const [response, setResponse] = useState<Type>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios.request(config)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, deps);

  return { response, error, loading };
};
